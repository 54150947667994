export const columnData = [
   {
        key: 'sn',
        text: '单号',
        width: '200px',
        fixed: 'left'
    }, {
        key: 'stateDesc',
        text: '状态',
        width: '120px',
        fixed: 'left',
        remind: {
            text: `
                草稿单(蓝色)：临时保存单据草稿，不会占用库存<br/>
                预提交（紫色）：先提交单据，但不会下发到店铺，会占用店铺库存<br/>
                已下发（绿色）：已提交单据，且下发到店铺，待店铺拣货出库<br/>
                拣货中（橙色）：店铺收到指令，正在拣货<br/>
                拣货完成（绿色）：店铺拣货完成并提交<br/>
                已出货（蓝色）：店铺已按照退仓单拣货出库，会扣减店铺库存，物流状<br/>&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;态在途，将增加仓库在途库存<br/>
                已签收（绿色）：走合作物流发货时，物流信息已到仓签收，等待仓库拆<br/>&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;包验收<br/>
                验收中（橙色）：仓库拆包验收中<br/>
                已入库（绿色）：验收完成后，操作确认入库，仓库的在途库存变为实际<br/>&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;增加库存<br/>
                已作废（红色）：草稿单、预提交、已下发，这三个状态可进行作废操作<br/>
                申请作废（红色）：已出货单据进行申请作废<br/>
          `,
            style: {
                width: '400px',
                'font-size': '12px',
                'text-align': 'left'
            }
        },
        template: () => {
            return `
              <div style="display:flex;align-items:center;">
                  <span v-if="row.state == 0" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#F8475F;"></span>
                  <span v-else-if="[4,6,8,12,14].includes(+row.state)" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#43D268;"></span>
                  <span v-else-if="[5,7,11].includes(+row.state)" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#FF9000;"></span>
                  <span v-else-if="[1,9,10].includes(+row.state)" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#067CF2;"></span>
                  <span v-else-if="row.state == 2" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#BD73D3;"></span>
                  <span v-else-if="row.state == 19" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#F8475F;"></span>
                  <span v-else style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#999;"></span>
                  <span>{{row.stateDesc}}</span>
              </div>
          `
        }
    },
    {
        key: 'ifDiscrepancy',
        text: '收货差异待处理',
        width: '100px',
        template: () => {
            return `
                <div>{{row.ifDiscrepancy === 'Y' ? '是' : '否'}}</div>
            `
        }
    },
    {
        key: 'marketName',
        text: '发货方',
        width: '100px'
    },
    { key: 'warehouseName', text: '收货方', width: '100px' },

    { key: 'totalNum', text: '计划数量', width: '80px', 
     template: (e, row) => {
        return `<span>{{row.totalNum==='0'?'':row.totalNum}}<span>`
    } },
    { key: 'outNum', text: '出货数量', width: '80px', 
     template: (e, row) => {
        return `<span>{{row.outNum==='0'?'':row.outNum}}<span>`
    } },
    {
        key: 'outNumDifference',
        text: '出货差异数',
        width: '100px',
        template: (e, row) => {
            return `<span style="color:red">{{row.outNumDifference==='0'?'':row.outNumDifference}}<span>`
        }
    },
    { key: 'inNum', text: '入库数量', width: '80px',
      template: (e, row) => {
        return `<span>{{row.inNum==='0'?'':row.inNum}}<span>`
    } },
    {
        key: 'inNumDifference',
        text: '入库差异数',
        width: '100px',
        template: (e, row) => {
            return `<span  style="color:red">{{row.inNumDifference==='0'?'':row.inNumDifference}}<span>`
        }
    },
    { key: 'remark', text: '备注', width: '150px' },
    { key: 'createUserName', text: '创建人', width: '100px' },
    {
        key: 'createTime',
        text: '创建时间',
        width: '160px',
    },
    { key: 'updateTime', text: '更新时间', width: '160px' },
    { key: 'updateUserName', text: '修改人', width: '100px' }
    // {
    //   key: "operate",
    //   text: "操作",
    //   width: "200px",
    //   fixed: "right",
    //   template: () => {
    //     return `
    //       <el-button type="text" style="padding: 0;font-size: 14px; color: #0B83F3;" v-if="row.state != 101" @click="changeType(row)" class="operate-btn">详情</el-button>
    //       <el-button type="text" style="padding: 0;font-size: 14px; color: #0B83F3;" v-if="row.state == 101" @click="editDetail(row)" class="operate-btn">编辑</el-button>
    //       <el-button type="text" style="padding: 0;font-size: 14px; color: #ff9c1c;" v-if="row.state == 105" @click="handleCommand('checkGet', row)" class="operate-btn">发货</el-button>
    //       <el-button type="text" style="padding: 0;font-size: 14px; color: #bf79d4;" v-if=" [103, 104].includes(+row.state)" @click="handleCommand('startGet', row)" class="operate-btn">拣货</el-button>
    //       <el-button type="text" style="padding: 0;font-size: 14px; color: #47d26b;" v-if="row.state != 101" @click="handleCommand('print', row)" class="operate-btn">打印</el-button>
    //       <el-button type="text" style="padding: 0;font-size: 14px; color: #f84f66;"v-if="row.typeCode==100020&& [101,102,103].includes(+row.state)" @click="handleCommand('void', row)" class="operate-btn">作废</el-button>
    //   `;
    //   },
    // },
]
export const options = {
    searchItem: [
        {
            id: '2',
            type: 'input',
            size: 'small',
            clearable: true,
            placeholder: '请输入单号',
            label: '单号',
            value: '',
            resField: 'snList'
        },
        {
            id: '3',
            type: 'input',
            size: 'small',
            clearable: true,
            placeholder: '请输入快递单号',
            label: '快递单号',
            value: '',
            resField: 'expressNo'
        },
        {
            id: '1',
            type: 'select',
            size: 'small',
            clearable: true,
            placeholder: '请选择状态',
            label: '状态',
            value: '',
            resField: 'stateList',
            multiple: true,
            list: [
                { name: '草稿单', value: '1' },
                { name: '已下发', value: '4' },
                { name: '拣货中', value: '5' },
                { name: '拣货完成', value: '6' },
                { name: '打包完成', value: '8' },
                { name: '已出货', value: '9' },
                { name: '已签收', value: '10' },
                { name: '验收中', value: '11' },
                { name: '已入库', value: '14' },
                { name: '作废申请', value: '19' },
                { name: '已作废', value: '0' }
            ]
        },
        {
            id: 'ifDiscrepancy',
            type: 'select',
            size: 'small',
            clearable: true,
            placeholder: '请选择',
            label: '是否仅看收货差异待处理',
            value: '',
            resField: 'ifDiscrepancy',
            multiple: false,
            list: [
                { value: 'Y', name: '是' },
                { value: 'N', name: '否' }
            ]
        },
        // {
        //     id: 'warehouseIdList',
        //     type: 'selectEntre',
        //     size: 'small',
        //     clearable: true,
        //     placeholder: '请选择',
        //     label: '收货方',
        //     value: [],
        //     resField: 'warehouseIdList',
        //     list: [],
        //     multiple: true,
        //     filterable: true,
        //     remote: true
        // },
        {
            id: 'warehouseIdList',
            type: 'warehouse',
            placeholder: '请选择收货方',
            label: '收货方',
            value: [],
            resField: 'warehouseIdList'
        },
        {
            // id: 'spuList',
            // type: 'goodsNoSelector',
            // placeholder: '请选择货号',
            // label: '货号',
            // modelKey: 'spu',
            // multiple: true, //单选
            // value: '', //单选
            // value: [],//多选
            // resField: 'spuList'
            id: 'goodsNo',
            type: 'goodsNoSelector',
            placeholder: '请输入货号，多个用空格隔开',
            label: '货号',
            modelKey: 'goodsNo',
            multiple: true,
            value: '',
            inputShow: true,
            slectShow: false,
            resField: 'spuList'
        },
        // {
        //     id: '7',
        //     type: 'selectUser',
        //     size: 'small',
        //     clearable: true,
        //     placeholder: '请选择创建人',
        //     label: '创建人',
        //     value: '',
        //     resField: 'createUser',
        //     list: [],
        //     filterable: true,
        //     remote: true
        // },
        {
            id: "UserInput",
            type: "input",
            size: "small",
            clearable: true,
            placeholder: "请填写创建人",
            label: "创建人",
            value: "",
            resField: "createUserName",
        },
        {
            id: '8',
            type: 'daterange',
            size: 'small',
            clearable: true,
            placeholder: '请选择创建时间',
            label: '创建时间',
            value: '',
            resField: 'createTime',
            valueFormat: 'yyyy-MM-dd'
        },
        {
            id: '9',
            type: 'daterange',
            size: 'small',
            clearable: true,
            placeholder: '请选择更新时间',
            label: '更新时间',
            value: '',
            resField: 'updateTime',
            valueFormat: 'yyyy-MM-dd'
        }
    ],
    // 确认按钮
    sureBtnVisable: true,
    sureBtnType: 'primary',
    sureBtnSize: 'medium',
    sureBtnText: '查询',
    //重置按钮
    restBtnVisable: true,
    restBtnType: '',
    restBtnSize: 'medium',
    restBtnText: '重置'
}
