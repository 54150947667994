<template>
    <el-drawer size="90%" :visible.sync="dialogVisible" :wrapperClosable="false" @close="close" :show-close="false">
        <section slot="title" class="dialog-top">
            <p>{{ '单据详情' }}</p>
            <div style="display: flex">
                <basic-button
                    v-if="[9, 11].includes(+modalParams.state)"
                    @click="checkGet()"
                    type="primary"
                    icon-class="acceptance"
                >
                    开始验收
                </basic-button>
                <i class="el-icon-close" @click="dialogVisible = false"></i>
            </div>
        </section>
        <section v-loading="loading">
            <section class="step">
                <!-- <el-steps :active="scheduleState" finish-status="finish" process-status="wait" align-center>
                    <el-step
                        v-for="(item, index) in scheduleInfoList"
                        :key="item.sort"
                        :title="item.scheduleName"
                        :status="item.hasNowStep === 'N' ? 'wait' : ''"
                    >
                        <div slot="description" v-if="item.hasNowStep === 'Y'">
                            <div>{{ item.startTime }}</div>
                            <div v-if="item.consumingTime && index > 0" style="color: #999">
                                耗时：{{ item.consumingTime }}
                            </div>
                            <span v-if="item.timeout" style="color: red">超时：{{ item.overTime }}</span>
                        </div>
                    </el-step>
                </el-steps> -->
                <Timeline :timeList="scheduleInfoList"></Timeline>
            </section>
            <section class="section-card">
                <h3 class="section-title">
                    <span></span>
                    <div>单据信息</div>
                </h3>
                <div class="section-value" v-if="invoicesData">
                    <el-descriptions :column="4" size="small" border style="width: 100%">
                        <el-descriptions-item>
                            <template slot="label"> 创建人 </template>
                            {{ invoicesData.createUserName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 发货方 </template>
                            {{ invoicesData.senderInfoName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 收货方 </template>
                            {{ invoicesData.receiveInfoName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 计划数量 </template>
                            {{ invoicesData.count }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 出货数量 </template>
                            {{ invoicesData.shipmentCount }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 入库数量 </template>
                            {{ invoicesData.storageCount }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 出货差异数 </template>
                            {{ invoicesData.shipmentDifferenceCount }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 入库差异数 </template>
                            {{ invoicesData.storageDifferenceCount }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 创建时间 </template>
                            {{ invoicesData.createTime }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 更新时间 </template>
                            {{ invoicesData.updateTime }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 最后修改人 </template>
                            {{ invoicesData.updateUserName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 总耗时 </template>
                            {{ invoicesData.consumingTime }}
                        </el-descriptions-item>
                        <el-descriptions-item v-if="invoicesSnType === 'PH'">
                            <template slot="label"> 商品负责人 </template>
                            {{ invoicesData.goodsPrincipal }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 备注 </template>
                            {{ invoicesData.description }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="detail-state">
                    <img v-if="modalParams.state == 0" src="@/assets/images/erp/void.png" />
                    <img v-if="modalParams.state == 4" src="@/assets/images/erp/sended.png" />
                    <img v-if="modalParams.state == 5" src="@/assets/images/erp/orderpack.png" />
                    <img v-if="modalParams.state == 6" src="@/assets/images/erp/orderpacked.png" />
                    <img v-if="modalParams.state == 7" src="@/assets/images/erp/packing.png" />
                    <img v-if="modalParams.state == 8" src="@/assets/images/erp/packed.png" />
                    <img v-if="modalParams.state == 9" src="@/assets/images/erp/outed.png" />
                    <img v-if="modalParams.state == 10" src="@/assets/images/erp/checked.png" />
                    <img v-if="modalParams.state == 14" src="@/assets/images/erp/joined.png" />
                </div>
            </section>

            <section class="section-card">
                <h3 class="section-title">
                    <span></span>
                    <div>商品列表</div>
                </h3>
                <div>
                    <SizeGroupTable
                        ref="sizeGroupTableIns"
                        :dataList="dataList"
                        :columns="newColumns"
                        :isEmpty="true"
                        :sizeInfoLabels="newSizeInfoLabels"
                        :footerOptions="newFooterOptions"
                        border
                    >
                        <template v-slot="{ row, item }">
                            <el-popover v-if="item.prop === 'goodsNo'" placement="right-start" trigger="click">
                                <el-image
                                    v-if="row.imageList && row.imageList.length"
                                    style="width: 400px; height: 400px"
                                    :src="row.imageList[0]"
                                    :preview-src-list="row.imageList"
                                >
                                </el-image>
                                <span slot="reference" class="text-link">{{ row.goodsNo }} </span>
                            </el-popover>
                        </template>
                    </SizeGroupTable>
                </div>
            </section>

            <section class="section-card" v-if="logisticsList.length">
                <h3 class="section-title">
                    <span></span>
                    <div>发货信息</div>
                </h3>
                <div style="margin: 20px">
                    <el-table
                        border
                        :data="logisticsList"
                        :header-cell-style="{
                            background: '#F3F4F7',
                            color: '#555',
                            padding: '5px 0'
                        }"
                        :cell-style="{ padding: '5px 0' }"
                        :span-method="arraySpanMethod"
                    >
                        <el-table-column prop="invoicesNo" label="单号" align="center"> </el-table-column>
                        <el-table-column prop="senderInfoName" label="发货方" align="center"> </el-table-column>
                        <el-table-column prop="receiveInfoName" label="收货方" align="center"> </el-table-column>
                        <el-table-column prop="packageSn" label="包裹号" align="center">
                            <template slot-scope="scope">
                                <span
                                    @click="packageInfo(scope.row)"
                                    style="text-decoration: underline; color: #1890ff; cursor: pointer"
                                    >{{ scope.row.packageSn }}</span
                                >
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsNum" label="货品数量" align="center"> </el-table-column>
                        <el-table-column prop="weight" label="重量" align="center"> </el-table-column>
                        <el-table-column prop="logisticsName" label="物流公司" align="center"> </el-table-column>
                        <el-table-column prop="expressNo" label="快递单号" align="center"> </el-table-column>
                        <el-table-column prop="state" label="状态" align="center">
                            <!-- v-if="scope.row.queryType == 0" -->
<!--                            <template slot-scope="scope">-->
<!--                                <el-tag effect="dark" :type="expressState[scope.row.state].type">-->
<!--                                    {{ expressState[scope.row.state].label }}-->
<!--                                </el-tag>-->
<!--                            </template>-->
                          <template slot-scope="scope">
                            <el-tag effect="dark"
                                    v-if="scope.row.logisticsName && scope.row.logisticsName.indexOf('京东') !== -1">
                              {{ scope.row.jdStateDesc ? scope.row.jdStateDesc : '待更新' }}
                            </el-tag>
                            <el-tag effect="dark" v-else :type="expressState[scope.row.state].type">
                              {{ scope.row.stateDesc }}
                            </el-tag>
                          </template>
                        </el-table-column>
                        <el-table-column prop="shipmentTime" label="发货时间" align="center"> </el-table-column>
                        <el-table-column prop="signTime" label="签收时间" align="center"> </el-table-column>
                        <el-table-column fixed="right" label="操作" width="160">
                            <template slot-scope="scope">
                                <el-button @click="clickPackage(scope.row)" type="text" size="small">
                                  <span style="text-decoration: underline">查看包裹</span>
                                </el-button>
                                <el-button @click="handleClick(scope.row)" type="text" size="small">
                                    <span style="text-decoration: underline">物流轨迹</span>
                                </el-button>
<!--                                <el-button-->
<!--                                    v-if="scope.row.expressNo && scope.row.state == 2"-->
<!--                                    @click="amendClick(scope.row)"-->
<!--                                    type="text"-->
<!--                                    size="small"-->
<!--                                >-->
<!--                                    <span style="text-decoration: underline">修改快递单号</span>-->
<!--                                </el-button>-->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section>

            <section class="section-card" v-if="differList.length">
              <h3 class="section-title">
                <span></span>
                <div>收货差异信息</div>
              </h3>
              <div style="margin: 20px">
                <el-table
                  border
                  :data="differList"
                  :header-cell-style="{
                    background: '#F3F4F7',
                    color: '#555',
                    padding: '5px 0'
                  }"
                  :cell-style="{ padding: '5px 0' }">
                  <el-table-column prop="packageSn" label="包裹号" align="center"></el-table-column>
                  <el-table-column prop="receiveName" label="收货人" align="center"></el-table-column>
                  <el-table-column prop="checkCompleteTime" label="收货完成时间" align="center" width="200"></el-table-column>
                  <el-table-column prop="sendNum" label="货品数量" align="center"></el-table-column>
                  <el-table-column prop="sendEpcNum" label="出货标签数量" align="center"></el-table-column>
                  <el-table-column prop="discrepancyNum" label="差异" align="center"></el-table-column>
                  <el-table-column prop="sendDutyNum" label="发货方责任" align="center"></el-table-column>
                  <el-table-column prop="receiveDutyNum" label="收货方责任" align="center"></el-table-column>
                  <el-table-column prop="transportDutyNum" label="运输方责任" align="center"></el-table-column>
                  <el-table-column prop="state" label="状态" align="center">
                    <template slot-scope="scope">
                      <el-tag
                          effect="dark"
                          size="mini"
                          :type="scope.row.state === '0' ? 'danger' : 'success'">
                        {{scope.row.state === '0' ? '未定责' : '已定责'}}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="操作" width="150">
                    <template slot-scope="scope">
                      <el-button @click="showDifferDetail(scope.row)" type="text" size="small">
                        <span style="text-decoration: underline">查看详情</span>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </section>

            <section class="section-card" v-if="invoiceInvalidApplyInfoList.length">
              <h3 class="section-title">
                <span></span>
                <div>作废申请记录</div>
              </h3>
              <div style="margin: 20px;">
                <el-table
                    border
                    :data="invoiceInvalidApplyInfoList"
                    :header-cell-style="{
                                        background: '#F3F4F7',
                                        color: '#555',
                                        padding: '5px 0'
                                    }"
                    :cell-style="{ padding: '5px 0' }">
                  <el-table-column prop="applyUserName" label="申请人" align="center" width="180"> </el-table-column>
                  <el-table-column prop="applyTime" label="申请时间" align="center" width="200"> </el-table-column>
                  <el-table-column prop="applyRemark" label="申请理由" align="center"> </el-table-column>
                  <el-table-column prop="state" label="状态" align="center" width="180">
                    <template slot-scope="scope">
                      <el-tag
                          effect="dark"
                          :type="scope.row.state == '0' ? 'warning' : scope.row.state == '1' ? 'success' : 'danger'">
                        {{scope.row.state == '0' ? '待审批' : scope.row.state == '1' ? '已通过' : '已拒绝'}}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="checkUserName" label="审批人" align="center" width="200"> </el-table-column>
                  <el-table-column prop="checkTime" label="审批时间" align="center" width="200"> </el-table-column>
                </el-table>
              </div>
            </section>

            <differDetailDialog v-if="isShowDiffer" :differRows="differRows" @close="isShowDiffer = false" @updateDiffer="fetchDifferList"></differDetailDialog>
            <TrackDialog v-if="disloagModule" :expressNo="expressNo" @close="disloagModule = false"></TrackDialog>
            <packageAndEpc
                v-if="packageModule"
                :detailList="detailLists"
                @close="packageModule = false"
            ></packageAndEpc>
            <distributionCheck v-if="CheckAcceptModule" :modalParams="drawerModalParams" @close="checkClose">
            </distributionCheck>
            <printDeliveryOrder
                v-if="printDialog"
                @close="printDialog = false"
                type="2"
                :modalParams="rowData"
                :isPrint="false"
            ></printDeliveryOrder>
            <entryCourierInfo
                v-if="entryCourierDialog"
                :modalParams="modalParams"
                :details="details"
                @close="entryCourier"
            >
            </entryCourierInfo>
<!--            <el-dialog-->
<!--                :append-to-body="true"-->
<!--                title="请选择包裹号"-->
<!--                :visible.sync="packDialogShow"-->
<!--                width="20%"-->
<!--                :before-close="packClose"-->
<!--                custom-class="pack-dialog"-->
<!--            >-->
<!--                <el-select v-model="packageId" size="small" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="item in packOptions"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="item.value"-->
<!--                        :disabled="item.state == 2"-->
<!--                    >-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--                <el-button type="primary" size="small" @click="packConfirm" style="margin-left: 20px">确 定</el-button>-->
<!--            </el-dialog>-->
            <el-dialog
                :append-to-body="true"
                title="请选择包裹号"
                :visible.sync="packDialogShow"
                width="40%"
                :before-close="packClose"
                custom-class="pack-dialog"
            >
                <el-table
                    :data="packOptions"
                    style="width: 100%"
                    tooltip-effect="dark"
                    border
                    ref='table'
                    @row-click="handleSelectionChange"
                    :row-key="packageId"
                    class='tableBack'
                    :row-class-name="tableRowClassName"
                    :header-cell-style="{
                            background: '#e8e8e8',
                            color: '#555',
                            padding: '5px 0'
                        }"
                >
                  <el-table-column label="选择" align="center" width="50" class='radios'>
                      <template slot-scope="scope">
                          <el-radio v-model="templateSelection" :label="scope.row.packageId" :disabled='scope.row.enableCheck=="Y"?false:true'  class='radios'></el-radio>
                      </template>
                  </el-table-column>
                  <el-table-column prop="packageSn" label="包裹号" align="center"></el-table-column>
                  <el-table-column prop="expressSn" label="包裹快递号" align="center"></el-table-column>
                  <el-table-column prop="logisticsName" label="物流公司" align="center"></el-table-column>
                  <el-table-column prop="state" label="验收状态" width="180" align="center">
                      <template slot-scope="scope">
                          {{ scope.row.state=='0'?'未验收':scope.row.state=='1'?'验收中':'已验收' }}
                      </template>
                  </el-table-column>
                  <el-table-column prop="goodsNum" label="货品件数" align="center"></el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="packConfirm"  style='margin-top: -30px'>确 定</el-button>
                </span>
            </el-dialog>
        </section>
    </el-drawer>
</template>

<script>
import Timeline from '@/components/Timeline/index.vue'
import differDetailDialog from '../../components/differDetailDialog'
import TrackDialog from '../../components/trackDialog.vue'
import { erpPackageRecallJdExpress } from '@/libs/http/api.js'
import {
  erp2DistributeQueryDetail,
  erp2DistributeQueryInvoicePackage,
  queryDiscrepancyList
} from '@/libs/http/modules/erp.js'
import distributionCheck from './distributionCheck.vue'
import printDeliveryOrder from '../../components/printDeliveryOrder.vue'
import entryCourierInfo from '../../components/entryCourierInfo.vue'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import { columnObj, sizeInfoLabel, footerObj } from './tableConfig.js'
import packageAndEpc from './packageEpcPh.vue'
export default {
    name: 'ShopDetails',
    props: {
        modalParams: {
            type: Object,
            default: () => null
        },
        type: {
            type: String,
            default: '1'
        }
    },
    components: {
        TrackDialog,
        printDeliveryOrder,
        entryCourierInfo,
        distributionCheck,
        SizeGroupTable,
        Timeline,
        packageAndEpc,
        differDetailDialog
    },
    data() {
        return {
            columnObj,
            sizeInfoLabel,
            footerObj,
            dialogVisible: false,
            CheckAcceptModule: false,
            entryCourierDialog: false,
            details: [],
            dataList: [],
            invoicesData: null,
            invoicesSnType: '',
            logisticsList: [],
            courierInfoDialog: false,
            scheduleInfoList: [],
            scheduleState: 1,
            disloagModule: false,
            rowData: null,
            printDialog: false,
            expressNo: null,
            packDialogShow: false,
            loading: false,
            packageId: '',
            packOptions: [],
            drawerModalParams: {},
            merageArr: [],
            meragePos: 0,
            merageArrs: [],
            meragePoss: 0,
            expressState: {
                0: {
                    type: 'warning',
                    label: '未揽收'
                },
                1: {
                    type: 'warning',
                    label: '待发货'
                },
                2: {
                    type: 'info',
                    label: '运输中'
                },
                3: {
                    type: '',
                    label: '配送中'
                },
                4: {
                    type: 'success',
                    label: '已妥投'
                }
            },
            packageModule: false,
            detailLists: [],
            //   当前选择的行的id
            templateSelection: "",
            checkList:null,

            differList: [],
            differRows: null,
            isShowDiffer: false,
            invoiceInvalidApplyInfoList:[]
        }
    },
    computed: {
        newSizeInfoLabels() {
            return this.formatLabels(this.sizeInfoLabel['PH'])
        },
        newFooterOptions() {
            return this.footerObj['PH']
        },
        newColumns() {
            return this.columnObj['PH']
        }
    },
    mounted() {
        this.fetchDetail()
        this.fetchDifferList()
    },
    methods: {
        handleSelectionChange(row) {
            if(row.enableCheck=='N') {
                this.$message.warning('该包裹已验收，无法选择！')
                return
            }
            this.templateSelection = row.packageId
            this.checkList = this.packOptions.filter((item) => item.packageId === row.packageId)
        },
        tableRowClassName({ row, columnIndex }) {
            if(row.enableCheck=='N') {
                return 'warning-row'
            }
            return 'unwarning-row'


        },
        formatLabels(arr) {
            return arr.map((item, index) => {
                if (index === 0) {
                    item.disabled = true
                } else if ([1, 2].includes(index)) {
                    item.disabled = this.scheduleState >= 2 ? false : true
                    item.value = this.scheduleState >= 2 ? true : false
                } else if ([3, 4].includes(index)) {
                    item.disabled = this.scheduleState > 3 ? false : true
                    item.value = this.scheduleState > 3 ? true : false
                }
                return item
            })
        },
        checkClose(e) {
            this.CheckAcceptModule = false
            if (e) {
                this.fetchDetail()
                this.$parent.refresh(e)
                // this.$emit('refresh', true)
            }
        },
        clickPackage(row) {
          this.packageModule = true
          this.detailLists = row
        },
        packageInfo(row) {
            this.printDialog = true
            row.invoicesId = this.modalParams.id
            this.rowData = row
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        fetchDetail() {
            this.loading = true
            this.dialogVisible = true
            erp2DistributeQueryDetail({
                id: this.modalParams.id
            })
                .then((data) => {
                    this.loading = false
                    this.invoicesData = data.invoicesInfo
                    this.modalParams.state = data.invoicesInfo.state // 更新状态
                    this.invoicesSnType = data.invoicesInfo.invoicesSn.slice(0, 2) || 'PH'
                    this.$nextTick(() => {
                        this.dataList = data.goodsInfoList || []
                    })
                    this.invoiceInvalidApplyInfoList = data.invoiceInvalidApplyInfoList || []
                    this.logisticsList = data.logisticsList || []
                    this.merage(this.logisticsList)
                    this.scheduleInfoList = data.scheduleInfoList || []
                    this.scheduleState = Number(data.scheduleState)
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        // getSpanArr(data, spanKey) {
        //     let spanArr = [] //值
        //     let pos = '' //下标
        //     for (let i = 0; i < data.length; i++) {
        //         if (i === 0) {
        //             spanArr.push(1)
        //             pos = 0
        //         } else {
        //             // 判断当前元素与上一个元素是否相同
        //             if (data[i][spanKey] === data[i - 1][spanKey]) {
        //                 spanArr[pos] += 1
        //                 spanArr.push(0)
        //             } else {
        //                 spanArr.push(1)
        //                 pos = i
        //             }
        //         }
        //     }
        //     return spanArr
        // },
        // arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        //     let currindex = [0, 3, 4, 5, 6, 7, 8, 9, 10]
        //     if (currindex.indexOf(columnIndex) != -1) {
        //         let spanArr = this.getSpanArr(this.logisticsList, row.invoicesNo)
        //         const _row = spanArr[rowIndex]
        //         const _col = _row > 0 ? 1 : 0
        //         return {
        //             rowspan: _row,
        //             colspan: _col
        //         }
        //     }
        // },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex < 3) {
                const _row = this.merageArr[rowIndex]
                const _col = _row > 0 ? 1 : 0 //如果被合并了_row=0则它这个列需要取消
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        merage(tableData) {
            this.merageInit()
            for (var i = 0; i < tableData.length; i++) {
                if (i === 0) {
                    // 第一行正常显示 必须存在
                    this.merageArr.push(1)
                    this.meragePos = 0
                    this.merageArrs.push(1)
                    this.meragePoss = 0
                } else {
                    // 判断当前元素与上一个元素是否相同 根据是否合并的id
                    if (tableData[i].invoicesSn === tableData[i - 1].invoicesSn) {
                        this.merageArr[this.meragePos] += 1
                        this.merageArr.push(0)
                    } else {
                        this.merageArr.push(1)
                        this.meragePos = i
                    }
                    if (
                        tableData[i].invoicesSn === tableData[i - 1].invoicesSn &&
                        tableData[i].expressNo === tableData[i - 1].expressNo
                    ) {
                        this.merageArrs[this.meragePoss] += 1
                        this.merageArrs.push(0)
                    } else {
                        this.merageArrs.push(1)
                        this.meragePoss = i
                    }
                }
            }
        },
        merageInit() {
            this.merageArr = []
            this.meragePos = 0
            this.merageArrs = []
            this.meragePoss = 0
        },
        handleClick(row) {
            row.invoicesId = this.modalParams.id
            this.expressNo = row
            this.disloagModule = true
        },

        amendClick(row) {
            if (row.type == '1') {
                this.$confirm(`是否取消快递单`, {
                    type: 'warning'
                })
                    .then(() => {
                        erpPackageRecallJdExpress({
                            waybillCode: row.expressNo
                        })
                            .then((data) => {
                                this.$message.success('取消成功')
                                this.$nextTick(() => {
                                    this.fetchDetail()
                                })
                                this.entryCourierDialog = true
                                this.details = row
                            })
                            .catch((err) => {
                                this.$message({
                                    type: 'error',
                                    message: err,
                                    duration: 2000,
                                    showClose: true
                                })
                            })
                    })
                    .catch(() => {})
            }
            if (row.type == '2') {
                this.entryCourierDialog = true
                this.details = row
            }
        },
        entryCourier(refetch) {
            this.entryCourierDialog = false
            if (refetch) {
                this.fetchDetail()
            }
        },

        checkGet() {
            if (this.type == 1) {
              if(this.modalParams['rfidInvoicePack'] === 'Y' && this.modalParams['rfidDistributeInputSwitch'] === 'Y'){
                this.$confirm(`本单已通过RFID芯片进行出货，并且店铺已经开启了使用RFID芯片收货。请使用盘点机对本单进行收货！`, '提示', {
                  showClose: false,
                  showCancelButton: false,
                  closeOnClickModal: false,
                  confirmButtonClass: false,
                  confirmButtonText: '知道了'
                }).then(res=>{

                }).catch(()=>{})
                return
              }
              this.getPackOptions()
            } else {
                this.CheckAcceptModule = true
            }
        },
        packClose() {
            this.templateSelection=''
            this.checkList=[]
            this.packDialogShow = false
            this.packageId = ''
        },
        packConfirm() {
            if (!this.templateSelection) {
                this.$message.error('请选择包裹号')
                return
            }
            this.CheckAcceptModule = true
            this.drawerModalParams = { packageId: this.templateSelection  }
            this.packClose()
        },
        getPackOptions() {
            let params = {
                id: this.modalParams.id
            }
            erp2DistributeQueryInvoicePackage(params)
                .then((res) => {
                    if (res && res.itemList) {
                        if (res.itemList.length === 1) {
                            this.CheckAcceptModule = true
                            this.drawerModalParams = { packageId: res.itemList[0].packageId }
                            this.packClose()
                        } else if (res.itemList.length > 1) {
                            this.packOptions = res.itemList.map((i) => ({
                                packageSn: i.packageSn,
                                packageId: i.packageId,
                                expressSn:i.expressSn,
                                state: i.state,
                                goodsNum:i.goodsNum,
                                enableCheck:i.enableCheck,
                            }))
                            let found = this.packOptions.find((item) => {
                                return item.state === '0' // 返回第一个满足条件的项
                            })

                            if (found) {
                                this.packageId = found.value // 更新this.packageId// 的值为找到的项的 value
                            }
                            this.packDialogShow = true
                        }
                    }
                })
                .catch((rej) => {
                    this.$message.error(rej)
                })
        },


      fetchDifferList(){
        queryDiscrepancyList({
          page: '1',
          pageSize: '100',
          queryText: this.modalParams.sn
        }).then(res=>{
          console.log(res)
          this.differList = res.items || []
        }).catch(error=>{})
      },
      showDifferDetail(row){
        console.log(row)
        this.differRows = row
        this.isShowDiffer = true
      },
    }
}
</script>

<style lang="scss" scoped>
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 18px;
        color: #333;
    }

    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }

    .sub-box {
        color: black;
        margin: 10px 15px;
        padding: 10px 50px;
        background: #c6e7ff;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        border-radius: 3px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

.step {
    width: 100%;
    padding-bottom: 50px;
}

.section-card {
    position: relative;
    margin-bottom: 30px;
    .text-link {
        color: #1890ff;
        cursor: pointer;
    }

    .section-title {
        font-size: 18px;
        color: #333;
        font-weight: normal;
        display: flex;
        align-items: center;

        span {
            margin-top: -2px;
            width: 4px;
            height: 18px;
            background: #067cf2;
            margin-right: 16px;
            border-radius: 0 2px 2px 0;
        }
    }

    > div {
        margin: 20px;
    }

    .detail-state {
        position: absolute;
        right: 20px;
        top: -50px;

        img {
            width: 80px;
        }
    }

    .section-value {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;

        > div {
            width: calc(100% / 3);
            margin-bottom: 10px;
        }
    }
}

.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}
::v-deep .radios .el-radio__label{
    display: none;
}
::v-deep .el-table .warning-row {
    color: #b0afac !important;
    background: #f5f7fa!important;
}
::v-deep .el-table .unwarning-row {
    color: red !important;
}
</style>
