<template>
  <aside class="aside-left">
    <section class="section-cont" ref="cont" @scroll="alive">
      <section class="aside-form">
        <el-form ref="form" :model="form" label-position="left" label-width="auto">
          <el-row type="flex" :gutter="20" style="flex-wrap: wrap; margin-left: -6px; margin-right: -22px">
            <el-col :span="12">
              <el-form-item label="销售单号" prop="orderNo">
                <input
                  v-focusInput
                  type="text"
                  ref="invoiceInput"
                  placeholder="请输入销售单号"
                  v-model="form.orderNo"
                  style="
                    width: 150px;
                    outline: none;
                    border: 1px solid #ddd;
                    line-height: 30px;
                    border-radius: 4px;
                    padding: 0 10px;
                  " />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="货号/条码" prop="keyword">
                <el-input
                  style="width: 150px"
                  clearable
                  size="small"
                  v-model="form.keyword"
                  placeholder="请输入货号/条码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="1===2">
              <el-form-item label="日期" prop="startTime">
                <el-date-picker
                    v-model="form.datePicker"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyyMMdd"
                    style="width: 408px;"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <template v-if="moreItem">
              <el-col :span="12" v-if="1 === 3">
                <el-form-item label="订单性质" prop="invoicesTypeId">
                  <el-select
                    v-model="form.invoicesTypeId"
                    style="width: 150px"
                    size="small"
                    placeholder="请选择"
                    clearable
                    filterable
                    remote
                    :remote-method="queryInvoceList"
                    @clear="queryInvoceList('')">
                    <el-option v-for="item in ordingTypeList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="业绩方式" prop="brokerage">
                  <el-select v-model="form.brokerage" style="width: 150px" size="small" placeholder="请选择" clearable>
                    <el-option v-for="item in brokerageList" :key="item.code" :label="item.name" :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="营业员" prop="shoppingGuideId">
                  <el-select
                    v-model="form.shoppingGuideId"
                    style="width: 150px"
                    size="small"
                    placeholder="请选择"
                    clearable
                    filterable
                    remote
                    :remote-method="queryGuide"
                    @clear="queryGuide('')">
                    <el-option v-for="item in userList" :key="item.id" :label="item.realName" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="会员手机号" prop="crmVipId">
                  <el-select
                    v-if="1 === 2"
                    v-model="form.crmVipId"
                    style="width: 150px"
                    size="small"
                    placeholder="请选择"
                    clearable
                    filterable
                    remote
                    :remote-method="queryVipList"
                    @clear="queryVipList('')">
                    <div style="max-height: 200px; overflow: auto">
                      <el-option v-for="item in vipList" :key="item.id" :label="item.nickName" :value="item.id">
                      </el-option>
                    </div>
                    <el-pagination
                      layout="prev, pager, next"
                      :pager-count="5"
                      :page-size="20"
                      :hide-on-single-page="false"
                      :total="totalVip"
                      :current-page="vipPage - 0"
                      @current-change="getCurPage">
                    </el-pagination>
                  </el-select>
                  <el-input clearable placeholder="请输入会员手机号" size="small" v-model="form.crmVipKey"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="单据来源" prop="source">
                  <el-select v-model="form.source" style="width: 150px" size="small" placeholder="请选择" clearable>
                    <el-option
                      v-for="item in invoicesSourceList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <template v-if="invoicesType === '2'">
                <el-col :span="12">
                  <el-form-item label="订单状态" prop="orderState">
                    <el-select
                      v-model="form.orderState"
                      style="width: 150px"
                      size="small"
                      placeholder="请选择"
                      clearable>
                      <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="支付方式" prop="paymentModeInfoId">
                    <el-select
                      v-model="form.paymentModeInfoId"
                      style="width: 150px"
                      size="small"
                      placeholder="请选择"
                      clearable
                      filterable
                      remote
                      :remote-method="queryPayList"
                      @clear="queryPayList('')">
                      <el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="参与促销" prop="hasPromotionInfo">
                    <el-select
                      v-model="form.hasPromotionInfo"
                      style="width: 150px"
                      size="small"
                      placeholder="请选择"
                      clearable>
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="发生售后" prop="hasAftersalesInfo">
                    <el-select
                      v-model="form.hasAftersalesInfo"
                      style="width: 150px"
                      size="small"
                      placeholder="请选择"
                      clearable>
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item label="发生审批" prop="hasReviewInfo">
                    <el-select
                      v-model="form.hasReviewInfo"
                      style="width: 150px"
                      size="small"
                      placeholder="请选择"
                      clearable>
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="次品销售单" prop="isDefectiveSalary">
                    <el-select
                      v-model="form.isDefectiveSalary"
                      style="width: 150px"
                      size="small"
                      placeholder="请选择"
                      clearable>
                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </template>
          </el-row>
          <div class="row align-center space-end form-tools">
            <div class="row align-center" @click="showMore">
              {{ moreItem ? '收起' : '展开' }}
              <span v-if="!moreItem" class="el-icon-arrow-down"></span>
              <span v-else class="el-icon-arrow-up"></span>
            </div>
            <div class="cancel" @click="resetKey">重 置</div>
            <el-button
              class="sure"
              native-type="submit"
              v-no-more-click
              @click="
                () => {
                  changeindex = 0
                  queryHangList('search')
                }
              "
              v-loading="searchListIng"
              element-loading-spinner="el-icon-loading"
              >确 定</el-button
            >
          </div>
        </el-form>
      </section>
      <section v-if="cardList.length">
        <ordingCard
          v-for="(item, index) in cardList"
          :key="item.id"
          :item="item"
          :indexL="index"
          :curId="ordingId"
          :invoicesType="invoicesType"
          @choose="chooseItem(item, index)">
        </ordingCard>
      </section>
      <noDataState v-else textDesc="未查询到单据记录" marginTop="160"></noDataState>
    </section>
    <section
      v-if="cardList.length"
      class="section-page row align-center space-center"
      style="height: 40px; margin-top: 10px">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :pager-count="5"
        background
        @current-change="cahngeCurPage"
        :current-page="page - 0">
      </el-pagination>
    </section>
  </aside>
</template>

<script>
import moment from 'moment'
import ordingCard from '../../../components/ordingCard.vue'
import noDataState from '@/components/noDataState.vue'
import { queryMemberList } from '@/libs/http/modules/vip'
import {
  queryStoreInvoicesList,
  queryShoppGuide,
  queryPaymentList,
  queryInvoiceType,
  deleteOrder,
  queryBrokerage
} from '@/libs/http/modules/posOrder.js'
export default {
  name: 'AsideLeft',
  props: ['invoicesType'],
  components: {
    ordingCard,
    noDataState
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let lastMonth = moment().subtract(1, 'months').format('yyyy-MM')
          let lastDateString = new Date(lastMonth + '-01 00:00:00').getTime()
          return time.getTime() > Date.now() || time.getTime() < lastDateString
        }
      },
      form: {
        orderNo: '',
        keyword: '',
        startTime: '',
        endTime: '',
        datePicker: '',
        invoicesTypeId: '',
        brokerage: '',
        shoppingGuideId: '',
        crmVipId: '',
        crmVipKey: '',
        source: '',
        orderState: '',
        paymentModeInfoId: '',
        hasPromotionInfo: '',
        hasReviewInfo: '',
        isDefectiveSalary: '',
        hasAftersalesInfo: ''
      },
      brokerageList: [],
      invoicesSourceList: [
        { label: '手机端', value: '1' },
        { label: '客户端', value: '2' }
      ],
      marketingList: [
        { label: '线下直营', value: '1' },
        { label: '线下加盟', value: '2' },
        { label: '联营', value: '3' },
        { label: '批发', value: '4' }
      ],
      stateList: [
        { label: '已取消', value: '-1' },
        { label: '待支付', value: '1' },
        { label: '已完成', value: '3' },
        { label: '已作废', value: '5' },
        { label: '待确认', value: '6' }
      ],
      options: [
        { label: '是', value: 'Y' },
        { label: '否', value: 'N' }
      ],
      userList: [],
      ordingTypeList: [],
      payList: [],
      moreItem: false,
      cardList: [],
      ordingId: '',
      invoicesNo: '',
      isSearch: false,
      total: 0,
      page: 1,
      pageSize: 10,
      scrollPages: 0,
      changeindex: 0,
      searchListIng: false,
      vipList: [],
      vipPage: 1,
      totalVip: 0,
      vipKey: ''
    }
  },
  created() {
    this.queryPayList()
    this.queryGuide()
    this.queryBrokerageList()
    this.$nextTick(() => {
      this.$refs.invoiceInput.focus()
    })
  },
  activated() {
    if (this.scrollPages > 0) {
      this.$nextTick(() => {
        this.$refs.cont.scrollTop = this.scrollPages
      })
    }
    this.resetKey()
  },
  methods: {
    alive(e) {
      // 获取页面滚动条的位置
      this.scrollPages = e.target.scrollTop
    },
    showMore() {
      this.moreItem = !this.moreItem
    },
    async queryBrokerageList() {
      let res = await queryBrokerage({ platform: 'pos' })
      this.brokerageList = res || []
    },
    async queryGuide(name) {
      let res = await queryShoppGuide({ keyword: name, page: '1', pageSize: '50' })
      this.userList = res.items || []
    },
    async queryInvoceList() {
      let res = await queryInvoiceType()
      this.ordingTypeList = res
    },
    async queryVipList(name) {
      if (name) {
        this.vipKey = name
      }
      let res = await queryMemberList({ keyword: this.vipKey, page: this.vipPage, pageSize: '20' })
      this.vipList = res.items || []
      this.totalVip = Number(res.totalItem) || 0
    },
    getCurPage(val) {
      this.vipPage = val
      this.queryVipList(this.vipKey)
    },
    async queryPayList() {
      let res = await queryPaymentList()
      this.payList = res || []
    },
    resetKey() {
      for (let key in this.form) {
        this.form[key] = ''
      }
    },
    queryHangList(type, form, page) {
      this.searchListIng = true
      this.page = page ? page : '1'
      if (type === 'search') {
        this.isSearch = true
      } else {
        this.isSearch = false
      }
      let reqData = {
        type: this.invoicesType,
        page: this.page,
        pageSize: this.pageSize + ''
      }
      if (form) {
        if (form.reset) {
          //传过来的数据有这个属性重置查询条件
          for (let key in this.form) {
            if (this.form[key]) {
              this.form[key] = ''
            }
          }
        } else {
          //获取传不过来的查询条件
          for (let key in form) {
            this.form[key] = form[key]
          }
        }
      }
      for (let key in this.form) {
        if (this.form[key] && key !== 'datePicker') {
          reqData[key] = this.form[key]
        }
      }
      if(this.form.orderNo){
        this.form.datePicker = ''
      }
      if(this.form.datePicker && this.form.datePicker.length){
        reqData['startTime'] = this.form.datePicker[0]
        reqData['endTime'] = this.form.datePicker[1]
      }
      queryStoreInvoicesList(reqData)
        .then(data => {
          this.cardList = data.items || []
          if (data.items && data.items.length) {
            this.total = Number(data.totalItem)
            this.ordingId = String(this.cardList[0].id)
            this.invoicesNo = this.cardList[0].orderNo
            let obj = {
              id: this.ordingId,
              invoicesNo: this.invoicesNo,
              first: type === 'first' ? true : false,
              item: this.cardList[0]
            }
            this.$emit('sendObj', obj)
          } else {
            this.$emit('sendObj')
          }
          this.$nextTick(() => {
            this.$refs.cont.scrollTop = 0
          })
          this.searchListIng = false
        })
        .catch(err => {
          this.searchListIng = false
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },

    chooseItem(item, index) {
      if (this.ordingId === item.id) return
      let obj = {
        id: item.id,
        invoicesNo: item.orderNo,
        item: item
      }
      this.$emit('sendObj', obj)
      this.ordingId = String(item.id)
      item.isChoose = !item.isChoose
      this.changeindex = index
    },
    cahngeCurPage(val) {
      this.page = val
      this.changeindex = 0
      this.queryHangList('', '', val)
    },
    deleteCurOrding() {
      deleteOrder({
        orderId: this.ordingId
      })
        .then(data => {
          this.$message({ type: 'success', message: '操作成功', duration: 2000, showClose: true })
          this.queryHangList()
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    delecardList() {
      let index = this.cardList.findIndex(item => {
        return item.id === this.ordingId
      })
      this.cardList.splice(index, 1)
    },
    changeState(state, price) {
      setTimeout(() => {
        if (this.cardList[this.changeindex]) {
          this.cardList[this.changeindex].state = state
          if (price) {
            this.cardList[this.changeindex].realAmount = price
          }
        }
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.aside-left {
  width: 500px;
  margin: 10px 0 10px 10px;
  background: #fff;
  height: calc(100vh - 80px);
  border-right: 1px solid #ccc;
  .section-cont {
    height: calc(100vh - 140px);
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }
  .aside-form {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    .form-tools {
      margin-bottom: 20px;
      font-family: 'font-Light';
      font-size: 14px;
      div:first-child {
        margin-right: 10px;
        cursor: pointer;
        span {
          color: #333;
          font-weight: bold;
        }
      }
      .cancel {
        border: 1px solid #dcdfe6;
        width: 80px;
        text-align: center;
        line-height: 34px;
        border-radius: 4px;
        margin-right: 10px;
        cursor: pointer;
      }
      .sure {
        background: #067cf2;
        width: 80px;
        text-align: center;
        line-height: 34px;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        padding: 0;
        font-family: 'font-Light';
      }
    }
  }
}
</style>
<style>
.aside-form .el-form-item {
  margin-bottom: 10px;
  font-family: 'font-Regular';
}
.aside-form .el-input--prefix .el-input__inner {
  padding-left: 14px;
}
.aside-form .el-input__prefix {
  display: none;
}
</style>
