export const addColumns = [
    {
        prop: 'goodsNo',
        label: '货号'
    },
    {
        prop: 'goodsCode',
        label: '简码'
    },
    {
        prop: 'goodsName',
        label: '名称'
    },
    {
        prop: 'colorValue',
        label: '颜色'
    },
    {
        prop: 'sizeInfoList',
        width: '800px',
        boxWidth: '1px' //复选框宽度
    },
    {
        prop: 'total',
        label: '总数',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    },
]

export const addSizeInfoLabel = [
    {
        prop: 'num', //复选框key//valueInfoList当前行对应key
        label: '', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    }
]