<template>
    <el-dialog
        append-to-body
        title="收货数量有差异，请确认差异情况"
        :visible.sync="dialogVisible"
        @close="closeDialog">
        <div class="content">
          <div class="package-info">
            <div>
              <span>单号：{{modalParams['invoicesSn']}}</span>
              <span>包裹号：{{modalParams['packageNo']}}</span>
            </div>
            <div>
              <span>总数：{{modalParams['distriNumTotal']}}</span>
              <span>已收：{{modalParams['ReceivedTotal']}}</span>
              <span class="color-red">未收：{{modalParams['noReceivedTotal']}}</span>
            </div>
          </div>
          <div v-if="dialogState === '1'">
            <div class="tip-text">请检查包裹并清点实物明细，比对实物明细与系统出货明细（{{modalParams['distriNumTotal']}}），从下方选择一个符合的情况。</div>
            <div>
              <div class="reason-item" :class="[itemActive === '2' ? 'active-class' : '']" @click="itemActive = '2'">实物明细 多于 系统出货明细</div>
              <div class="reason-item" :class="[itemActive === '3' ? 'active-class' : '']" @click="itemActive = '3'">实物明细 少于 系统出货明细</div>
              <div class="reason-item" :class="[itemActive === '4' ? 'active-class' : '']" @click="itemActive = '4'">实物明细 等于 系统出货明细</div>
              <div class="reason-item" :class="[itemActive === '5' ? 'active-class' : '']" @click="itemActive = '5'">实物明细与单据大部分或完全对不上</div>
            </div>
            <div class="row align-center space-end">
              <el-button type="primary" @click="sureActive">确 定</el-button>
            </div>
          </div>

          <div v-if="dialogState === '2'">
            <div class="tip-text">实物明细多于系统出货明细，代表发货方随包多发了数量或其他款。系统将按出货数量（{{modalParams['distriNumTotal']}}）收货入库，多发的数量请联系后台人员协商处理。</div>
            <div class="row align-center space-end">
              <el-button @click="dialogState = '1'">返回选择</el-button>
              <el-button type="primary" @click="submitBtn">确定完成收货</el-button>
            </div>
          </div>
          <div v-if="dialogState === '3'">
            <div class="tip-text">实物明细少于系统出货明细，代表缺少实物可能漏装或丢件了。操作收货后系统将产生差异记录，需要核对差异的数量属于哪一方的问题，联系后台人员协商处理。可前往[差异处理]功能查看差异记录。</div>
            <div class="row align-center space-end">
              <el-button @click="dialogState = '1'">返回选择</el-button>
              <el-button type="primary" @click="submitBtn">确定完成收货</el-button>
            </div>
          </div>
          <div v-if="dialogState === '4'">
            <div class="tip-text">实物明细等于系统出货明细，但收货数量比实物少。可能存在货品有漏扫或重复扫，请检查货品收货数量，重新调整扫码货品的收货数量后再提交。</div>
            <div class="row align-center space-end">
              <el-button @click="dialogState = '1'">返回选择</el-button>
              <el-button type="primary" @click="closeDialog">关闭，返回收货</el-button>
            </div>
          </div>
          <div v-if="dialogState === '5'">
            <div class="tip-text">
              可能快递贴错单错发了或其他特殊情况，请联系后台人员协商处理，
              <span class="color-red">后台通知可以收货时再操作确定收货，并配合处理后续其他操作。</span>
              操作确定收货后，系统将按出货数量（{{modalParams['distriNumTotal']}}）收货入库。
            </div>
            <div class="row align-center space-end">
              <el-button @click="dialogState = '1'">返回选择</el-button>
              <el-button type="primary" @click="submitBtn">确定完成收货</el-button>
            </div>
          </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
  props: {
    modalParams: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      dialogVisible: true,
      dialogState: '1',
      itemActive: ''
    }
  },
  created() {
    console.log(this.modalParams)
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false
      this.$emit('close')
    },
    sureActive(){
      if(!this.itemActive){
        return this.$message({ type: 'error', message: `请选择一种情况再确定`, duration: 2000, showClose: true })
      }
      this.dialogState = this.itemActive
    },
    submitBtn(){
      this.dialogVisible = false
      this.$emit('close', this.itemActive)
    }
  }
}
</script>
<style lang="scss" scoped>
.content{
  color: #000;
  font-size: 16px;
  margin-top: -20px;
  .color-red{
    color: red;
  }
  .package-info{
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    background: rgb(245,247,250);
    div{
      &:last-child{
        margin-top: 10px;
      }
      span{
        margin-right: 40px;
      }
    }
  }
  .reason-item{
    width: 400px;
    height: 36px;
    cursor: pointer;
    line-height: 36px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;
    background: rgb(245,245,245);
    border: 1px solid rgb(245,245,245);
  }
  .active-class{
    background: rgb(230,247,255);
    border-color: rgb(7,124,242);
  }
  .tip-text{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
