<template>
    <!-- 铺货单-收 -->
    <div>
        <div class="dept-retreat">
            <div class="grid-box">
                <div class="right-box">
                    <div>
                        <basic-button
                            :disabled="displayRule('check')"
                            @click="getPackOptions()"
                            type="primary"
                            :loading="loading"
                            icon-class="acceptance"
                            style="width: 200px"
                        >
                            开始验收
                        </basic-button>
                    </div>
                </div>
                <grid-manager :option="gridOption"></grid-manager>
            </div>
            <SearchFormItem
                :options="options"
                @searchHandel="sureSharch"
                @getState="getFormState"
                ref="SearchFormItemIns"
            ></SearchFormItem>
        </div>
        <distributionCheck
            v-if="CheckAccepting"
            :modalParams="modalParams"
            @close="closeDrawer"
            @refetch="sureSharch"
        ></distributionCheck>
        <distributionDetail
            v-if="detailDrawer"
            :modalParams="modalParams"
            @close="detailDrawer = false"
            @refresh='refresh'
            type="1"
        ></distributionDetail>
        <el-dialog
            :append-to-body="true"
            title="请选择包裹号"
            :visible.sync="packDialogShow"
            width="40%"
            :before-close="packClose"
            custom-class="pack-dialog"
        >
            <el-table
                :data="packOptions"
                style="width: 100%"
                tooltip-effect="dark"
                border
                ref='table'
                @row-click="handleSelectionChange"
                :row-key="packageId"
                class='tableBack'
                :row-class-name="tableRowClassName"
                :header-cell-style="{
                            background: '#e8e8e8',
                            color: '#555',
                            padding: '5px 0'
                        }"
            >
              <el-table-column label="选择" align="center" width="50" class='radios'>
                  <template slot-scope="scope">
                      <el-radio v-model="templateSelection" :label="scope.row.packageId" :disabled='scope.row.enableCheck=="Y"?false:true'  class='radios'></el-radio>
                  </template>
              </el-table-column>
              <el-table-column prop="packageSn" label="包裹号" align="center"></el-table-column>
              <el-table-column prop="expressSn" label="包裹快递号" align="center"></el-table-column>
              <el-table-column prop="logisticsName" label="物流公司" align="center"></el-table-column>
              <el-table-column prop="state" label="验收状态" width="180" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.state=='0'?'未验收':scope.row.state=='1'?'验收中':'已验收' }}
                  </template>
              </el-table-column>
              <el-table-column prop="goodsNum" label="货品件数" align="center"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="packConfirm"  style='margin-top: -30px'>确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {columnData, options } from './configBution'
import { erp2DistributeQueryList, erp2DistributeQueryInvoicePackage,erp2MarketingUnitSpuPriceRelationQueryPrincipal } from '@/libs/http/modules/erp.js'
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import distributionCheck from './components/distributionCheck.vue'
import distributionDetail from './components/distributionDetail.vue'

export default {
    name: '',
    components: {
        SearchFormItem,
        distributionCheck,
        distributionDetail
    },
    data() {
        return {
            loading: false,
            packDialogShow: false,
            packageId: '',
            packOptions: [],

            gridOption: {
                width: '80vw - 345px',
                height: '85.5vh',
                gridManagerName: 'transferSlipinput',
                firstLoading: true,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useTrFocus: true,
                supportPrint: false,
                lineHeight: '20px',
                checkboxConfig: {
                    fixed: 'left',
                    width: '80px',
                    useRowCheck: true
                },
                columnData,
                ajaxData: this.fetch,
				        ajaxError: this.ajaxError,
                supportAjaxPage: true,
                cellDblClick: this.rowdbClick,
                checkedAfter: this.checkedAfter,
                dataKey: 'items',
                totalsKey: 'totalItem'
            },
            options,
            queryKey: {},
            CheckAccepting: false,
            detailDrawer: false,
            checkedList: [],
            //   当前选择的行的id
            templateSelection: "",
            checkList:null,
            modalParams: null
        }
    },
    created() {
        this.LeaderUnderDept()
    },
    methods: {
        refresh(e) {
            if(e)  {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },

        handleSelectionChange(row) {
            if(row.enableCheck=='N') {
                this.$message.warning('该包裹已验收，无法选择！')
                return
            }
            this.templateSelection = row.packageId
            this.checkList = this.packOptions.filter((item) => item.packageId === row.packageId)
        },
        tableRowClassName({ row, columnIndex }) {
            if(row.enableCheck=='N') {
                return 'warning-row'
            }
            return 'unwarning-row'


        },
        LeaderUnderDept() {
            erp2MarketingUnitSpuPriceRelationQueryPrincipal({})
                .then((res) => {
                    this.options.searchItem[8].list=res?.items?.map(item=> {
                        return {
                            name:item.name,
                            value:item.id
                        }
                    })
                    }
                )
                .catch((rej) => {
                    this.$message.error(rej)
                })
        },
        displayRule(type) {
            let rule = true
            let list = this.checkedList
            if (list && list.length) {
                switch (type) {
                    case 'check':
                        rule = !(this.checkedList.length === 1 && list.every((i) => ['9', '11'].includes(i.state)))
                        break
                    default:
                        break
                }
            }
            return rule
        },
        refetch() {
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        getFormState(e) {
            GridManager.resetLayout(this.gridOption.gridManagerName, e ? '80vw - 345px' : '80vw - 40px', '83vh')
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        sureSharch(e) {
            this.queryKey = e
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        closeDrawer(e) {
            this.checkedList=[]
            this.CheckAccepting = false
            GridManager.setCheckedData(this.gridOption.gridManagerName, [])
            if (e) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        fetch(params) {
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                marketId: [this.$cache.local.get('marketingUnitId')],
                delFlag: '0'
            }
            if (!this.queryKey?.state?.length) {
                reqData['state'] = ['9', '10', '11']
            }
            for (let key in this.queryKey) {
                if (!['dateUpte', 'dateCreate','snList','externalSystemSn1'].includes(key)) {
                    reqData[key] = this.queryKey[key]
                }
                if (this.queryKey.snList) {
                    // reqData['invoicesNo'] = this.queryKey.invoicesNo.trim().split(/[ ]+/)
                    reqData['snList'] = this.queryKey.snList.trim().split(/[ ]+/)
                }
                if (this.queryKey.externalSystemSn1) {
                    reqData['externalSystemSn1'] = this.queryKey.externalSystemSn1.trim().split(/[ ]+/)
                }
                if (this.queryKey.createTime && this.queryKey.createTime.length) {
                    let [start, end] = this.queryKey.createTime
                    reqData['createStartTime'] = `${start} 00:00:00`
                    reqData['createEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.updateTime && this.queryKey.updateTime.length) {
                    let [start, end] = this.queryKey.updateTime
                    reqData['updateStartTime'] = `${start} 00:00:00`
                    reqData['updateEndTime'] = `${end} 23:59:59`
                }
            }
            GridManager.setCheckedData(this.gridOption.gridManagerName, [])
            this.checkedList = []
            this.$parent.quantiting()
            return erp2DistributeQueryList(reqData)
        },
		ajaxError(e){
			this.$message({type: 'error', message: e, duration: 2000, showClose: true})
		},
        packClose() {
            this.packDialogShow = false
            this.packageId = ''
            this.templateSelection=''
            this.checkList=[]
        },
        packConfirm() {
            if (!this.templateSelection) {
                this.$message.error('请选择包裹号')
                return
            }
            this.CheckAccepting = true
            this.modalParams = { packageId: this.templateSelection }
            this.packClose()
        },
        rowdbClick(row, rowIndex, colIndex) {
            if (colIndex == 1 || colIndex == 0) return
            this.showDetails(row)
        },
        showDetails(row) {
            this.detailDrawer = true
            this.modalParams = row
        },
        checkedAfter(a) {
            this.checkedList = a
        },
        getPackOptions() {
            if(this.checkedList[0].rfidInvoicePack === 'Y' && this.checkedList[0].rfidDistributeInputSwitch === 'Y'){
              this.$confirm(`本单已通过RFID芯片进行出货，并且店铺已经开启了使用RFID芯片收货。请使用盘点机对本单进行收货！`, '提示', {
                showClose: false,
                showCancelButton: false,
                closeOnClickModal: false,
                confirmButtonClass: false,
                confirmButtonText: '知道了'
              }).then(res=>{

              }).catch(()=>{})
              return
            }
            let params = {
                id: this.checkedList[0].id
            }
            erp2DistributeQueryInvoicePackage(params)
                .then((res) => {
                    if (res && res.itemList) {
                        if (res.itemList.length === 1) {
                            this.CheckAccepting = true
                            this.modalParams = { packageId: res.itemList[0].packageId }
                            this.packClose()
                        } else if (res.itemList.length > 1) {
                            this.packOptions = res.itemList.map((i) => ({
                                packageSn: i.packageSn,
                                packageId: i.packageId,
                                expressSn:i.expressSn,
                                state: i.state,
                                goodsNum:i.goodsNum,
                                enableCheck:i.enableCheck,
                            }))
                            let found = this.packOptions.find((item)=> {
                                return item.state === "0";  // 返回第一个满足条件的项
                            });

                            if (found) {
                                this.packageId = found.value;  // 更新 a 的值为找到的项的 value
                            }
                            this.packDialogShow = true
                        }
                    }
                })
                .catch((rej) => {
                    this.$message.error(rej)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.dept-retreat {
    display: flex;
    padding: 10px;
    .right-box {
        display: flex;
        justify-content: space-between;
    }
}
::v-deep .radios .el-radio__label{
   display: none;
}
::v-deep .el-table .warning-row {
    color: #b0afac !important;
    background: #f5f7fa!important;
}
::v-deep .el-table .unwarning-row {
    color: red !important;
}

</style>
