import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wsConnected: false,
    deviceMicrophoneList: [],
    pluginList: [],
    startPlugin: [],
    barcode: {}
  },
  mutations: {
    changeWsConnected(state,connected) {
      console.log(`connected = ${connected}`);
      state.wsConnected = connected;
    },
    initDeviceMicrophoneList(state,list){
      state.deviceMicrophoneList = list;
    },
    playExceedSize(state, err){
      let scanTip = new Audio(require("@/assets/audio/exceedSize.mp3"));
      scanTip.play();
    },
    playExceedGoodsNo(state, err){
      let scanTip = new Audio(require("@/assets/audio/exceedGoodsno.mp3"));
      scanTip.play();
    },
    playExceedNumber(state, err){
      let scanTip = new Audio(require("@/assets/audio/exceedNumber.mp3"));
      scanTip.play();
    },
    playErrorAudio(state, err) {
      let scanTip = new Audio(require("@/assets/audio/warning.mp3"));
      scanTip.play();
    },
    playSuccessAudio(state, err) {
      let scanTip = new Audio(require("@/assets/audio/scan_tip.mp3"));
      scanTip.play();
    },
    playPaySuccess(){
      let successAudio = new Audio(require("@/assets/audio/pay_success.mp3"));
      successAudio.play();
    },
    playPayScanTip(){
      let scanPayAudio = new Audio(require("@/assets/audio/scan_pay_tip.mp3"));
      scanPayAudio.play();
    },
    pluginHandel(state, list){
      state.pluginList = list
    },
    startPlugList(state, list){
      state.startPlugin = list
    },
    changeCodeObj(state, obj){
      console.log(state, obj)
      state.barcode[obj.filed] = obj.list
    }
  },
  actions: {
  },
  modules: {
  }
})
