//新增配置
export const addColumns = [
    {
        prop: 'goodsName',
        label: '货品名称'
    },
    {
        prop: 'goodsNo',
        label: '货号 (点击查看图片)'
    },
    {
        prop: 'colorValue',
        label: '颜色'
    },
    {
        prop: 'sizeInfoList',
        width: '800px',
        boxWidth: '90px' //复选框宽度
    },
    {
        prop: 'total',
        label: '汇总',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    }
]
export const addSizeInfoLabel = [
    {
        prop: 'num', //复选框key//valueInfoList当前行对应key
        label: '总数', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
    {
        prop: 'receiveNum', //复选框key//valueInfoList当前行对应key
        label: '已拣', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: false, //禁用
        colType: 'checkInput', //当前行是否为输入框
        checkInputDisabled: false, //是否禁用验收输入框
        getRowBgc(_row, row, list) {
            let total = 0
            let checkNumberTotal = 0
            list.forEach((item) => {
                let shipmentNumberAll = item.sizeInfoList.reduce((c, R) => c + Number(R.num || 0), 0)
                total += shipmentNumberAll
                // ------
                let checkNumberAll = item.sizeInfoList.reduce((c, R) => c + Number(R.receiveNum || 0), 0)
                checkNumberTotal += checkNumberAll
            })
            // ------------
            let shipmentNumberAll = row.sizeInfoList.reduce((c, R) => c + Number(R.num || 0), 0)
            let checkNumberAll = row.sizeInfoList.reduce((c, R) => c + Number(R.receiveNum || 0), 0)
            let isexcess = row.sizeInfoList.some((item) => {
                //是否出现超拣货，根据总数是否有0出现
                return item.num == 0
            })
            //-------------
            if (total === checkNumberTotal) {
                return 'row-bg-red'
            } else if (shipmentNumberAll === checkNumberAll && !isexcess) {
                //出现超拣货则不显示整货号全蓝
                return 'row-bg-blue'
            } else if (+_row.receiveNum == 0) {
                //数字为0的时候
                return 'row-bg-zero'
            } else if (+_row.receiveNum === +_row.num) {
                return 'row-bg-green'
            } else if (+_row.excessNum != 0) {
                //超拣货的时候
                return 'row-bg-exceed'
            } else {
                return ''
            }
        }
    },
    {
        prop: 'packetCount', //复选框key//valueInfoList当前行对应key
        label: '封包', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
    {
        prop: 'excessNum', //复选框key//valueInfoList当前行对应key
        label: '超拣', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
    {
        prop: 'noReceiveNum', //复选框key//valueInfoList当前行对应key
        label: '未拣', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    }
]
export const footerList = [
    {
        prop: 'checkGetNum',
        label: '总件数',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.num || 0), 0)
                num += all
            })
            return num
        }
    },
    {
        prop: 'receiveNumTotal',
        label: '已拣',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.receiveNum || 0), 0)
                num += all
            })
            return num
        }
    },
    {
        prop: 'excessReceiveNumTotal',
        label: '超拣',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.excessNum || 0), 0)
                num += all
            })
            return num
        }
    },
    {
        prop: 'noReceiveNumTotal',
        label: '未拣',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.noReceiveNum || 0), 0)
                num += all
            })
            return num
        }
    }
]

// 退仓详情配置

export const columnObj = {
    TC: [
        { prop: 'goodsName', label: '货品名称' },
        { prop: 'goodsNo', label: '货号 (点击查看图片)' },
        { prop: 'colorValue', label: '颜色' },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ]
}
export const sizeInfoLabel = {
    TC: [
        {
            prop: 'num', //复选框key//valueInfoList当前行对应key
            label: '退仓数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true //禁用
        },
        {
            prop: 'outNum', //复选框key//valueInfoList当前行对应key
            label: '出货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fef8ef'
        },
        {
            prop: 'outNumDifference', //复选框key//valueInfoList当前行对应key
            label: '出货差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用
            colBgc: '#fef8ef',
            getRowBgc(_row, row, list) {
                //-------------
                if (+_row.receiveNum === +_row.num) {
                    return 'row-bg-red'
                }
            }
        },
        {
            prop: 'inNum', //复选框key//valueInfoList当前行对应key
            label: '入库数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fafef2'
        },
        {
            prop: 'inNumDifference', //复选框key//valueInfoList当前行对应key
            label: '入库差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fafef2'
        }
    ]
}
export const footerObj = {
    TC: [
        {
            prop: 'tcGetNum',
            label: '退仓总件数',
            unit: '',
            formula(data) {
                let num = 0
                data.forEach((item) => {
                    let all = item.sizeInfoList.reduce((c, R) => c + Number(R.num || 0), 0)
                    num += all
                })
                return num
            }
        },
        {
            prop: 'chuhdNumTotal',
            label: '出货差异总数',
            unit: '',
            formula(data) {
                let num = 0
                data.forEach((item) => {
                    let chAll = item.sizeInfoList.reduce((c, R) => c + Number(R.outNumDifference || 0), 0)
                    num += chAll
                })
                return num
            }
        },
        {
            prop: 'rukudNumTotal',
            label: '入库差异总数',
            unit: '',
            formula(data) {
                let num = 0
                data.forEach((item) => {
                    let rkAll = item.sizeInfoList.reduce((c, R) => c + Number(R.inNumDifference || 0), 0)
                    num += rkAll
                })
                return num
            }
        }
    ]
}


// 包裹信息
export const packagecolumn = [
    {
        prop: 'goodsCode',
        label: '简码',
    },
    {
        prop: 'goodsNo',
        label: '货号',
    }, {
        prop: 'goodsName',
        label: '货品名称',
    }, {
        prop: 'colorValue',
        label: '颜色',
    },
    {
        prop: 'sizeInfoList',
        //复选框宽度
        boxWidth: '30px' //复选框宽度
    },
    {
        prop: 'total',
        label: '数量',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    },
]
export const packageSizeInfoLabels = [
    {
        prop: 'num', //复选框key//valueInfoList当前行对应key
        label: '', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
]
