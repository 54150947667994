<template>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :show-close="false" append-to-body @close="close">
        <section slot="title" class="dialog-top" :close-on-click-modal="false" :close-on-press-escape="false">
            <p style="font-weight: bold">包裹明细/{{ detailList.packageSn }}</p>
            <div>
                <i class="el-icon-close" @click="close"></i>
            </div>
        </section>
        <section v-loading="loading">
            <section class="row space-between" style="margin-top: -20px">
                <div class="row">
                    <div class="row align-center">
                        <div style="width: 150px">货号/条码/简码</div>
                        <el-input
                            v-model="inputGood"
                            placeholder="输入后按回车搜索"
                            @keyup.enter.native="UserSelect"
                            clearable
                        ></el-input>
                    </div>
                    <div class="row align-center" style="margin-left: 30px" v-if="tab == 'EPC'">
                        <div style="width: 50px">EPC</div>
                        <el-input
                            v-model="inputEPC"
                            placeholder="输入后按回车搜索"
                            @keyup.enter.native="UserSelect"
                            clearable
                        ></el-input>
                    </div>
                </div>

                <div>
                    <el-radio-group v-model="tab" @input="tabChange">
                        <el-radio-button label="货品"></el-radio-button>
                        <el-radio-button label="EPC"></el-radio-button>
                    </el-radio-group>
                </div>
            </section>
            <section>
                <div v-show="tab == '货品'">
                    <SizeGroupTable
                        style="margin-top: 10px"
                        ref="sizeGroupTableIns"
                        :dataList="dataList"
                        :columns="packagecolumn"
                        :sizeInfoLabels="packageSizeInfoLabels"
                        :propSizeGroup="sizeGroup"
                        :isEmpty="true"
                        border
                    >
                    </SizeGroupTable>
                </div>
                <div v-if="tab == 'EPC'" style="margin-top: 10px">
                    <grid-manager :option="gridOption"></grid-manager>
                </div>
            </section>
        </section>
    </el-dialog>
</template>
<script>
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import { packageSizeInfoLabels, packagecolumn } from './tableConfig.js'
import {queryMarketAllotPackageEpc} from "@/libs/http/modules/erp";
export default {
    name: 'packageAndEpc',
    props: ['detailList'],
    components: {
        SizeGroupTable
    },
    data() {
        return {
            dialogVisible: false,
            packageSn: '',
            packagecolumn,
            packageSizeInfoLabels,
            newSizeInfoLabels: packageSizeInfoLabels,
            dataList: [],
            inputEPC: '',
            inputGood: '',
            sizeGroup: [],
            tab: '货品',
            loading: false,
            arrayB: [],
            gridOption: {
                gridManagerName: 'packageAndEpc',
                firstLoading: true,
                supportAutoOrder: false,
                supportCheckbox: false,
                lineHeight: '30px',
                useTrFocus: true,
                columnData: [
                    {
                        key: 'goodsNo',
                        text: '货号',
                        width: '120px',
                        merge: 'id'
                    },
                    { key: 'goodsName', text: '货品名称', width: '120px', merge: 'id' },
                    { key: 'colorValue', text: '颜色', width: '120px', merge: 'id' },
                    { key: 'value', text: '尺码', width: '120px', merge: 'id' },
                    { key: 'epc', text: 'EPC' },
                    { key: 'number', text: '数量', width: '120px', merge: 'id' }
                ],
                ajaxData: this.fetch,
                height: '50vh',
                supportAjaxPage: false
            }
        }
    },
    created() {
        this.dialogVisible = true
        this.fetchDetail()
    },
    methods: {
        tabChange() {
            if (this.tab == '货品') {
                this.inputEPC = ''
            }
            this.fetchDetail()
        },
        formatLabels(state = false) {
            this.newSizeInfoLabels = sizeInfoLabels.map((item) => {
                return item
            })
        },
        UserSelect() {
            this.fetchDetail()
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        splitArrays(originalArray) {
            // 第一个数组A
            const arrayA = originalArray.map((item) => {
                const newItem = {
                    colorValue: item.colorValue,
                    goodsCode: item.goodsCode,
                    goodsName: item.goodsName,
                    goodsNo: item.goodsNo,
                    sizeInfoList: item.sizeGroupInfoList.map((sizeItem) => ({
                        barCode: sizeItem.barCode,
                        num: sizeItem.num,
                        sku: sizeItem.sku,
                        skuInfoId: sizeItem.skuInfoId,
                        sort: sizeItem.sort,
                        value: sizeItem.value,
                        valueGroupId: sizeItem.valueGroupId
                    })),
                    valueGroupId: item.valueGroupId
                }
                return newItem
            })

            // 第二个数组B
            const arrayB = originalArray.reduce((acc, item) => {
                item.sizeGroupInfoList.forEach((sizeItem) => {
                    sizeItem.epcInfo.forEach((epcItem) => {
                        const newItem = {
                            colorValue: item.colorValue,
                            goodsCode: item.goodsCode,
                            goodsName: item.goodsName,
                            goodsNo: item.goodsNo,
                            epc: epcItem.epc,
                            id: sizeItem.skuInfoId,
                            value: sizeItem.value,
                            number: sizeItem.epcInfo.length
                        }
                        acc.push(newItem)
                    })
                })
                return acc
            }, [])

            return { arrayA, arrayB }
        },
        fetch() {
            return new Promise((resolve, reject) => {
                const tableData = {
                    data: this.arrayB || [],
                    totals: 0
                }
                resolve(tableData)
            })
        },
        fetchDetail() {
            this.loading = true
            let reqData = {
                id: this.detailList.invoicesLogisticsInfoId
            }
            if (this.inputGood) {
                reqData.conditions = this.inputGood
            }
            if (this.inputEPC) {
                reqData.epc = this.inputEPC
            }
            queryMarketAllotPackageEpc(reqData).then(data=>{
              this.loading = false
              if (data) {
                this.invoicesData = data
                this.sizeGroup = data.sizeGroup
                const { arrayA, arrayB } = this.splitArrays(data.colorGroupInfoList)
                this.arrayB = arrayB
                if (this.tab == 'EPC') {
                  GridManager.refreshGrid(this.gridOption.gridManagerName)
                  GridManager.hideLoading(this.gridOption.gridManagerName) //隐藏加载
                }
                this.$nextTick(() => {
                  this.dataList = arrayA || []
                })
              }
            }).catch(error=>{
              this.loading = false
              this.$message.error(error)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -15px;

    p {
        font-size: 18px;
        color: #333;
        font-weight: bold;
    }

    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }
}
.tabClass {
    width: 100px;
    height: 30px;
    // background: blue;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    border: 1px solid #7bb9fd;
}
</style>
