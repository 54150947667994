//详情配置
export const columnObj = {
    PH: [
        {
            prop: 'goodsName',
            label: '货品名称'
        },
        {
            prop: 'goodsNo',
            label: '货号 (点击查看图片)'
        },
        {
            prop: 'retailPrice',
            label: '零售价'
        },
        // {
        //   prop: "price",
        //   label: "铺货价",
        // },
        // {
        //   prop: "colorValue",
        //   label: "颜色",
        // },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ],
    DB: [
        { prop: 'goodsName', label: '货品名称' },
        { prop: 'goodsNo', label: '货号 (点击查看图片)' },
        { prop: 'colorValue', label: '颜色' },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ],
    DDDB: [
        { prop: 'goodsName', label: '货品名称' },
        { prop: 'goodsNo', label: '货号 (点击查看图片)' },
        { prop: 'colorValue', label: '颜色' },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'remark',
            label: '备注',
            width: '130px'
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ],
    TC: [
        { prop: 'goodsName', label: '货品名称' },
        { prop: 'goodsNo', label: '货号 (点击查看图片)' },
        // { prop: "colorValue", label: "颜色" },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ],
    TG: [
        { prop: 'goodsName', label: '货品名称' },
        { prop: 'goodsNo', label: '货号 (点击查看图片)' },
        { prop: 'retailPrice', label: '零售价' },
        // { prop: "colorValue", label: "颜色" },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ]
}
export const sizeInfoLabel = {
    PH: [
        {
            prop: 'number', //复选框key//valueInfoList当前行对应key
            label: '铺货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true //禁用
        },
        {
            prop: 'shipmentNumber', //复选框key//valueInfoList当前行对应key
            label: '出货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fef8ef'
        },
        {
            prop: 'shipmentDifferenceNumber', //复选框key//valueInfoList当前行对应key
            label: '出货差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fef8ef'
        },
        {
            prop: 'storageNumber', //复选框key//valueInfoList当前行对应key
            label: '入库数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fafef2'
        },
        {
            prop: 'storageDifferenceNumber', //复选框key//valueInfoList当前行对应key
            label: '入库差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fafef2'
        }
    ],
    TC: [
        {
            prop: 'num', //复选框key//valueInfoList当前行对应key
            label: '退仓数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true //禁用
        },
        {
            prop: 'outNum', //复选框key//valueInfoList当前行对应key
            label: '出货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fef8ef'
        },
        {
            prop: 'outNumDifference', //复选框key//valueInfoList当前行对应key
            label: '出货差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用
            colBgc: '#fef8ef',
            getRowBgc(_row, row, list) {
                //-------------
                if (+_row.receiveNum === +_row.num) {
                    return 'row-bg-red'
                }
            }
        },
        {
            prop: 'inNum', //复选框key//valueInfoList当前行对应key
            label: '入库数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fafef2'
        },
        {
            prop: 'inNumDifference', //复选框key//valueInfoList当前行对应key
            label: '入库差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fafef2'
        }
    ],
    TG: [
        {
            prop: 'number', //复选框key//valueInfoList当前行对应key
            label: '退供数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true //禁用
        },
        {
            prop: 'shipmentNumber', //复选框key//valueInfoList当前行对应key
            label: '出货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fef8ef'
        },
        {
            prop: 'shipmentDifferenceNumber', //复选框key//valueInfoList当前行对应key
            label: '出货差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: false, //禁用

            colBgc: '#fef8ef'
        }
        // {
        //   prop: "storageNumber", //复选框key//valueInfoList当前行对应key
        //   label: "入库数量", //复选框名称及行数据对应名称
        //   checkShow: true, //是否出现复选框
        //   value: true, //绑定值
        //   disabled: false, //禁用

        //   colBgc: "#fafef2",
        // },
        // {
        //   prop: "storageDifferenceNumber", //复选框key//valueInfoList当前行对应key
        //   label: "入库差异", //复选框名称及行数据对应名称
        //   checkShow: true, //是否出现复选框
        //   value: true, //绑定值
        //   disabled: false, //禁用

        //   colBgc: "#fafef2",
        // },
    ],
    DB: [
        {
            prop: 'totalNum', //复选框key//valueInfoList当前行对应key
            label: '调拨数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colType: 'span'
        },

        {
            prop: 'outNum', //复选框key//valueInfoList当前行对应key
            label: '出货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: false, //绑定值
            disabled: true, //禁用
            colBgc: '#fef8ef',
            colType: 'span'
        },
        {
            prop: 'outNumDifference', //复选框key//valueInfoList当前行对应key
            label: '出货差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: false, //绑定值
            disabled: true, //禁用
            // colBgc: '#fef8ef',
            colType: 'span',
            colBgc(_row) {
                if (_row.outNumDifference * 1) {
                    return '#f4b4bb'
                }
            }
        },
        {
            prop: 'inNum', //复选框key//valueInfoList当前行对应key
            label: '入库数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: false, //绑定值
            disabled: true, //禁用
            colBgc: '#fafef2',
            colType: 'span'
        },

        {
            prop: 'inNumDifference', //复选框key//valueInfoList当前行对应key
            label: '入库差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: false, //绑定值
            disabled: true, //禁用
            // colBgc: '#fafef2',
            colType: 'span',
            colBgc(_row) {
                if (_row.inNumDifference * 1) {
                    return '#f4b4bb'
                }
            }
        }
    ]
}
export const footerObj = {
    PH: [
        'totalAmount',
        'getNum',
        // "phDistriPriceTotal",
        'sellPriceTotal',
        'chuhdNumTotal',
        'rukudNumTotal'
    ],
    TC: ['totalAmount', 'tcGetNum', 'rukudNumTotal', 'chuhdNumTotal'],
    TG: [
        'totalAmount',
        'tcGetNum',
        'chuhdNumTotal'
        // "rukudNumTotal",
    ],
    addDB: [
        {
            prop: "totalAmount",
            label: "总款数",
            unit: "个",
            formula(data) {
                let sum = 0
                data.forEach((item) => {
                    for (let i of item.sizeInfoList) {
                        if (+i.number > 0) {
                            sum++
                            break
                        }
                    }
                })
                return sum
            },
        },
        {
            prop: 'tcGetNum',
            label: '调拨总件数',
            unit: '',
            formula(data) {
                let num = 0
                data.forEach((item) => {
                    let all = item.sizeInfoList.reduce((c, R) => c + Number(R.number*1 || 0), 0)
                    num += all
                })
                return num
            }
        },  
    ],
    DB: [
        {
            prop: 'totalDBAmount',
            label: '总款数',
            unit: '',
            formula(data) {
                return new Set(data.map((i) => i.goodsNo)).size || 0
            }
        },
        {
            prop: 'DBGetNum',
            label: '调拨总件数',
            unit: '',
            formula(data) {
                let num = 0
                data.forEach((item) => {
                    let phAll = item.sizeInfoList.reduce((c, R) => c + Number(R.totalNum || 0), 0)
                    num += phAll
                })
                return num
            }
        },
        {
            prop: 'chuhdbNumTotal',
            label: '出货差异总数',
            unit: '',
            formula(data) {
                let num = 0
                data.forEach((item) => {
                    let chAll = item.sizeInfoList.reduce((c, R) => c + Number(R.outNumDifference || 0), 0)
                    num += chAll
                })
                return num
            }
        },
        {
            prop: 'rukudbNumTotal',
            label: '入库差异总数',
            unit: '',
            formula(data) {
                let num = 0
                data.forEach((item) => {
                    let rkAll = item.sizeInfoList.reduce((c, R) => c + Number(R.inNumDifference || 0), 0)
                    num += rkAll
                })
                return num
            }
        }
    ]
}
export const actions = {
    // fixedWidth: 60, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
    list: [
        {
            label: '查看',
            event: 'detail',
            displayRule: () => true || false //按钮显隐规则
        }
    ]
}

//新增配置
export const addColumnObj = {
    DDDB: [
        {
            prop: 'goodsName',
            label: '货品名称'
        },
        {
            prop: 'goodsNo',
            label: '货号'
        },
        {
            prop: 'colorValue',
            label: '颜色'
        },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ],
    OTHER: [
        {
            prop: 'goodsName',
            label: '货品名称'
        },
        {
            prop: 'goodsNo',
            label: '货号'
        },
        {
            prop: 'colorValue',
            label: '颜色'
        },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ]
}

export const addSizeInfoLabel = [
    {
        prop: 'usableQuantity', //复选框key//valueInfoList当前行对应key
        label: '可用库存', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
    {
        prop: 'number', //复选框key//valueInfoList当前行对应key
        label: '退货数', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true, //禁用
        colType: 'addInput', //当前行是否为输入框
        getRowBgc(_row) {
            if (+_row.number !== 0) {
                return 'row-bg-green'
            } else {
                return ''
            }
        }
    }
]
export const addActions = {
    fixedWidth: 120,
    list: [
        {
            label: '删除',
            event: 'del',
            displayRule: (row) => true
        }
    ]
}




// 包裹信息
export const packagecolumn = [
    {
        prop: 'goodsCode',
        label: '简码',
    },
    {
        prop: 'goodsNo',
        label: '货号',
    }, {
        prop: 'goodsName',
        label: '货品名称',
    }, {
        prop: 'colorValue',
        label: '颜色',
    },
    {
        prop: 'sizeInfoList',
        //复选框宽度
        boxWidth: '30px' //复选框宽度
    },
    {
        prop: 'total',
        label: '数量',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    },
]
export const packageSizeInfoLabels = [
    {
        prop: 'num', //复选框key//valueInfoList当前行对应key
        label: '', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
]