<template>
  <section>
    <dialogModule
      v-if="popupDialog"
      top="6"
      title="结算"
      :width="dialogWidth + 'px'"
      :dialogVisible="popupDialog"
      :closeOnPressEscape="false"
      @close="
        () => {
          closeDialog()
        }
      ">
      <span
        slot="close"
        class="el-icon-close close-icon"
        @click="
          () => {
            closeDialog()
          }
        "></span>
      <p slot="change" style="overflow: hidden; white-space: nowrap; width: 560px; text-overflow: ellipsis">
        <span v-if="orderNature && orderNature.guiderList && orderNature.guiderList[0]" style="margin-left: 20px"
          >营业员：
          <span v-for="(guid, index) in orderNature.guiderList" :key="guid.id"
            >{{ guid.realName }}<span v-if="index < orderNature.guiderList.length - 1">、</span></span
          >
        </span>
        <span
          v-if="vipData"
          style="color: #067cf2; border-bottom: 1px solid #067cf2; cursor: pointer; margin-left: 20px"
          >会员：{{ vipData.nickName }}/{{ vipData.phone }}</span
        >
      </p>
      <section slot="content" class="row" style="height: 830px; padding: 15px">
        <section class="pay-goods" v-if="activityInfo && activityInfo.length">
          <template v-if="activeList && activeList.length">
            <div v-for="(item, index) in activeList" :key="index">
              <div class="pay-goods-item">
                <div class="row aling-center space-between pay-goods-top">
                  <div class="row aling-cener">
                    <span class="goods-type">{{ item.typeDesc }}</span>
                    <p class="goods-title" :title="item.activityTitle">{{ item.activityName }}</p>
                  </div>
                  <div @click="item.isGoods = !item.isGoods">
                    {{ item.isGoods ? '收起' : '展开' }}
                    <span class="el-icon-arrow-down"></span>
                  </div>
                </div>
                <div class="goods-body" v-if="item.isGoods">
                  <div class="row align-center space-between">
                    <div class="row align-center" style="flex: 1; overflow: hidden">
                      <div class="goods-ingo" v-for="(goods, index) in item.list" :key="index">
                        <div>
                          <el-image
                            v-if="goods.imageList"
                            :src="goods.imageList[0]"
                            :preview-src-list="goods.imageList"
                            class="goods-img"></el-image>
                          <div v-else class="goods-img-null row align-center space-center">暂无图片</div>
                          <div class="goods-num">{{ goods.number }}件</div>
                          <div class="row align-center space-center is-gift gift" v-if="goods.isGift">赠品</div>
                          <div
                            class="row align-center space-center is-gift"
                            :class="[goods.isAddBuy ? 'add-buy' : 'activity']"
                            v-else>
                            {{ goods.isAddBuy ? '加购' : '促销' }}
                          </div>
                        </div>
                        <p>{{ goods.goodsNo }}</p>
                      </div>
                    </div>
                    <div
                      class="goods-ingo"
                      style="width: 60px; cursor: pointer"
                      @click="
                        activeModule = true
                        actGoodsList = item
                      ">
                      共{{ item.list.length }}款
                      <span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <el-empty v-else style="height: 90%" description="未查询到促销信息"></el-empty>
        </section>
        <section class="pay-type">
          <template v-if="payList.length">
            <div style="height: 50px; background: #fff; text-align: center">
              <img src="../assets/images/paytype-tips.png" style="height: 55px; margin-top: -10px" />
            </div>
            <div class="row align-center pay-list" :class="[payList.length > 5 ? 'flex-wrap' : 'space-around']">
              <div
                v-for="pay in payList"
                :key="pay.id"
                :style="{marginLeft: payList.length > 5 ? '10px' : ''}"
                @click.left="choosePayItemSingle(pay)"
                @contextmenu.prevent="choosePayItem(pay)"
                :class="['pay-item', 'column', 'align-center', 'space-center', pay.isChecked ? 'choose-pay' : '']">
                <img :src="pay.icon" />
                <p>{{ pay.name }}</p>
                <div class="pay-select row" v-if="pay.isChecked">
                  <span class="iconfont icon-gou"></span>
                </div>
              </div>
            </div>
            <div class="choose-list" :style="{height: payList.length > 5 ? '310px' : '440px'}">
              <div class="choose-item" v-for="choose in chooseList" :key="choose.id">
                <div class="row space-between align-center">
                  <div class="choose-title">
                    <span
                      style="
                        background: linear-gradient(66deg, #00cb0e, #24ea65);
                        color: #fff;
                        font-size: 14px;
                        padding: 2px 10px;
                        border-radius: 0 20px 20px 0;
                      "
                      >已选择</span
                    >
                    <span style="color: #f8475f">【</span>
                    <span>{{ choose.name }}</span>
                    <span style="color: #f8475f">】</span>
                  </div>
                  <div class="pay-split" v-if="choose.isChecked && choose.isSplit === 'Y' && !choose.splitItem">
                    <a href="javascript:;" @click="splictPayItem(choose)">拆分</a>
                  </div>
                  <div v-if="vipData && choose.type === '3'" style="font-size: 16px; color: #f8475f">
                    可用余额：{{ vipData.accountBalance || '0.00' }}
                  </div>
                  <p class="row align-center choose-body">
                    <span>￥</span>
                    <input
                      type="text"
                      placeholder="0.00"
                      v-model="choose.price"
                      @keyup.enter="$event.target.blur()"
                      @blur="computedMoney(choose)" />
                    <span>元</span>
                  </p>
                </div>
                <div v-if="choose.name.includes('现金')" class="return-tool">
                  <div class="row space-center align-center tool-title">找零小工具</div>
                  <div class="row align-center flex-wrap text-center tool-money">
                    <div class="tool-row" style="border-bottom: none">实收金额</div>
                    <div class="tool-row" style="border-bottom: none; border-left: none">找零金额</div>
                    <div class="tool-line tool-input row align-center space-center">
                      <span>￥</span>
                      <input
                        type="text"
                        :disabled="!choose.price"
                        placeholder="0.00"
                        @keyup.enter="$event.target.blur()"
                        @input="cashMoney = cashMoney.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/, '$1')"
                        @blur="calcCash"
                        v-model="cashMoney" />
                      <span>元</span>
                    </div>
                    <div class="tool-line" style="border-left: none">￥{{ returnMoney || '0.00' }}</div>
                  </div>
                </div>
                <div class="split-card" v-if="choose.splitItem && choose.isChecked">
                  <div class="row space-center align-center split-title">拆分明细</div>
                  <div class="row align-center text-center title-row">
                    <div class="cell" style="border-bottom: none">收款次数</div>
                    <div class="cell" style="border-bottom: none; border-left: none">支付金额</div>
                    <div class="cell" style="border-bottom: none; border-left: none">操作</div>
                  </div>
                  <div
                    class="row align-center text-center body-row"
                    v-for="(pay, index) in choose.splitList"
                    :key="index">
                    <div class="cell" style="border-bottom: none">第{{ index - 0 + 1 }}次收款</div>
                    <div class="cell row align-center cell-input" style="border-bottom: none; border-left: none">
                      <p class="row align-center">
                        <span>￥</span>
                        <input
                          type="text"
                          placeholder="0.00"
                          v-model="pay.value"
                          @keyup.enter="$event.target.blur()"
                          @blur="calucItemMoney(choose, pay)" />
                        <span>元</span>
                      </p>
                    </div>
                    <div class="delete-split cell" @click="splicePay(choose, index)">
                      <span>删除</span>
                    </div>
                  </div>
                  <div class="row align-center text-center bott-row">
                    <div class="cell"></div>
                    <div class="cell" style="border-left: none">
                      可拆分金额：<span style="color: #f8475f; font-size: 18px; font-family: 'font-Regular'"
                        >￥{{ choose.surplusPrice }}</span
                      >
                    </div>
                    <div class="cell row align-center" style="border-left: none">
                      <p @click="pushPayItem(choose)">新增</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <el-empty description="当前店铺未关联支付方式" v-else style="height: 450px"></el-empty>
          <div class="pay-total">
            <div class="price-list row align-center space-around">
              <div class="row align-center money-item">
                <div>本单金额：</div>
                <span>￥{{ orderMoney.totalPrice || orderMoney.receivablePrice }}</span>
              </div>
              <div class="row align-center money-item" v-if="orderMoney.receivedAmount">
                <div>已经支付：</div>
                <span>￥{{ orderMoney.receivedAmount }}</span>
              </div>
              <div class="row align-center money-item">
                <div>{{ againPay && againPay.againPayId ? '还需收款' : '实收金额' }}：</div>
                <span style="color: #f8475f; font-size: 24px">￥{{ orderMoney.receivablePrice }}</span>
              </div>
            </div>
            <el-button :loading="loading" :disabled="loading" class="sure-money" @click="payBefore">
              确认收款
            </el-button>
          </div>
        </section>
      </section>
    </dialogModule>
    <ActiveGoodsList v-if="activeModule" :activeObject="actGoodsList" @close="activeModule = false"></ActiveGoodsList>
    <DoReceipts v-if="DoReceiptsModal" :webViewUrl="webViewUrl" @close="closeDialog"></DoReceipts>
    <ScanPayWaiting
      v-if="scanWating"
      :ordingId="againPay && againPay.againPayId ? againPay.againPayId : ordingId"
      :payData="payData"
      :isAgain="isAgain"
      @print="queryPrintData"
      @close="
        () => {
          scanWating = false
          closeDialog()
        }
      ">
    </ScanPayWaiting>
  </section>
</template>

<script>
import request from '@/libs/http/index'
import dialogModule from '@/components/dialogModule.vue'
import DoReceipts from '@/views/salesRecord/components/DoReceipts.vue'
import ActiveGoodsList from '@/views/deptCollectMoney/components/ActiveGoodsList.vue'
import ScanPayWaiting from './ScanPayWaiting.vue'
import {
  submitStoreInvoice,
  queryPaymentList,
  generatePayInfo,
  queryGoodsActinfo,
  posOrderPayInfo
} from '@/libs/http/modules/posOrder.js'
import { ipc } from '@/utils/ipcRenderer'
import { ipcApiRoute, specialIpcRoute } from '@/api/main'
export default {
  name: 'PayDialog',
  props: {
    orderNature: {
      type: Object,
      default: () => {}
    },
    vipData: {
      type: Object,
      default: () => {}
    },
    orderMoney: {
      type: Object,
      default: () => {}
    },
    activityInfo: {
      type: Array,
      default: () => []
    },
    againPay: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    dialogModule,
    DoReceipts,
    ScanPayWaiting,
    ActiveGoodsList
  },
  data() {
    return {
      popupDialog: true,
      payList: [],
      ordingId: '',
      invoicesNo: '',
      activeList: [],
      actList: [],
      cashMoney: '',
      returnMoney: '0.00',
      loading: false,
      DoReceiptsModal: false,
      scanWating: false,
      actGoodsList: [],
      activeModule: false,
      activityTitle: '',
      payData: [],
      chooseList: [],
      isRefresh: false,
      webViewUrl: ''
    }
  },
  created() {
    if (this.activityInfo && this.activityInfo.length) {
      this.fetcActGoods()
    }
    this.queryPayList()
  },
  computed: {
    dialogWidth() {
      let width = 680
      if (this.activityInfo && this.activityInfo.length) {
        width = this.$amount.add(width, 470, 0)
      }
      return width
    },
    isAgain() {
      return this.againPay && this.againPay.againPayId
    }
  },
  methods: {
    async closeDialog() {
      if (!this.againPay && this.isRefresh) {
        let obj = {
          winId: 'salesRecord',
          windowName: '销售记录'
        }
        const newWcId = await ipc.invoke(ipcApiRoute.getWCid, obj.windowName)
        if (newWcId) {
          ipc.sendTo(newWcId, specialIpcRoute.window1ToWindow2, '刷新页面')
        }
        this.$emit('close')
      } else if (this.againPay && this.againPay.againPayId && this.isRefresh) {
        this.$emit('close', true)
      } else {
        this.$emit('close')
      }
      this.popupDialog = false
      this.DoReceiptsModal = false
    },
    fetcActGoods() {
      this.actList = []
      queryGoodsActinfo({
        goodInfos: this.orderNature.tableList,
        checkActivityInfos: this.activityInfo
      })
        .then(data => {
          if (data && data.length) {
            data.forEach(item => {
              this.$set(item, 'list', [])
              this.$set(item, 'isGoods', true)
              if (item.goodInfos && item.goodInfos.length) {
                item.list = item.list.concat(item.goodInfos)
              }
              if (item.addGoodInfos && item.addGoodInfos.length) {
                item.addGoodInfos.forEach(gave => {
                  this.$set(gave, 'isAddBuy', true)
                })
                item.list = item.list.concat(item.addGoodInfos)
              }
              if (item.giftGoodInfos && item.giftGoodInfos.length) {
                item.giftGoodInfos.forEach(gift => {
                  this.$set(gift, 'isGift', true)
                })
                item.list = item.list.concat(item.giftGoodInfos)
              }
            })
            this.activeList = data
          }
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    async queryPayList() {
      this.chooseList = []
      this.payList = await queryPaymentList({ isHasMember: this.vipData ? 'Y' : 'N' })
      this.payList.forEach(item => {
        if (item.isSplit === 'Y') {
          this.$set(item, 'splitItem', false)
          this.$set(item, 'splitList', [{ value: '' }])
          this.$set(item, 'splitPrice', '')
          this.$set(item, 'surplusPrice', '')
        }
        if (item.isDefault === 'Y') {
          this.$set(item, 'isChecked', true)
          this.$set(item, 'price', this.orderMoney.receivablePrice)
          this.chooseList.push(item)
        } else {
          this.$set(item, 'isChecked', false)
        }
      })
    },
    choosePayItemSingle(item) {
      this.chooseList = []
      this.returnMoney = '0.00'
      this.cashMoney = ''
      this.payList.forEach(choose => {
        if (choose.id !== item.id) {
          choose.isChecked = false
        } else {
          choose.isChecked = true
          this.$set(item, 'price', '')
          this.chooseList.push(item)
          if (
            choose.type === '3' &&
            this.vipData &&
            this.$amount.lessThan(this.vipData.accountBalance || 0, this.orderMoney.receivablePrice)
          ) {
            this.chooseList[0].price = ''
          } else {
            this.chooseList[0].price = (this.orderMoney.receivablePrice * 1).toFixed(2)
          }
        }
      })
      this.chooseList.forEach(choose => {
        if (choose.isSplit === 'Y') {
          choose.splitItem = false
          choose.splitList = [{ value: '' }]
          choose.splitPrice = ''
          choose.surplusPrice = ''
        }
      })
    },
    choosePayItem(item) {
      item.isChecked = !item.isChecked
      this.returnMoney = '0.00'
      this.cashMoney = ''
      let index = this.chooseList.findIndex(choose => {
        return choose.id === item.id
      })
      if (item.isChecked && index === -1) {
        this.$set(item, 'price', '')
        this.chooseList.push(item)
      }
      if (!item.isChecked && index !== -1) {
        this.chooseList.splice(index, 1)
      }
      this.chooseList.forEach(choose => {
        if (choose.isSplit === 'Y') {
          choose.splitItem = false
          choose.splitList = [{ value: '' }]
          choose.splitPrice = ''
          choose.surplusPrice = ''
        }
      })
      if (this.chooseList.length === 1) {
        if (
          this.chooseList[0].type === '3' &&
          this.vipData &&
          this.$amount.lessThan(this.vipData.accountBalance || 0, this.orderMoney.receivablePrice)
        ) {
          this.chooseList[0].price = ''
        } else {
          this.chooseList[0].price = (this.orderMoney.receivablePrice * 1).toFixed(2)
        }
      } else {
        this.chooseList.map(item => {
          item.price = ''
        })
      }
    },
    calcCash() {
      this.chooseList.forEach(item => {
        if (item.name.includes('现金') && this.cashMoney) {
          this.returnMoney = this.$amount.subtract(this.cashMoney, item.price, 2)
        }
      })
      this.cashMoney = (+this.cashMoney).toFixed(2)
    },
    computedMoney(val) {
      if (!val.price) return
      if (+val.price > +this.orderMoney.receivablePrice || isNaN(val.price)) {
        val.price = ''
        return this.$message({ type: 'error', message: '请填写正确的金额', duration: 2000, showClose: true })
      }
      if (
        val.type === '3' &&
        this.vipData &&
        this.$amount.greaterThan(val.price || 0, this.vipData.accountBalance || 0)
      ) {
        val.price = ''
        return this.$message({
          type: 'error',
          message: `余额不足，当前会员余额只有${this.vipData.accountBalance || 0}元`,
          duration: 2000,
          showClose: true
        })
      }
      let res = this.chooseList.every(item => {
        return item.price
      })
      if (res) {
        this.chooseList.forEach(item => {
          if (item.id !== val.id) {
            item.price = ''
          }
        })
      }
      if (val.price) {
        val.price = (+val.price).toFixed(2)
      }
      if (val.isSplit === 'Y') {
        val.splitList = [{ value: '' }]
        val.splitPrice = val.price
        val.surplusPrice = val.price
      }
      const money = this.chooseList.reduce((c, R) => c + +R.price, 0)
      let arrList = this.chooseList.filter(item => {
        return item.id !== val.id && !item.price
      })
      if (arrList.length === 1) {
        this.chooseList.map(item => {
          if (item.id === arrList[0].id) {
            item.price = this.$amount.subtract(this.orderMoney.receivablePrice, money, 2)
            if (item.isSplit === 'Y') {
              item.splitList = [{ value: '' }]
              item.splitPrice = item.price
              item.surplusPrice = item.price
            }
          }
        })
      } else {
        this.chooseList.map(item => {
          if (item.isSplit === 'Y') {
            item.splitList = [{ value: '' }]
            item.splitPrice = item.price || '0.00'
            item.surplusPrice = item.price || '0.00'
          }
        })
      }
      if (this.cashMoney) {
        this.cashMoney = ''
        this.returnMoney = ''
      }
    },
    splictPayItem(item) {
      if (!item.price) {
        return this.$message({ type: 'error', message: '请先填写金额', duration: 2000, showClose: true })
      }
      item.splitPrice = item.price
      item.surplusPrice = item.price
      item.splitItem = true
    },
    pushPayItem(item) {
      item.splitList.push({
        value: ''
      })
    },
    splicePay(item, index) {
      let money = 0
      item.splitList.splice(index, 1)
      item.splitList.forEach(pay => {
        money = this.$amount.add(money, pay.value || 0, 2)
      })
      item.surplusPrice = this.$amount.subtract(item.splitPrice, money, 2)
      if (!item.splitList.length) {
        item.splitItem = false
      }
    },
    calucItemMoney(item, pay) {
      if (pay.value && (isNaN(pay.value) || +pay.value <= 0)) {
        pay.value = ''
        this.$message({ type: 'error', message: '请填写大于0的有效金额', duration: 2000, showClose: true })
      }
      let money = 0
      item.splitList.forEach(item => {
        money = this.$amount.add(money, item.value || 0, 2)
      })
      item.surplusPrice = this.$amount.subtract(item.splitPrice, money, 2)
      if (pay.value) {
        pay.value = (+pay.value).toFixed(2)
      }
    },

    payBefore() {
      if (!this.chooseList.length) {
        return this.$message({
          type: 'error',
          message: '请选择至少一种收款方式',
          duration: 2000,
          showClose: true
        })
      }
      let subPayList = []
      let isOnline = false
      let checkSplit = false
      this.chooseList.forEach(item => {
        if (item.splitItem && item.splitList.length) {
          let itemTotal = 0
          item.splitList.forEach(val => {
            itemTotal = +itemTotal + +val.value
            if (val.value) {
              subPayList.push({
                id: item.id,
                proceedsPrice: val.value
              })
            }
          })
          if (!this.$amount.equals(item.splitPrice, itemTotal)) {
            checkSplit = true
          }
        } else {
          subPayList.push({
            id: item.id,
            proceedsPrice: item.price
          })
        }
        if (item.type === '1') {
          isOnline = true
        }
      })
      let money = this.chooseList.reduce((c, R) => c + Number(R.price), 0).toFixed(2)
      if (checkSplit || !this.$amount.equals(this.orderMoney.receivablePrice, money)) {
        return this.$message({ type: 'error', message: '请检查金额是否正确', duration: 2000, showClose: true })
      }
      let isMinus = this.chooseList.some(item => {
        return +item.price < 0
      })
      if (isMinus)
        return this.$message({
          type: 'error',
          message: '请检查金额是否正确，请取消勾选金额小于0元的收款方式',
          duration: 4000,
          showClose: true
        })
      this.loading = true
      if (this.againPay && this.againPay.againPayId) {
        this.sureGetMoney(subPayList, isOnline, 'isAgain')
      } else {
        this.submitOrding(subPayList, isOnline)
      }
    },
    submitOrding(subPayList, isOnline) {
      const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      let shopArr = this.orderNature.guiderList.map(item => {
        return item.id
      })
      submitStoreInvoice({
        type: '2',
        source: '2',
        terminalId: cashierItem ? cashierItem.id : '',
        storeInfoList: this.orderNature.tableList,
        orderTypeId: this.orderNature.orderTypeId,
        brokerage: this.orderNature.salesTypeId,
        crmVipId: this.vipData ? this.vipData.id : '',
        checkActivityInfos: this.activityInfo,
        isAlterpriceReview: this.isUpdatePrice ? 'Y' : 'N',
        shoppingGuideIds: shopArr,
        ...this.orderMoney,
        proceedsInfoList: subPayList
      })
        .then(data => {
          this.ordingId = data.id
          this.invoicesNo = data.innerSn
          this.$emit('sendState')
          if (isOnline) {
            this.payData = data['generateOrderPaymentInfoResp']['onLinePaymentInfos'] || []
            this.popupDialog = false
            this.scanWating = true
            this.loading = false
          } else {
            this.queryPrintData()
          }
          this.isRefresh = true
        })
        .catch(err => {
          this.loading = false
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
          this.$confirm("请到销售记录查看订单状态，若没有该订单请重新打单。", "提示", {
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false
          }).then(() => {
            this.$emit('sendState')
            this.closeDialog()
          }).catch((err) => {})
        })
    },
    sureGetMoney(paylist, line, again) {
      const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      let ordingId = this.againPay && this.againPay.againPayId ? this.againPay.againPayId : this.ordingId
      posOrderPayInfo({ id: ordingId }).then(res => {
        generatePayInfo({
          id: ordingId,
          proceedsInfoList: paylist,
          activityInfo: this.actList,
          memberId: this.vipData ? this.vipData.id : '',
          isAgainPayment: again === 'isAgain' ? 'Y' : 'N',
          terminalId: cashierItem ? cashierItem.id : ''
        })
          .then(data => {
            this.payData = data['onLinePaymentInfos'] || []
            if (line) {
              this.popupDialog = false
              this.scanWating = true
              this.loading = false
            } else {
              this.queryPrintData()
            }
            this.isRefresh = true
          })
          .catch(err => {
            this.loading = false
            this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
          })
      })
    },
    // 打印小票
    queryPrintData() {
      // this.DoReceiptsModal = true
      // if (type === 'scanPay') {
      //   this.$store.commit('playPayScanTip')
      // }
      this.$store.commit('playPayScanTip')
      this.$message({ type: 'success', message: '买单成功', duration: 2000, showClose: true })
      let printer = this.$cache.local.get('printerModel')
      let printCount = this.$cache.local.get('retailReceiptCount')
      let printCode = this.$cache.local.get('retailReceiptCode')
      let params = {
        sn: this.againPay && this.againPay.againinvoicesNo ? this.againPay.againinvoicesNo : this.invoicesNo
      }
      this.webViewUrl = `${
        request.printPreviewRoot
      }?printerName=${printer}&templateCode=${printCode}&printCount=0&data=${encodeURI(JSON.stringify(params))}`
      let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${printer}&templateCode=${printCode}&`
      const eggSrc =
        eggRootUrl + `printCount=${printCount}&data=${encodeURI(JSON.stringify(params))}&sn=${params['sn']}`
      ipc.invoke(ipcApiRoute.print, { contentUrl: eggSrc, sn: params['sn'] }).then(r => {})
      this.closeDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.close-icon {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}

.pay-goods {
  width: 460px;
  background: #f3f3f3;
  margin-right: 10px;
  border-radius: 4px;
  overflow: auto;
  font-family: 'font-Regular';

  .pay-goods-item {
    background: #fff;
    margin: 10px;
    border-radius: 4px;

    .pay-goods-top {
      border-bottom: 1px solid #eee;
      font-size: 14px;
      padding: 10px;

      .goods-type {
        border-radius: 4px;
        background: #ff9000;
        color: #fff;
        font-size: 12px;
        margin-right: 10px;
        padding: 0 6px;
      }

      .goods-title {
        width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .goods-body {
      padding: 10px 0;

      .goods-ingo {
        margin-left: 20px;
        width: 110px;

        > div {
          position: relative;
          width: 100px;
          height: 90px;
          overflow: hidden;

          .goods-img {
            border: 1px solid #f5f5f5;
            width: 100px;
            height: 90px;
            float: left;
            border-radius: 4px;
          }

          .goods-num {
            position: absolute;
            left: 5px;
            bottom: 1px;
            border-radius: 4px 4px 0px 0px;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            font-size: 12px;
            padding: 3px 7px;
          }

          .goods-img-null {
            width: 100px;
            height: 90px;
            border-radius: 4px;
            color: #999;
            background: #f5f5f5;
          }

          .is-gift {
            position: absolute;
            top: 5px;
            right: -15px;
            color: #fff;
            width: 60px;
            height: 20px;
            transform: rotate(45deg);
            font-size: 12px;
          }
        }

        p {
          color: #999;
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
  }
}

.pay-type {
  background: #f3f3f3;
  border-radius: 4px;
  width: 650px;

  .pay-list {
    padding-top: 10px;
    border-radius: 4px;
    .pay-item {
      position: relative;
      width: 118px;
      height: 118px;
      border: 1px solid #e2e9f9;
      background: #ffffff;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 10px;
      img {
        width: 40px;
        margin-bottom: 10px;
      }

      p {
        font-family: 'font-Regular';
      }

      .pay-select {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
        border-radius: 0 0 2px 0;
        background: linear-gradient(-45deg, #067cf2 50%, transparent 50%);

        .icon-gou {
          margin-top: 20px;
          margin-left: 18px;
          color: #fff;
        }
      }
    }

    .choose-pay {
      border: 1px solid #067cf2;
      background: #f1f5ff;
    }
  }

  .choose-list {
    margin: 10px;
    border-radius: 4px;
    overflow: auto;

    .choose-item {
      background: #fff;
      margin-bottom: 10px;
      padding: 10px 10px 10px 0;

      .choose-title {
        font-family: 'font-Regular';
        color: #333;
        font-size: 16px;
      }

      .pay-split {
        border-bottom: 1px solid #067cf2;
        font-size: 16px;

        a {
          color: #067cf2;
        }
      }

      .choose-body {
        font-family: 'font-Regular';
        font-size: 18px;
        color: #333;

        input {
          border: 1px solid #ccc;
          background: #eef2fb;
          line-height: 36px;
          border-radius: 4px;
          text-align: center;
          font-size: 18px;
          font-family: 'font-Regular';
          margin: 0 5px;
          outline: none;
        }
      }

      .return-tool {
        margin-top: 20px;
        padding-left: 10px;

        .tool-title {
          background: #f9f9f9;
          height: 30px;
          border: 1px solid #e7e8ea;
          border-bottom: none;
        }

        .tool-money {
          .tool-row {
            width: 50%;
            color: #436481;
            line-height: 30px;
            border: 1px solid #e7e8ea;
            font-size: 14px;
          }

          .tool-line {
            width: 50%;
            line-height: 50px;
            border: 1px solid #e7e8ea;
            font-size: 18px;
          }

          .tool-input {
            font-size: 14px;
            font-family: 'font-Regular';

            input {
              font-family: 'font-Regular';
              background: #eef2fb;
              border: 1px solid #ccc;
              outline: none;
              width: 100px;
              text-align: center;
              line-height: 28px;
              border-radius: 4px;
              margin: 0 5px;
              font-size: 18px;
            }
          }
        }
      }

      .split-card {
        margin-top: 20px;
        padding-left: 10px;

        .split-title {
          background: #f9f9f9;
          height: 30px;
          border: 1px solid #e7e8ea;
          border-bottom: none;
        }

        .title-row {
          height: 33px;
          width: 100%;

          div {
            width: calc(100%);
            border: 1px solid #e7e8ea;
            height: 33px;
            line-height: 33px;
            color: #436481;
          }
        }

        .body-row {
          height: 46px;
          width: 100%;

          div {
            width: calc(100%);
            border: 1px solid #e7e8ea;
            height: 46px;
            line-height: 46px;
          }

          .cell-input {
            height: 46px;
            line-height: 46px;

            p {
              margin: auto;
            }

            input {
              font-family: 'font-Regular';
              outline: none;
              width: 100px;
              text-align: center;
              line-height: 28px;
              border-radius: 4px;
              margin: 0 5px;
              font-size: 18px;
              background: #eef2fb;
              border: 1px solid #ccc;
            }
          }

          .delete-split {
            border-left: none;
            border-bottom: none;

            span {
              color: #067cf2;
              border-bottom: 1px solid #067cf2;
              cursor: pointer;
            }
          }
        }

        .bott-row {
          height: 46px;
          width: 100%;

          div {
            width: calc(100%);
            border: 1px solid #e7e8ea;
            height: 46px;
            line-height: 46px;
          }

          p {
            background: #067cf2;
            color: #fff;
            line-height: 30px;
            width: 100px;
            border-radius: 4px;
            margin: auto;
            cursor: pointer;
          }
        }
      }
    }
  }

  .pay-total {
    background: #fff;
    margin: 10px;
    padding: 20px;

    .price-list {
      .money-item {
        margin-bottom: 20px;

        div {
          color: #999;
          font-size: 16px;
          font-family: 'font-Light';
        }

        span {
          font-size: 20px;
          color: #333;
          font-family: 'font-Regular';
        }
      }
    }

    .sure-money {
      width: 100%;
      background: #067cf2;
      color: #fff;
      font-size: 20px;
      line-height: 50px;
      padding: 0;
      font-family: 'font-Regular';
    }
  }
}
.activity {
  background: linear-gradient(-90deg, #ec4620, #f08702, #f7d400) !important;
}
.gift {
  background: linear-gradient(0deg, #208aec, #02d7f0, #00c6f7) !important;
}
.add-buy {
  background: linear-gradient(0deg, rgb(116, 47, 169), rgb(101, 41, 147), rgb(72, 30, 105)) !important;
}
</style>
