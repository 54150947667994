<template>
  <el-dialog
    top="10vh"
    width="1400px"
    append-to-body
    v-loading="loading"
    :title="'包裹明细/' + packageInfo['packageSn']"
    :visible.sync="dialogVisible"
    @close="closeHandel">
    <div class="detail-box">
      <div class="search-row">
        <span>货号/条码/简码</span>
        <el-input clearable size="small" placeholder="输入后按回车搜索" v-model="queryText" style="width: 300px;" @keyup.enter.native="fetchInfo"></el-input>
      </div>
      <SizeGroupTable
          border
          :columns="addColumns"
          :dataList="newDataList"
          :sizeInfoLabels="addSizeInfoLabel">
      </SizeGroupTable>
    </div>
  </el-dialog>
</template>

<script>
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import {returnPdaPackageInfo, pdaPackageDetailAllot} from "@/libs/http/modules/erp";
import {addColumns, addSizeInfoLabel} from './outConfig.js'
export default {
  props: {
    invoiceType: {
      type: String,
      default: () => ''
    },
    packageInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    SizeGroupTable
  },
  data(){
    return{
      queryText: '',
      dialogVisible: true,

      addColumns,
      addSizeInfoLabel,
      loading: false,
      newDataList: []
    }
  },
  created() {
    this.fetchInfo()
  },
  methods: {
    closeHandel(){
      this.dialogVisible = false
      this.$emit('close')
    },
    fetchInfo(){
      console.log('abcdefg')
      this.loading = true
      if(this.invoiceType === 'TCD'){
        returnPdaPackageInfo({
          id: this.packageInfo.id,
          searchText: this.queryText
        }).then(res=>{
          console.log(res)
          this.newDataList = res.goodsInfoList || []
          this.loading = false
        }).catch(error=>{
          this.loading = false
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
      }
      if(this.invoiceType === 'DCD'){
        pdaPackageDetailAllot({
          id: this.packageInfo.id,
          searchText: this.queryText
        }).then(res=>{
          console.log(res)
          this.newDataList = res.goodsInfoList || []
          this.loading = false
        }).catch(error=>{
          this.loading = false
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-box{
  height: 70vh;
  overflow: auto;
  margin-top: -20px;
  .search-row{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span{
      margin-right: 10px;
    }
  }
}
</style>