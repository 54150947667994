<template>
    <div>
        <el-dialog
            :title="type == 1 ? '完成验收' : '完成拣货并提交'"
            :visible.sync="dialogVisible"
            width="600px"
            @close="close"
            append-to-body
        >
            <div>
                <div style="width: 100%; text-align: center ; font-size: 16px; color: #333; text-align: center">
                  当前数据存在差异，确定继续提交？
                </div>
                <div
                    style="
                        width: 100%;
                        text-align: center;
                        /*margin-left: 200px;*/
                        height: 20px;
                        margin-top: 30px;
                        font-size: 16px;
                        color: #333;
                        text-align: center;
                    "
                >
                    <span>总数量：{{ modalParams.distriNumTotal }}</span>
                    <span style="margin: 0 30px"
                        >已{{ type == 1 ? '验收' : '拣货' }}：{{ modalParams.receiveNumTotal }}</span
                    >
                    <span style="color: #f8475f;margin: 0 30px " v-if="modalParams.excessReceiveNumTotal > 0"
                        >超{{ type == 1 ? '验收' : '拣货' }}：{{ Math.abs(modalParams.excessReceiveNumTotal) }}</span
                    >
                    <span style="color: #f8475f; margin: 0 30px" 
                        >未{{ type == 1 ? '验收' : '拣货' }}：{{ modalParams.noReceiveNumTotal }}</span
                    >
                </div>

                <el-form ref="form" size="small" :model="form" :rules="rules" v-if='documentType==3'>
                    <el-form-item label="差异拣货的原因"  prop="difReason">
                        <el-input v-model="form.difReason" placeholder="请输入差异拣货的原因"></el-input>
                    </el-form-item>
                </el-form>

<!--                <div v-if='documentType==3'>-->
<!--                <div>-->
<!--                    差异拣货的原因：-->
<!--                    <div><el-input v-model="difReason" placeholder="请输入差异拣货的原因"></el-input></div>-->
<!--                </div>-->
<!--            </div>-->
            </div>

            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ['modalParams', 'type','documentType'],

    data() {
        return {
            dialogVisible: false,
            form:{
                difReason:""
            },

            rules: {
                difReason: [{ required: true, message: '请输入差异拣货的原因', trigger: 'blur' }]
            },
        }
    },
    created() {
        this.dialogVisible = true
    },
    methods: {
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        submit() {
            if(!this.form.difReason&&this.documentType==3) {
                this.$message({
                    type: 'error',
                    message: '请填写差异拣货原因',
                    duration: 2000,
                    showClose: true
                })
                return
            }
            this.dialogVisible = false
            this.$emit('close', this.form.difReason,1)
        }
    }
}
</script>
<style scoped></style>
