<template>
  <section class="container">
    <nav class="search-top">
      <el-form inline :model="form" size="small">
        <el-form-item label="客订单号">
          <el-input clearable placeholder="请输入单号" v-model="form.guestBookingOrderNo"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select style="width: 120px" clearable placeholder="请选择类型" v-model="form.applyType">
            <el-option label="客订到客" value="0"></el-option>
            <el-option label="客订到店" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货号">
          <el-input style="width: 180px" clearable placeholder="请输入" v-model="form.goodsNo"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width: 140px" clearable placeholder="请选择状态" v-model="form.state">
            <el-option label="已关闭" value="-1"></el-option>
            <el-option label="匹配中" value="0"></el-option>
            <el-option label="待发货" value="1"></el-option>
            <el-option label="已发货" value="2"></el-option>
            <el-option label="已收货" value="3"></el-option>
            <el-option label="全国都没货" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请店铺">
          <el-cascader
            style="width: 180px"
            placeholder="请输入或选择部门"
            v-model="form.deptIdList"
            clearable
            collapse-tags
            filterable
            :options="deptList"
            :show-all-levels="false"
            :props="{
              multiple: true,
              checkStrictly: false,
              emitPath: false,
              value: 'id',
              label: 'name',
              children: 'children'
            }">
          </el-cascader>
        </el-form-item>
        <el-form-item label="快递单号">
          <el-input clearable placeholder="请输入" style="width: 160px" v-model="form.logisticsNo"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 50px">
          <el-button @click="resetForm">重 置</el-button>
          <el-button type="primary" @click="search">查 询</el-button>
        </el-form-item>
      </el-form>
    </nav>
    <main class="main row">
      <aside class="main-table" v-loading="searchLoading">
        <grid-manager :option="gridOption"></grid-manager>
      </aside>
      <section v-if="!currentRow" class="main-detail">
        <NoDataState textDesc="请选择一条客订记录查看详情" marginTop="200"></NoDataState>
      </section>
      <section
        v-else
        class="main-detail"
        v-loading="fetchLoading"
        element-loading-text="请稍后，全力加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0)">
        <div class="row align-center text-center oprate-btn">
          <span
            v-if="state === '0'"
            style="background: #43d268"
            @click="
              actionType = '0'
              actionDialog = true
            "
            >同意</span
          >
          <span
            v-if="state === '0'"
            style="background: #f8475f"
            @click="
              actionType = '1'
              actionDialog = true
            "
            >拒绝</span
          >
          <span v-if="state === '1'" style="background: #0b83f3" @click="enteringOpen">录入单号</span>
          <span v-if="state === '2'" @click="printMin" style="background: #ff9000">打印小票</span>
        </div>
        <div class="row strack-goods">
          <div class="logistics">
            <div class="row align-center space-between logistics-top">
              <div class="row align-center">
                <div class="row align-center space-center"><img src="@/assets/images/jd.png" /></div>
                <template v-if="applyMatchSkuLog">
                  <span>{{ applyMatchSkuLog.logisticsName }}</span>
                  <span class="invoiceNo">{{ applyMatchSkuLog.expressNo }}</span>
                  <span class="state" v-if="applyMatchSkuLog && applyMatchSkuLog.logisticsStateDesc">{{ applyMatchSkuLog.logisticsStateDesc }}</span>
                </template>
                <span v-else style="color: #999">暂无快递信息</span>
              </div>
              <span v-if="applyMatchSkuLog && applyMatchSkuLog.expressNo" @click="strakDialog = true"
                >物流轨迹查询</span
              >
            </div>
            <div class="logistics-desc">
              <div>
                <div class="row align-center track-one" v-if="applyMatchLog">
                  <div v-if="receiverInfo" class="doted" style="background: #0b83f3"></div>
                  <div class="row" style="flex: 1">
                    <div
                      class="row align-center space-center square"
                      style="background: rgb(235, 247, 255); color: #0b83f3">
                      发出方
                    </div>
                    <div class="column space-between" style="flex: 1">
                      <p style="font-size: 16px; color: #000; font-weight: bold; color: #0b83f3">
                        {{ applyMatchLog.deptName }}
                      </p>
                      <p style="color: #000">
                        {{ applyMatchLog.principalPhone }}
                        <span
                          style="
                            padding: 0 4px;
                            border-radius: 2px;
                            margin-left: 10px;
                            font-size: 12px;
                            background: #0b83f3;
                            color: #fff;
                          "
                          v-if="matchType == '1'">
                          云仓
                        </span>
                        <span
                          style="
                            padding: 0 4px;
                            border-radius: 2px;
                            margin-left: 10px;
                            font-size: 12px;
                            background: #0b83f3;
                            color: #fff;
                          "
                          v-if="applyMatchLog.territoryType != 'null' && applyMatchLog.territoryType">
                          {{
                            applyMatchLog.territoryType === '0'
                              ? '同城'
                              : applyMatchLog.territoryType === '1'
                              ? '同省'
                              : '经纬度'
                          }}</span
                        >
                        <span
                          v-if="applyMatchLog.province != 'null' && applyMatchLog.province"
                          style="
                            padding: 0 4px;
                            border-radius: 2px;
                            border: 1px solid rgb(67, 209, 104);
                            margin-left: 10px;
                            font-size: 12px;
                            color: rgb(67, 209, 104);
                          ">
                          {{ applyMatchLog.province }}</span
                        >
                        <span
                          v-if="applyMatchLog.city != 'null' && applyMatchLog.city"
                          style="
                            padding: 0 4px;
                            border-radius: 2px;
                            border: 1px solid rgb(255, 144, 0);
                            margin-left: 10px;
                            font-size: 12px;
                            color: rgb(255, 144, 0);
                          ">
                          {{ applyMatchLog.city }}</span
                        >
                        <span
                          v-if="applyMatchLog.dist != 'null' && applyMatchLog.dist && matchType == '0'"
                          style="
                            padding: 0 4px;
                            border-radius: 2px;
                            border: 1px solid rgb(188, 115, 210);
                            margin-left: 10px;
                            font-size: 12px;
                            color: rgb(188, 115, 210);
                          ">
                          {{ applyMatchLog.dist }}km
                        </span>
                        <span
                          v-if="applyMatchLog.area != 'null' && applyMatchLog.area && matchType == '1'"
                          style="
                            padding: 0 4px;
                            border-radius: 2px;
                            border: 1px solid rgb(188, 115, 210);
                            margin-left: 10px;
                            font-size: 12px;
                            color: rgb(188, 115, 210);
                          ">
                          {{ applyMatchLog.area }}km
                        </span>
                      </p>
                      <div style="color: #999; font-size: 14px">
                        {{ applyMatchLog.province }}{{ applyMatchLog.city }}{{ applyMatchLog.area
                        }}{{ applyMatchLog.address }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="receiverInfo"
                    style="width: 1px; height: 100px; background: #eee; position: absolute; top: 45px; left: 4px"></div>
                </div>
                <div class="row align-center track-one" v-if="receiverInfo">
                  <div v-if="applyMatchLog || consignStore" class="doted" style="background: #f8475f"></div>
                  <div class="row" style="flex: 1">
                    <div
                      class="row align-center space-center square"
                      style="background: rgb(255, 237, 235); color: #f8475f">
                      接收方
                    </div>
                    <div class="column space-between" style="flex: 1">
                      <p style="font-size: 16px; color: #000; font-weight: bold; color: #f8475f">
                        {{ receiverInfo.name }}
                      </p>
                      <span style="color: #000">{{ receiverInfo.phone }}</span>
                      <div style="color: #999; font-size: 14px">
                        {{ receiverInfo.province }}{{ receiverInfo.city }}{{ receiverInfo.area
                        }}{{ receiverInfo.address }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="consignStore"
                    style="width: 1px; height: 100px; background: #eee; position: absolute; top: 45px; left: 4px"></div>
                </div>
                <!--                发出店铺-->
                <div class="row align-center track-one" v-if="consignStore">
                  <div class="doted" style="background: #f2f2f2"></div>
                  <div class="row" style="flex: 1">
                    <div class="row align-center space-center square" style="background: #f7f7f7; color: #000">
                      申请店铺
                    </div>
                    <div class="column space-between" style="flex: 1">
                      <p style="font-size: 16px; color: #000; font-weight: bold; color: #000">
                        {{ consignStore.principal }}
                      </p>
                      <span style="color: #000">{{ consignStore.principalPhone }}</span>
                      <div style="color: #999; font-size: 14px">
                        {{ consignStore.province }}{{ consignStore.city }}{{ consignStore.area
                        }}{{ consignStore.address }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="goods">
            <div class="row align-center space-between goods-top">
              <span>货品明细</span>
            </div>
            <div class="goods-list">
              <div class="row align-center flex-wrap space-between">
                <div class="row goods-item" v-for="(item, idx) in goodsList" :key="idx">
                  <div class="row goods-img">
                    <el-image
                      v-if="item.images && item.images.length"
                      :src="item.images[0]"
                      :preview-src-list="item.images"></el-image>
                    <div v-else class="row align-center text-center">暂无图片</div>
                  </div>
                  <div>
                    <div class="goods-name">
                      <span>{{ item.skuTitle }}</span
                      >{{ item.goodsNo }}
                    </div>
                    <div class="goods-attr">{{ item.skuColor }} | {{ item.skuSize }}</div>
                    <span class="goods-num">x{{ item.skuNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row track-row">
            <i></i>
            <span>客订轨迹</span>
          </div>
          <grid-manager :option="gridTrack"></grid-manager>
        </div>
      </section>
    </main>
    <StrackDialog
      v-if="strakDialog"
      :applyMatchSkuLog="applyMatchSkuLog"
      :applyMatchLog="applyMatchLog"
      :receiverInfo="receiverInfo"
      @close="strakDialog = false">
    </StrackDialog>
    <Action v-if="actionDialog" :currentObj="currentRow" :type="actionType" @close="closeDialog"></Action>
    <DoReceipts v-if="DoReceiptsModal" :webViewUrl="webViewUrl" @close="DoReceiptsModal = false"></DoReceipts>
    <Logistics v-if="enteringDialog" :currentObj="currentRow" @close="closeLogis"></Logistics>

    <el-dialog
      title="请确认货品及数量无误再进行发货！"
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose">
      <div class="dialog-box">
        <div class="row goods-item" v-for="(item, idx) in goodsList" :key="idx">
          <div class="row goods-img">
            <el-image
              v-if="item.images && item.images.length"
              :src="item.images[0]"
              :preview-src-list="item.images"></el-image>
            <div v-else class="row align-center text-center">暂无图片</div>
          </div>
          <div>
            <div class="goods-name">
              <span>{{ item.skuTitle }}</span
              >{{ item.goodsNo }}
            </div>
            <div class="goods-attr">{{ item.skuColor }} | {{ item.skuSize }}</div>
            <span class="goods-num">x{{ item.skuNumber }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureEntering">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import DoReceipts from '@/views/salesRecord/components/DoReceipts.vue'
import StrackDialog from '@/views/kedingModule/components/StrackDialog'
import Logistics from '@/views/kedingModule/components/logistics'
import NoDataState from '@/components/noDataState'
import Action from '@/views/kedingModule/components/Action'
import { querySaleDepts } from '@/libs/http/api'
import { otherStoreRecord, queryVisitorDetail } from '@/libs/http/modules/customer'
import request from '@/libs/http'
import { ipc } from '@/utils/ipcRenderer'
import { ipcApiRoute } from '@/api/main'
export default {
  name: 'outStore',
  components: {
    Logistics,
    NoDataState,
    Action,
    StrackDialog,
    DoReceipts
  },
  data() {
    return {
      searchLoading: false,
      page: '',
      dialogVisible: false,
      matchType: '',
      deptList: [],
      form: {
        guestBookingOrderNo: '',
        applyType: '',
        goodsNo: '',
        state: '',
        deptIdList: [],
        logisticsNo: ''
      },
      gridOption: {
        gridManagerName: 'otherStore',
        firstLoading: true,
        height: '75vh',
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        useTrFocus: true,
        supportConfig: true,
        disableAutoLoading: true,
        supportMenu: false,
        columnData: [
          {
            key: 'guestBookingOrderNo',
            text: '客订单号',
            align: 'center',
            width: '170px'
          },
          {
            key: 'applyDeptName',
            text: '收货店铺',
            align: 'center',
            width: '100px'
          },
          {
            key: 'applyState',
            text: '状态',
            align: 'center',
            width: '70px',
            template: () => {
              return `
                <div>
                   <span style="color: #F8475F;" v-if="row.applyState === '-1'">已关闭</span>
                   <span style="color: #067CF2;" v-if="row.applyState === '0'">匹配中</span>
                   <span style="color: #FD953B;" v-if="row.applyState === '1'">待发货</span>
                   <span style="color: #6e30a4;" v-if="row.applyState === '2'">已发货</span>
                   <span style="color: #43D268;" v-if="row.applyState === '3'">已收货</span>
                   <span style="color: #F8475F;" v-if="row.applyState === '4'">全国都没货</span>
                </div>
              `
            }
          },
          {
            key: 'applyType',
            text: '类型',
            align: 'center',
            width: '80px',
            template: () => {
              return `
                <div>
                   <span style="color: #FD953B;" v-if="row.applyType === '0'">客订到客</span>
                   <span v-if="row.applyType === '1'">客订到店</span>
                </div>
              `
            }
          },
          {
            key: 'description',
            text: '备注',
            align: 'center'
          }
        ],
        rowClick: this.rowClick,
        ajaxData: this.fetch,
        ajaxError: this.errorBack,
        supportAjaxPage: true,
        dataKey: 'items',
        totalsKey: 'totalItem',
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--选中信息-->
                    <div class="toolbar-info checked-info"></div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`
      },
      gridTrack: {
        gridManagerName: 'otherStrack',
        firstLoading: false,
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportConfig: true,
        disableAutoLoading: true,
        supportMenu: false,
        columnData: [
          {
            key: 'deptName',
            text: '匹配单位',
            align: 'center'
          },
          {
            key: 'principalPhone',
            text: '联系方式',
            align: 'center'
          },
          {
            key: 'processState',
            text: '状态',
            align: 'center',
            template: () => {
              return `
              <span v-if="row.processState == 0" style="color:#1890ff;">未处理</span>
                <span v-if="row.processState == 1" style="color:#43d168;">已同意</span>
                <span v-if="row.processState == 2" style="color:#f8475f;">已拒绝</span>
              `
            }
          },
          {
            key: 'territoryType',
            text: '匹配规则',
            align: 'center',
            template: cell => {
              if (cell === '0') {
                return `同城`
              } else if (cell === '1') {
                return `同省`
              } else {
                return `经纬度`
              }
            }
          },
          {
            key: 'province',
            text: '省份',
            align: 'center'
          },
          {
            key: 'city',
            text: '城市',
            align: 'center'
          },
          {
            key: 'dist',
            text: '距离(km)',
            align: 'center'
          },
          {
            key: 'description',
            text: '备注',
            align: 'center'
          },
          {
            key: 'updateTime',
            text: '回复时间',
            align: 'center'
          }
        ],
        ajaxData: this.fetchTrack
      },
      enteringDialog: false,
      currentRow: null,
      goodsList: [],
      bookingMatchLogs: [],
      applyMatchLog: null,
      applyMatchSkuLog: null,
      receiverInfo: null,
      consignStore: null,
      actionDialog: false,
      invoicesNo: '',
      state: null,
      actionType: null,
      strakDialog: false,
      fetchLoading: false,
      DoReceiptsModal: false,
      webViewUrl: null
    }
  },
  created() {
    this.fetchSaleList()
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    sureEntering() {
      this.enteringDialog = true
      this.dialogVisible = false
    },
    enteringOpen() {
      let num = 0
      this.goodsList.forEach(item => {
        num += item.skuNumber
      })
      if (num > 1) {
        this.dialogVisible = true
      } else {
        this.enteringDialog = true
      }
    },
    fetchSaleList() {
      let reqData = {}
      querySaleDepts(reqData)
        .then(data => {
          this.deptList = data.depts.areas
          for (let i = 0; i < data.depts.areas.length; i++) {
            data.depts.areas[i].children = data.depts.areas[i].groups
            for (let j = 0; j < data.depts.areas[i].children.length; j++) {
              data.depts.areas[i].children[j].children = data.depts.areas[i].children[j].stores
            }
          }
          this.deptList = data.depts.areas
        })
        .catch(err => {
          this.loading = false
          this.$message({
            type: 'error',
            message: err,
            duration: 2000,
            showClose: true
          })
        })
    },
    resetForm() {
      for (let key in this.form) {
        if (this.form[key]) {
          this.form[key] = ''
        }
      }
    },
    search() {
      this.$parent.fetchTotal()
      this.page = 1
      GridManager.refreshGrid(this.gridOption.gridManagerName)
    },
    fetch(params) {
      let reqData = {
        page: this.page ? this.page : params.pageData.cPage,
        pageSize: params.pageData.pSize,
        guestBookingOrderNo: this.form.guestBookingOrderNo,
        applyType: this.form.applyType,
        goodsNo: this.form.goodsNo,
        state: this.form.state,
        deptIdList: this.form.deptIdList || [],
        logisticsNo: this.form.logisticsNo
      }
      this.$nextTick(() => {
        this.page = ''
      }, 200)

      //为了加载效果
      this.listLoading(reqData)

      return otherStoreRecord(reqData)
    },
    listLoading(reqData) {
      this.searchLoading = true
      otherStoreRecord(reqData)
        .then(data => {
          if (data) {
            this.searchLoading = false
          }
        })
        .catch(error => {
          this.searchLoading = false
        })
    },
    errorBack(error) {
      this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
    },
    rowClick(row) {
      if (this.currentRow && this.currentRow.id === row.id) return
      this.currentRow = row
      this.matchType = row.matchType
      this.fetchDetail()
    },
    fetchDetail() {
      this.fetchLoading = true
      queryVisitorDetail({
        applyId: this.currentRow.id
      })
        .then(data => {
          this.goodsList = data.applySkuDetails || []
          this.applyMatchLog = data.applyMatchLog || null
          this.applyMatchSkuLog = data.applyMatchSkuLog || null
          this.receiverInfo = data.receiverInfo || null
          this.consignStore = data.bookingApplyDeptInfo || null
          this.invoicesNo = data.invoicesNo || ''
          this.state = data.state || ''
          if (data.bookingMatchLogs) {
            this.bookingMatchLogs = data.bookingMatchLogs
            GridManager.refreshGrid(this.gridTrack.gridManagerName)
          } else {
            this.bookingMatchLogs = []
            GridManager.refreshGrid(this.gridTrack.gridManagerName)
          }
          this.fetchLoading = false
        })
        .catch(error => {
          this.fetchLoading = false
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
    },
    fetchTrack() {
      return new Promise((resolve, reject) => {
        const tableData = {
          data: this.bookingMatchLogs
        }
        resolve(tableData)
      })
    },
    printMin() {
      this.$confirm('是否要打印小票', '提示')
        .then(res => {
          this.print()
        })
        .catch(() => {})
    },
    print() {
      this.DoReceiptsModal = true
      let printer = this.$cache.local.get('printerModel')
      let printCode = this.$cache.local.get('kdReceiptCode')
      let printCount = this.$cache.local.get('kdReceiptCount')
      let params = {
        sn: this.invoicesNo
      }
      this.webViewUrl = `${
        request.printPreviewRoot
      }?printerName=${printer}&templateCode=${printCode}&printCount=0&data=${encodeURI(JSON.stringify(params))}`
      let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${printer}&templateCode=${printCode}&`
      const eggSrc =
        eggRootUrl + `printCount=${printCount}&data=${encodeURI(JSON.stringify(params))}&sn=${params['sn']}`
      ipc.invoke(ipcApiRoute.print, { contentUrl: eggSrc, sn: params['sn'] }).then(r => {})
    },
    closeDialog(type) {
      this.actionDialog = false
      if (type === 'refresh') {
        this.search()
        if (this.actionType === '1') {
          this.currentRow = null
        } else {
          this.fetchDetail()
        }
      }
    },
    closeLogis(type) {
      this.dialogVisible = false
      this.enteringDialog = false
      if (type === 'refresh') {
        this.search()
        this.fetchDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0 10px;

  .search-top {
    background: #fff;
    padding: 15px 10px;
  }

  .main {
    margin-top: 10px;
    height: 80vh;

    .main-table {
      width: 600px;
      background: #fff;
      padding: 10px;
      height: 80vh;
    }

    .main-detail {
      width: 62%;
      background: #fff;
      padding: 10px;
      height: 80vh;
      flex: 1;
      margin-left: 10px;
      font-size: 14px;

      .oprate-btn {
        span {
          width: 90px;
          height: 30px;
          line-height: 30px;
          color: #fff;
          margin-right: 10px;
          border-radius: 2px;
          cursor: pointer;
        }
      }

      .strack-goods {
        margin-top: 10px;

        .logistics {
          border: 1px solid #dedede;
          height: 400px;
          width: 45%;

          .logistics-top {
            background: #f5f7fa;
            height: 40px;
            padding: 0 10px;
            border-bottom: 1px solid #dedede;

            div div {
              background: #ddd;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              margin-right: 10px;
              font-size: 0;

              img {
                width: 15px;
              }
            }

            .invoiceNo {
              margin: 0 10px;
            }

            .state {
              border: 1px solid orange;
              padding: 1px 5px;
              color: rgb(255, 144, 0);
              font-size: 12px;
              border-radius: 2px;
            }

            > span {
              padding: 4px 10px;
              background: #0b83f3;
              color: #fff;
              border-radius: 2px;
              cursor: pointer;
            }
          }

          .logistics-desc {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 330px;
            > div {
              margin: 10px;
            }

            .track-one {
              position: relative;
              margin-top: 25px;

              .doted {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 30px;
              }

              .square {
                width: 80px;
                height: 80px;
                margin-right: 10px;
                font-weight: bold;
                text-align: center;
              }
            }
          }
        }

        .goods {
          border: 1px solid #dedede;
          height: 400px;
          flex: 1;
          margin-left: 10px;

          .goods-top {
            background: #f5f7fa;
            height: 40px;
            padding: 0 10px;
            border-bottom: 1px solid #dedede;

            span {
              font-size: 16px;
            }
          }

          .goods-list {
            height: 255px;
            overflow: auto;

            > div {
              margin: 10px;
            }

            .goods-item {
              background: rgb(249, 250, 252);
              border-radius: 4px;
              width: calc((100% - 10px) / 2);
              margin-bottom: 10px;
              padding: 10px;

              .goods-img {
                width: 80px;
                height: 80px;
                margin-right: 10px;
                background: #fff;
                overflow: hidden;
                div {
                  color: #999;
                  margin: auto;
                }
              }

              .goods-name {
                span {
                  font-size: 16px;
                  font-weight: bold;
                  margin-right: 10px;
                }
              }

              .goods-attr {
                margin: 10px 0;
              }

              .goods-num {
                color: orange;
                font-weight: bold;
              }
            }
          }
        }
      }
      .track-row {
        margin: 10px 0;

        i {
          float: left;
          width: 4px;
          height: 16px;
          background: #067cf2;
          margin-left: -10px;
          border-radius: 2px;
          margin-right: 6px;
          margin-top: 4px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }
}

.dialog-box {
  max-height: 460px;
  overflow-y: auto;
  .goods-item {
    background: #f9fafc;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;

    .goods-img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      background: #fff;
      overflow: hidden;
      div {
        color: #999;
        margin: auto;
      }
    }

    .goods-name {
      span {
        font-size: 16px;
        font-weight: bold;
        margin-right: 10px;
      }
    }

    .goods-attr {
      margin: 10px 0;
    }

    .goods-num {
      color: orange;
      font-weight: bold;
    }
  }
}
</style>

<style>
.search-top .el-form-item {
  margin-bottom: 0;
}
</style>
