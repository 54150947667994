<template>
    <!-- 调拨单单-出 -->
    <section class="dept-retreat">
        <div class="grid-box">
            <div class="right-box">
                <div>
                    <basic-button
                        :disabled="displayRule('print')"
                        @click="handleCommand('print')"
                        type="primary"
                        :loading="loading"
                        icon-class="print"
                    >
                        打印单据
                    </basic-button>
                    <basic-button
                        :disabled="displayRule('startGet')"
                        @click="handleCommand('startGet')"
                        type="primary"
                        :loading="loading"
                        icon-class="picking"
                    >
                        开始拣货
                    </basic-button>
                    <basic-button
                        :disabled="displayRule('checkGet')"
                        @click="handleCommand('checkGet')"
                        type="primary"
                        :loading="loading"
                        icon-class="expressage"
                    >
                        录入快递信息
                    </basic-button>
                    <basic-button
                        type="primary"
                        icon-class="submit"
                        :disabled="displayRule('submit')"
                        @click="handleCommand('submit')"
                    >
                        提交
                    </basic-button>
                    <basic-button
                        type="primary"
                        icon-class="Dispatched"
                        :disabled="displayRule('copy')"
                        @click="handleCommand('copy')"
                    >
                        复制单据
                    </basic-button>

                    <basic-button
                        type="primary"
                        icon-class="recover"
                        :disabled="displayRule('repick')"
                        @click="handleCommand('repick')"
                    >
                      回退拣货
                    </basic-button>
                    <basic-button
                        type="primary"
                        icon-class="recover"
                        :disabled="displayRule('reorder')"
                        @click="handleCommand('reorder')"
                    >
                        重新做单
                    </basic-button>
                </div>
                <basic-button
                    v-if="addFlag == 'Y'"
                    @click="addNew()"
                    type="primary"
                    :loading="loading"
                    icon-class="add"
                >
                    新增店铺调拨单
                </basic-button>
            </div>
            <grid-manager :option="gridOption"></grid-manager>
        </div>
        <SearchFormItem
            :options="options"
            @searchHandel="sureSharch"
            class="search-form-r"
            ref="SearchFormItemInsTwo"
            @getState="getFormState"
        ></SearchFormItem>
        <entryCourierInfo v-if="entryCourierDialog" invoiceType="DCD" :modalParams="modalParams" @close="entryCourier"></entryCourierInfo>
        <InvoicePrint
            v-if="printDialog"
            :printTemplate="deptAllotCode"
            :defaultCopies="deptAllotCount"
            :modalParams="modalParams"
            @close="printDialog = false"
        >
        </InvoicePrint>

        <ShopDetailsStoreOut
            v-if="detailDrawer"
            :modalParams="modalParams"
            @close="detailDrawer = false"
            type="2"
        ></ShopDetailsStoreOut>
      <AllotPickUp
          v-if="checkAcceptDrawer"
          :modalParams="modalParams"
          @close="closeDrawer"
          @refetch="sureSharch"
      ></AllotPickUp>
        <multiWindow
            @close="closeDialog"
            ref="multiWindowRef"
            :moduleList="moduleList"
            :hasAdd="hasAdd"
            @hasAddChange="hasAddChange"
        ></multiWindow>
    </section>
</template>

<script>
import svgIcon from '@/components/SvgIcon/index'
import DialogModuleStore from '../components/dialogModulestroe.vue'
import multiWindow from '@/components/multiWindow/index.vue'
import ReturnDialogModule from '../components/returnDialogModule.vue'
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import InvoicePrint from '@/components/printInvoices/index'
import {
  erp2MarketAllotBatchInvalidMarketAllot,
  marketAllotQueryMarketAllotList,
  erp2MarketAllotBatchSubmitMarketAllot,
  erp2MarketAllotClientTransferOrder,
  erp2OperatingDuplicateInvoices,
  erp2OperatingRestartPick,
  erp2OperatingResetInvoices, pdaRebackByRfid
} from '@/libs/http/modules/erp.js'
import entryCourierInfo from '../components/entryCourierInfo.vue'
import ShopDetailsStoreOut from '../components/ShopDetailsStoreOut.vue'
import AllotPickUp from "./components/AllotPickUp.vue"
import { columnData, options } from './transFerConfig'
import { dialogConfig } from './dialogConfig'
export default {
    name: 'transferSlipOut',
    components: {
        DialogModuleStore,
        ReturnDialogModule,
        SearchFormItem,
        entryCourierInfo,
        ShopDetailsStoreOut,
        AllotPickUp,
        multiWindow,
        svgIcon,
        InvoicePrint
    },
    data() {
        return {
            gridOption: {
                width: '80vw - 345px',
                height: '85.5vh',
                gridManagerName: 'transferSlipOut',
                firstLoading: true,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useTrFocus: true,
                lineHeight: '20px',
                supportPrint: false,
                checkboxConfig: {
                    fixed: 'left',
                    useRowCheck: true
                },
                columnData,
                ajaxData: this.fetch,
				ajaxError: this.ajaxError,
                supportAjaxPage: true,
                queryKey: {},
                cellDblClick: this.rowdbClick,
                checkedAfter: this.checkedAfter,
                dataKey: 'items',
                totalsKey: 'totalItem'
            },
            options,
            entryCourierDialog: false,
            printDialog: false,
            detailDrawer: false,
            checkAcceptDrawer: false,
            loading: false,
            isType: true,
            StoreDialogVisiable: false,
            returnDialogVisiable: false,
            dialogVisiable: false,
            modalParams: null,
            checkedList: [],
            moduleList: [],
            hasAdd: 'N',
            addFlag: '',
            dialogConfig,
            deptAllotCode: '',
            deptAllotCount: ''
        }
    },
    created() {
        this.addDialoging()
        this.deptAllotCode = this.$cache.local.get('deptAllotCode')
        this.deptAllotCount = this.$cache.local.get('deptAllotCount')
    },
    methods: {
        checkedAfter(a) {
            this.checkedList = a
        },
        rowReturn(data) {
            return +data === 0 ? '' : data
        },
        rowdbClick(row, rowIndex, colIndex) {
            if (colIndex == 1 || colIndex == 0) return
            if (row.state == 1) {
                this.addNew(row)
            } else {
                this.showDetails(row)
            }
        },
        entryCourier(e) {
            this.entryCourierDialog = false
            if (e) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        showDetails(row) {
            this.detailDrawer = true
            this.modalParams = row
        },
        displayRule(type) {
            let rule = true
            let list = this.checkedList
            if (list && list.length) {
                switch (type) {
                    case 'print':
                        rule = !(list.length > 0 && list.every((i) => ![0, 1].includes(+i.state)))
                        break
                    case 'startGet':
                        rule = !(list.length === 1 && list.every((i) => [4, 5].includes(+i.state)))
                        break
                    case 'checkGet':
                        rule = !(list.length === 1 && list.every((i) => [8].includes(+i.state)))
                        break
                    case 'submit':
                        rule = !list.every((i) => ['1'].includes(i.state))
                        break
                    case 'copy':
                        rule = !(list.length === 1 && list.every((i) => ![].includes(i.state)))
                        break
                    case 'void':
                        rule = !list.every((i) => ['1', '2', '4', '5', '6'].includes(i.state))
                        break
                    case 'repick':
                        if (list.every((i) => ['5', '6', '7', '8'].includes(i.state)) && list.length == 1) {
                            rule = !rule
                        }
                        break
                    case 'reorder':
                        if (list.every((i) => i.channel == '50' && ['8'].includes(i.state)) && list.length == 1 && this.addFlag === 'Y') {
                            rule = !rule
                        }
                    default:
                        break
                }
            } else {
                rule = true
            }
            return rule
        },
        getFormState(e) {
            GridManager.resetLayout(this.gridOption.gridManagerName, e ? '80vw - 345px' : '80vw - 40px', '83vh')
        },
        sureSharch(e) {
            this.queryKey = e
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        fetch(params) {
            GridManager.setCheckedData(this.gridOption.gridManagerName, [])
            this.checkedList = []
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                outMarketIds: [this.$cache.local.get('marketingUnitId')],
                source: '2'
            }
            for (let key in this.queryKey) {
                if (!['createTime', 'updateTime', 'invoicesNoList'].includes(key)) {
                    reqData[key] = this.queryKey[key]
                }

                if (this.queryKey.createTime && this.queryKey.createTime.length) {
                    let [srart, end] = this.queryKey.createTime
                    reqData['createStartTime'] = `${srart} 00:00:00`
                    reqData['createEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.updateTime && this.queryKey.updateTime.length) {
                    let [srart, end] = this.queryKey.updateTime
                    reqData['updateStartTime'] = `${srart} 00:00:00`
                    reqData['updateEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.invoicesNoList) {
                    reqData['invoicesNoList'] = this.queryKey.invoicesNoList.trim().split(/[ ]+/)
                }
            }
            this.$parent.quantiting()
            return marketAllotQueryMarketAllotList(reqData)
        },
		ajaxError(e){
			this.$message({type: 'error', message: e, duration: 2000, showClose: true})
		},
        closeDrawer(refresh) {
            this.checkAcceptDrawer = false
            if (refresh) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        refresh(e) {
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        handleCommand(e) {
            let row = this.checkedList[0]
            switch (e) {
                case 'print':
                    this.printDialog = true
                    this.modalParams = this.checkedList
                    break
                case 'checkGet':
                    this.entryCourierDialog = true
                    this.modalParams = { ...row, invoicesId: row.id, typeCode: row.typeCode || '100011' }
                    break
                case 'startGet':
                    if(row['rfidAllotOutSwitch'] === 'Y'){
                      return this.$message({ type: 'error', message: '请用PDA拣货', duration: 2000, showClose: true })
                    }
                    this.checkAcceptDrawer = true
                    this.modalParams = row
                    break
                case 'submit':
                    this.submitList()
                    break
                case 'copy':
                    this.copy()
                    break
                case 'repick':
                    this.repickHandel()
                    break
                case 'reorder':
                    this.reorderHader()
            }
        },
        addDialoging() {
            erp2MarketAllotClientTransferOrder().then((res) => {
                this.addFlag = res
            })
        },
        // 重新做单
        reorderHader() {
            const list = GridManager.getCheckedData(this.gridOption.gridManagerName)
            this.$confirm('此单是否重新做单', '提示')
                .then(() => {
                    erp2OperatingResetInvoices({
                        typeCode: list[0].typeCode,
                        idList: [list[0].id]
                    })
                        .then((data) => {
                            this.$message.success('操作成功')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        //作废
        submitList() {
            this.$confirm('确定后单据将变为打包完成状态，确定提交？', '提示')
                .then(() => {
                    erp2MarketAllotBatchSubmitMarketAllot({
                        ids: this.checkedList.map((i) => i.id) || []
                    })
                        .then((data) => {
                            this.$messageDialog(data, '标记为打包完成')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        delvoidList() {
            this.$confirm('是否将单据操作为作废', '提示')
                .then(() => {
                    erp2MarketAllotBatchInvalidMarketAllot({
                        ids: this.checkedList.map((i) => i.id) || []
                    })
                        .then((data) => {
                            this.$messageDialog(data, '作废')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        copy() {
            this.$confirm('是否复制此单据', '提示')
                .then(() => {
                    erp2OperatingDuplicateInvoices({
                        type: 'ErpMarketAllot',
                        id: this.checkedList[0].id
                    })
                        .then((data) => {
                            // this.$messageDialog(data)
                            // this.$message.success('复制成功','复制')
                            this.$messageDialog(data, '复制')
                            GridManager.refreshGrid(this.gridOption.gridManagerName)
                        })
                        .catch((error) => {
                            this.$message.error(error)
                        })
                })
                .catch(() => {})
        },
        repickHandel() {
            const list = GridManager.getCheckedData(this.gridOption.gridManagerName)
            this.$confirm('此单确定回到“拣货中”状态继续拣货?', '提示').then(() => {
                pdaRebackByRfid({
                  id: list[0].id
                }).then((data) => {
                  this.$message.success('操作成功')
                  GridManager.refreshGrid(this.gridOption.gridManagerName)
                }).catch((error) => {
                    this.$message.error(error)
                })
            }).catch(() => {})
        },
        addNew(row) {
            let arrId = this.moduleList.map((item) => {
                return item.id
            })
            //编辑

            if (row && !arrId.includes(row?.id)) {
                // row.id = row.invoicesId
                this.moduleList.push(this.dialogConfig.editDialog(row))
            }

            //如果已经打开就把最小化的恢复到正常
            if (arrId.includes(row?.id)) {
                for (let ele of this.moduleList) {
                    if (ele.id == row.id) {
                        // this.unfoldTag(ele)
                        this.$refs['multiWindowRef'].unfoldTag(ele)
                    }
                }
            }
            //如果新增已经打开就把最小化的新增恢复正常大小
            if (!row && this.hasAdd == 'Y') {
                for (let ele of this.moduleList) {
                    if (ele.id == '-1') {
                        // this.unfoldTag(ele)
                        this.$refs['multiWindowRef'].unfoldTag(ele)
                    }
                }
            }
            //新增
            if (!row && this.hasAdd == 'N') {
                this.moduleList.push(this.dialogConfig.addDialog())
                this.hasAdd = 'Y'
            }

        },
        closeDialog(e) {
            this.moduleList.forEach((item) => {
                if (item.id == e[1]?.id) {
                    item.isminimize = true
                }
                if (!e[1] && item.id == '-1') {
                    item.isminimize = true
                }
            })
            if (e[0] && e[0] !== 'close') {
                this.options.searchItem[2].value = [e[0]]
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
            if (e[0] === 'close' || e[0]) {
                // this.closeTag(e[1])
                this.$refs['multiWindowRef'].closeTag(e[1])
            }
        },
        hasAddChange(e) {
            this.hasAdd = e
        }
    }
}
</script>

<style lang="scss">
.dept-retreat {
    display: flex;
    padding: 10px;
    .right-box {
        display: flex;
        justify-content: space-between;
    }
}
</style>
