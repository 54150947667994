import request from '@/libs/http'
export const erp2OperatingQuerySizeGroupInfoIist = (params) =>
    request.post('/erp2/operating/query-size-group-info-list', params) //查询尺码组表头

export const erp2OperatingShipment = (params) => request.post('/erp2/operating/shipment', params) //公共接口-录入快递信息
export const erp2MarketAllotQueryInvoicePackage = (params) =>
    request.post('/erp2/erp-market-allot/query-invoice-package', params) //公共接口-查询包裹信息

export const queryMarketingInfo = (params) =>
    request.post('/erp2/erp-marketing-unit-spu-price-relation/query-marketing-info', params) //查询店铺列表(店铺选择器)
export const erp2QueryGoodsInfo = (params) =>
    request.post('/erp2/erp-marketing-unit-spu-price-relation/query-goods-info', params) //查询店铺列表(货号选择器)
export const queryGoodsQuarter = (params) =>
    request.post('/erp2/erp-marketing-unit-spu-price-relation/query-goods-quarter', params) //查询季度列表(货号选择器)
export const erp2OperatingExpressInfo = (params) => request.post('/erp2/operating/express-info', params) //查询物流信息回显
export const erp2OperatingCheck = (params) => request.post('/erp2/operating/check', params) //验收动作
// /erp2/erp-market-allot/query-check-detail
export const erp2ErpMarketAllotQueryCheckDetail = (params) =>
    request.post('/erp2/erp-market-allot/query-check-detail', params) //验收回显

export const erp2OperatingCheckComplete = (params) => request.post('/erp2/operating/check-complete', params) //验收完成
export const erp2OperatingCheckPackageAll = (params) => request.post('/erp2/operating/check-package-all', params) //验收完成
export const marketAllotCheckComplete = (params) => request.post('/erp2/erp-market-allot/check-complete', params) //调拨单完成验收
export const queryOperatingBitFlag = (params) => request.post('/erp2/operating/query-market-bit-flag', params) //查询当前登录店铺是否允许配货单差异验收,是否允许调拨单差异验收


// /erp2/operating/check-complete
export const erp2MarketReturnQueryList = (params) => request.post('/erp2/erp-market-return/query-list', params) //店铺退仓单--列表查询
export const erp2MarketReturnBatchSubmit = (params) => request.post('/erp2/erp-market-return/batch-submit', params) //店铺退仓单--批量提交

export const erp2ErpMarketReturnQueryPickDetail = (params) =>
    request.post('/erp2/erp-market-return/query-pick-detail', params) // 店铺退仓单--拣货(查询详情)
export const erp2OperatingPick = (params) => request.post('/erp2/operating/pick', params) // 店铺退仓单--拣货
export const erp2OperatingPickComplete = (params) => request.post('/erp2/operating/pick-complete', params) // 店铺退仓单--拣货(提交)
export const erp2ErpMarketReturnQueryDetail = (params) => request.post('/erp2/erp-market-return/query-detail', params) // 店铺退仓单--详情
export const erp2MarketReturnQueryInsertPermission = (params) =>
    request.post('/erp2/erp-market-return/query-insert-permission', params) // 店铺退仓单--详情

export const erp2MarketReturnQueryUnitGoodsInfoList = (params) =>
    request.post('/erp2/erp-market-return/query-unit-goods-info-list', params) // 店铺退仓单--新增-查询货品列表
export const erp2MarketReturnQueryUnitGoodsSizeInfo = (params) =>
    request.post('/erp2/erp-market-return/query-unit-goods-size-info', params) // 店铺退仓单--新增-确认添加

// 新erp店铺调出单接口
export const erp2MarketAllotSubmitMarketAllot = (params) =>
    request.post('/erp2/erp-market-allot/submit-market-allot', params) //新增店店调拨单
export const erp2AllotQueryMarketAllotByUpdate = (params) =>
    request.post('/erp2/erp-market-allot/query-market-allot-by-update', params) //编辑店店调拨单
export const marketAllotQueryMarketAllotList = (params) =>
    request.post('/erp2/erp-market-allot/query-market-allot-list', params) //店店调拨列表查询
export const erpMarketAllotQueryMarketAllotDetail = (params) =>
    request.post('/erp2/erp-market-allot/query-market-allot-detail', params) //店店调拨详情查询
export const erp2MarketAllotBatchInvalidMarketAllot = (params) =>
    request.post('/erp2/erp-market-allot/batch-invalid-market-allot', params) //店店调拨批量作废
export const erp2rpMarketAllotQueryPickDetail = (params) =>
    request.post('/erp2/erp-market-allot/query-pick-detail', params) //店店调拨批量作废
export const erp2OperatingCleanPickAll = (params) =>
    request.post('/erp2/operating/clean-pick-all', params)
export const erp2MarketAllotBatchSubmitMarketAllot = (params) =>
    request.post('/erp2/erp-market-allot/batch-submit-market-allot', params) //店店调拨批量提交
export const erp2MarketAllotQueryUnitGoodsInfoList = (params) =>
    request.post('/erp2/erp-market-allot/query-unit-goods-info-list', params) //新增页面内获取单据货号详情
export const erp2MarketAllotQueryUnitGoodsSizeInfo = (params) =>
    request.post('/erp2/erp-market-allot/query-unit-goods-size-info', params) //新增页面内获取货号尺寸数据
export const erp2marketAllotClientNegativeStockAuthority = (params) =>
    request.post('/erp2/erp-market-allot/client-negative-stock-authority', params) //客户端查询是否允许负库存出货
export const operatingUpdateExpress = (params) => request.post('/erp2/operating/update-express', params) //修改快递单号
export const erp2OperatingInterceptExpress = (params) => request.post('/erp2/operating/intercept-express', params) //拦截包裹

export const erp2MarketAllotClientTransferOrder = (params) =>
    request.post('/erp2/erp-market-allot/client-transfer_order', params) //客户端查询是否允许新增调拨单
export const erp2DistributeQueryList = (params) => request.post('/erp2/distribute/query-list', params) //店铺配货查询列表
export const erp2DistributeQueryDetail = (params) => request.post('/erp2/distribute/query-detail', params) //店铺配货详情
export const erp2DistributeQueryInvoicePackage = (params) =>
    request.post('/erp2/distribute/query-invoice-package', params) //店铺配货包裹号列表查询
export const erp2DistributeWebPackageDetail = (params) => request.post('/erp2/distribute/web-package-detail', params) //店铺铺货单-查询验收详情
export const erpErpWarehouseUnitInfoQueryList = (params) =>
    request.post('/erp/erp-warehouse-unit-info/query-list', params) //查询仓库列表

export const erp2MarketReturnSubmitMarketReturn = (params) =>
    request.post('/erp2/erp-market-return/submit-market-return', params) //店铺退仓单--新增提交
export const erp2MarketReturnQueryMarketReturnByUpdate = (params) =>
    request.post('/erp2/erp-market-return/query-market-return-by-update', params) //店铺退仓单--编辑草稿单查详情
export const erp2OperatingPrintOrder = (params) => request.post('/erp2/operating/print-order', params) //根据包裹ID查询订单打印信息
export const erp2OperatingWorkbench = (params) => request.post('/erp2/operating/workbench', params) //根据包裹ID查询订单打印信息
export const erp2PeratingQueryClientShowNumber = (params) =>
    request.post('/erp2/operating/query-client-show-number', params) //查询客户端收货出货数量
export const erp2OperatingDuplicateInvoices = (params) => request.post('/erp2/operating/duplicate-invoices', params) //查询客户端收货出货数量
export const erp2AdjustNoticeQueryMarketNoticePage = (params) =>
    request.post('/erp2/adjust-notice/query-market-notice-page', params) //调价通知--列表查询
export const erp2AdjustNoticeQuerySecondCategoryList = (params) =>
    request.post('/erp2/adjust-notice/query-second-category-list', params) //调价通知--二级分类查询
export const erp2MarketingUnitSpuPriceRelationQueryPrincipal = (params) =>
    request.post('/erp2/erp-marketing-unit-spu-price-relation/query-principal', params) //获取商品负责人信息
export const erp2OperatingReceiveTraceGet = (params) => request.post('/erp2/operating/receive-trace-get', params) //查询京东物流轨迹
export const QueryEstimatedDeliveryTimeByExpressSn = (params) => request.post('/erp2/waybill/query-estimated-delivery-time-by-express-sn', params) //查询京东物流预计到达时间

export const erp2OperatingRestartPick = (params) => request.post('/erp2/operating/restart-pick', params)
export const erp2OperatingResetInvoices = (params) => request.post('/erp2/operating/reset-invoices', params)
export const erp2OperatingQueryStockAccountCard = (params) =>
    request.post('/erp2/operating/query-stock-account-card', params)
export const erp2SpuDemandReLabel = (params) => request.post('/erp2/spu/demand-reLabel', params)
// /erp2/operating/query-stock-account-card
export const erp2MarketingUnitStockQueryCategoryCount = (params) =>
    request.post('/erp2/marketing-unit-stock/query-category-count', params)
// /erp2/operating/query-stock-account-card
export const erp2ColorQueryInfo = (params) => request.post('/erp2/color/query-info', params) //颜色
export const erp2SizeQueryInfo = (params) => request.post('/erp2/size/query-info', params) //尺码
export const erp2MarketingUnitStockQueryGoodsInfo = (params) =>
    request.post('/erp2/marketing-unit-stock/query-goods-info', params) //尺码

export const openPublicOrderValid = (params) => request.post('/erp2/erp-market-public-order-join-user-relation/enable-valid', params) //店铺开启公单校验
export const closePublicOrder = (params) => request.post('/erp2/erp-market-public-order-join-user-relation/delete-join-user', params) //关闭店铺公单
export const publicOrderJoin = (params) => request.post('/erp2/erp-market-public-order-join-user-relation/query-join-list', params) //查询店铺公单参与人员
export const queryMarketUserPost = (params) => request.post('/erp2/erp-public-order-market-personal-rule/query-market-user-post', params) //查询店铺员工职级信息
export const openPublicOrder = (params) => request.post('/erp2/erp-market-public-order-join-user-relation/save', params) //店铺开启公单保存参与人员
export const queryPublicOrderEnableState = (params) => request.post('/erp2/erp-market-public-order-enable-state/query-page', params) //店铺开启公单保存参与人员


export const queryNegativeManualExpress = (params) => request.post('/erp2/erp-market-allot/client-negative-manual-express', params) //查询店铺是否可以手动录入快递
export const erpWaybillAdd = (params) => request.post('/erp2/waybill/add', params) //运单列表添加
export const erpWaybillQuery = (params) => request.post('/erp2/waybill/page', params) //运单列表查询
export const getAllotOrderWeight = (params) => request.post('/erp2/erp-market-allot/order-weight', params) //获取店铺调拨单据重量
export const getReturnOrderWeight = (params) => request.post('/erp2/erp-market-return/order-weight', params) //获取店铺退货单据重量


export const queryDiscrepancyList = (params) => request.post('/erp2/invoice-discrepancy-check/query-list', params) //查询差异列表
export const queryDiscrepancyDetail = (params) => request.post('/erp2/invoice-discrepancy-check/query-detail', params) //查询差异详情


export const cleanCheckPackageAll = (params) => request.post('/erp2/operating/clean-check-package-all', params) //店铺收货清空验收数
//调拨出货
export const erpPickMarketAllot = (params) => request.post('/erp2/erp-market-allot/pick', params)//店铺调出单拣货
export const pdaCleanPackByRfid = (params) => request.post('/erp2/erp-market-allot/pda-clean-pack-by-rfid', params)//店铺调出单清空拣货
export const operatingSealPack = (params) => request.post('/erp2/operating/seal-pack', params)//店铺调出单封包
export const pdaPackageListAllot = (params) => request.post('/erp2/erp-market-allot/pda-package-list', params)//店铺调出单查询包裹列表
export const pdaPackageDetailAllot = (params) => request.post('/erp2/erp-market-allot/pda-package-info', params)//店铺调出单查询包裹明细
export const pdaDissolveParcel = (params) => request.post('/erp2/erp-market-allot/pda-dissolve-parcel', params)//店铺调出单解散包裹
export const pdaRebackByRfid = (params) => request.post('/erp2/erp-market-allot/restart-pick', params)//店铺调出单回退拣货
export const erpOperatingShipment = (params) => request.post('/erp2/erp-market-allot/pda-shipment', params) //店铺调出单录入快递信息
export const allotPdaPickCheckSku = (params) => request.post('/erp2/erp-market-allot/pda-pick-check-sku', params) //店铺调出单校验sku信息
//退仓单
export const marketReturnPick = (params) => request.post('/erp2/erp-market-return/pick', params)//店铺退仓单拣货
export const returnSealPack = (params) => request.post('/erp2/erp-market-return/seal-pack', params)//店铺退仓单封包
export const returnPdaPackageList = (params) => request.post('/erp2/erp-market-return/pda-package-list', params)//店铺退仓单发货单据包裹列表
export const returnPdaPackageInfo = (params) => request.post('/erp2/erp-market-return/pda-package-info', params)//店铺退仓单发货包裹明细
export const returnPdaDissolveParcel = (params) => request.post('/erp2/erp-market-return/pda-dissolve-parcel', params)//店铺退仓单解散包裹
export const returnPdaCleanPackByRfid = (params) => request.post('/erp2/erp-market-return/pda-clean-pack-by-rfid', params)//店铺退仓单清空拣货
export const returnRestartPick = (params) => request.post('/erp2/erp-market-return/restart-pick', params)//店铺退仓单回退拣货
export const returnPdaShipment = (params) => request.post('/erp2/erp-market-return/pda-shipment', params) //店铺退仓单录入快递信息
export const returnPdaPickCheckSku = (params) => request.post('/erp2/erp-market-return/pda-pick-check-sku', params) //店铺退仓单校验sku信息

export const queryMarketAllotPackageEpc = (params) => request.post('/erp2/erp-market-allot/logistics-package-query-epc', params) //调拨单的epc包裹明细
export const queryMarketReturnPackageEpc = (params) => request.post('/erp2/erp-market-return/logistics-package-query-epc', params) //退仓单的epc包裹明细
export const queryMarketDistributePackageEpc = (params) => request.post('/erp2/distribute/logistics-package-query-epc', params) //配货单的epc包裹明细

