var list=require('@/assets/images/erp/cartonorder.jpeg')
export const columnData = [
    {
        key: 'sn',
        text: '配货单号',
        width: '200px',
        fixed: 'left'
    },
    {
        key: 'stateDesc',
        text: '状态',
        width: '120px',
        fixed: 'left',
        remind: {
            text: `
            草稿单(蓝色)：临时保存单据草稿，不会占用库存<br/>
            预提交（紫色）：先提交单据，但不会下发到仓库，会占用仓库库存<br/>
            已下发（绿色）：已提交单据，且下发到仓库，待仓库拣货出库<br/>
            拣货中（橙色）：仓库收到指令，正在拣货下架<br/>
            拣货完成（绿色）：仓库拣货完成并提交，放入待打包区<br/>
            打包中（橙色）：打包人进行封包装袋<br/>
            打包完成（绿色）：装袋完成，等待录入快递信息后出货<br/>
            已出货（蓝色）：仓库已按照配货单拣货出库，会扣减仓库库存，物流状态在途<br/>&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;&#12288;将增加店铺在途库存<br/>
            已签收（绿色）：走合作物流发货时，物流信息已到店签收，等待店铺拆包验收<br/>
            验收中（橙色）：店铺正在拆包验收<br/>
            已入库（绿色）：验收完成后，操作确认入库，店铺的在途库存变为实际增加库存<br/>
            已作废（红色）：草稿单、预提交、已下发，这三个状态可进行作废操作<br/>
            申请作废（红色）：已出货单据进行申请作废<br/>
`,
            style: {
                width: '450px',
                'font-size': '12px',
                'text-align': 'left',
                'z-index': '999'
            }
        },
        template: () => {
            return ` <div style="display:flex;align-items:center;">
            <span v-if="row.state ==0" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#F8475F;"></span>
            <span v-else-if="row.state == 12 || row.state == 4  || row.state == 6 || row.state == 8|| row.state == 14" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#43D268;"></span>
            <span v-else-if="row.state == 5 || row.state == 7 || row.state == 11" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#FF9000;"></span>
            <span v-else-if="row.state == 1 ||  row.state == 9" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#067CF2;"></span>
            <span v-else-if="row.state == 2||row.state==10" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#BD73D3;"></span>
            <span v-else-if="row.state == 19" style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#F8475F;"></span>
            <span v-else style="width:10px;height:10px;border-radius:35%;margin-right:10px;background:#999;"></span>
            <span>{{row.stateDesc}}</span>
        </div>`
        }
    },
    {
        key: 'ifDiscrepancy',
        text: '收货差异待处理',
        width: '100px',
        template: () => {
            return `
                <div>{{row.ifDiscrepancy === 'Y' ? '是' : '否'}}</div>
            `
        }
    },
    { key: 'updateTime', text: '更新时间', width: '150px', sorting: '' },
    {
        key: 'warehouseName',
        text: '发货方',
        width: '100px'
    },
    {
        key: 'externalSystemSn1',
        text: '箱标订单号',
        width: '200px',
        remind: {
            text: `
           <img src='${list}' style='height: 100%;width: 100%'/>
  </div>
    
`,
            style: {
                width: '450px',
                height:'400px',
            }
        },
    },

    { key: 'marketName', text: '收货方', width: '100px' },
    {
        key: 'outNum',
        text: '来货数量',
        width: '80px',
        template: (e, row) => {
            return `<span>{{row.outNum==='0'?'':row.outNum}}<span>`
        }
    },
    {
        key: 'inNum',
        text: '入库数量',
        width: '80px',
        template: (e, row) => {
            return `<span>{{row.inNum==='0'?'':row.inNum}}<span>`
        }
    },
    { key: 'logisticPackageNum', text: '快递包裹数', width: '100px' },
    { key: 'logisticPackageCheckNum', text: '已收包裹数', width: '100px' },
    { key: 'logisticPackageNoCheckNum', text: '未收包裹数', width: '100px' },
    {
        key: 'intoStockDifferNum',
        text: '入库差异数',
        width: '100px',
        template: (e, row) => {
            return `<span style="color:red">{{row.intoStockDifferNum==='0'?'':row.intoStockDifferNum}}<span>`
        }
    },
    { key: 'goodsPrincipalName', text: '商品负责人', width: '100px' },
    { key: 'remark', text: '备注' , width: '150px'},
    { key: 'createUserName', text: '创建人', width: '90px' },
    {
        key: 'createTime',
        text: '创建时间',
        width: '150px',
        sorting: 'DESC',
    },

    { key: 'updateUserName', text: '修改人', width: '90px' }
]
export const options = {
    searchItem: [
        {
            id: '1',
            type: 'select',
            size: 'small',
            clearable: true,
            placeholder: '请选择状态',
            label: '状态',
            value: ['9','10','11'],
            resField: 'state',
            multiple: true,
            list: [
                { value: '9', name: '已出货' },
                { value: '10', name: '已签收' },
                { value: '11', name: '验收中' },
                { value: '14', name: '已入库' },
                { value: '19', name: '作废申请' },
                { value: '0', name: '已作废' }
            ]
        },
        {
            id: 'ifDiscrepancy',
            type: 'select',
            size: 'small',
            clearable: true,
            placeholder: '请选择',
            label: '是否仅看收货差异待处理',
            value: '',
            resField: 'ifDiscrepancy',
            multiple: false,
            list: [
                { value: 'Y', name: '是' },
                { value: 'N', name: '否' }
            ]
        },
        {
            id: '2',
            type: 'input',
            size: 'small',
            clearable: true,
            placeholder: '请输入单号',
            label: '单号',
            value: '',
            resField: 'snList'
        },
        {
            id: '3',
            type: 'input',
            size: 'small',
            clearable: true,
            placeholder: '请输入快递单号',
            label: '快递单号',
            value: '',
            resField: 'expressNo'
        },
        {
            id: 'external',
            type: 'input',
            size: 'small',
            clearable: true,
            placeholder: '请输入箱标订单号',
            label: '箱标订单号',
            value: '',
            resField: 'externalSystemSn1'
        },
        // {
        //     id: 'receiverInfoIds',
        //     type: 'selectEntre',
        //     size: 'small',
        //     clearable: true,
        //     placeholder: '请选择',
        //     label: '发货方',
        //     value: [],
        //     resField: 'receiverInfoIds',
        //     multiple: true,
        //     filterable: true,
        //     remote: true,
        //     remoteMethod: 'fetchEntrepot'
        // },
        {
            id: 'warehouseIdList',
            type: 'warehouse',
            placeholder: '请选择发货方',
            label: '发货方',
            value: [],
            resField: 'warehouseId'
        },
        {
            // id: 'goodsNo',
            // type: 'goodsNoSelector',
            // placeholder: '请选择货号',
            // label: '货号搜索',
            // modelKey: 'goodsNo',
            // multiple: true, //单选
            // // value: '', //单选
            // value: [],//多选
            // resField: 'spuList'
            id: 'goodsNo',
            type: 'goodsNoSelector',
            placeholder: '请输入货号，多个用空格隔开',
            label: '货号',
            modelKey: 'goodsNo',
            multiple: true,
            value: '',
            inputShow: true,
            slectShow: false,
            resField: 'spuList'
        },
        // {
        //     id: '7',
        //     type: 'selectUser',
        //     size: 'small',
        //     clearable: true,
        //     placeholder: '请选择创建人',
        //     label: '创建人',
        //     value: '',
        //     resField: 'createUserId',
        //     list: [],
        //     filterable: true,
        //     remote: true,
        //     remoteMethod: 'userList'
        // },
        {
            id: "UserInput",
            type: "input",
            size: "small",
            clearable: true,
            placeholder: "请填写创建人",
            label: "创建人",
            value: "",
            resField: "createUserName",
        },
        // {
        //     id: '82',
        //     type: 'selectUser',
        //     size: 'small',
        //     clearable: true,
        //     placeholder: '请选择商品负责人',
        //     label: '商品负责人',
        //     value: '',
        //     resField: 'goodsLeader',
        //     list: [],
        //     filterable: true,
        //     remote: true,
        //     remoteMethod: 'userList'
        // },
        {
            id: 'goodsLeader',
            type: 'select',
            size: 'small',
            clearable: true,
            multiple: true,
            placeholder: '请选择商品负责人',
            label: '商品负责人',
            value: '',
            filterable:true,
            resField: 'goodsLeaderList',
            list: []
        },
        // {
        //     id: 'goodsLeader',
        //     type: 'input',
        //     size: 'small',
        //     clearable: true,
        //     placeholder: '请输入商品负责人姓名',
        //     label: '商品负责人',
        //     value: '',
        //     resField: 'goodsLeader'
        // },
        {
            id: '8',
            type: 'daterange',
            size: 'small',
            clearable: true,
            placeholder: '请选择创建时间',
            label: '创建时间',
            value: '',
            resField: 'createTime',
            valueFormat: 'yyyy-MM-dd'
        },

        {
            id: '9',
            type: 'daterange',
            size: 'small',
            clearable: true,
            placeholder: '请选择更新时间',
            label: '更新时间',
            value: '',
            resField: 'updateTime',
            valueFormat: 'yyyy-MM-dd'
        }
    ],
    // 确认按钮
    sureBtnVisable: true,
    sureBtnType: 'primary',
    sureBtnSize: 'medium',
    sureBtnText: '查询',
    //重置按钮
    restBtnVisable: true,
    restBtnType: '',
    restBtnSize: 'medium',
    restBtnText: '重置'
}
