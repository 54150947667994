<template>
  <el-dialog
      top="2vh"
      width="1000px"
      append-to-body
      custom-class="differ-dialog"
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeDialog">
    <div slot="title" class="dialog-title">
      <div>{{ title }}</div>
      <span class="el-icon-close" style="cursor:pointer;" @click="closeDialog"></span>
    </div>
    <section class="dialog-content"
             v-if="detailInfo"
             v-loading="fetchDetailLoading">
      <div class="card-box">
        <div class="card-box-top">
          <span></span>
          <div>差异信息</div>
        </div>
        <div class="invoice-box">
          <div class="card-box-num">
            <div>
              <div>出货数</div>
              <span>{{ detailInfo.sendNum || '0' }}</span>
            </div>
            <div>
              <div>入库数</div>
              <span>{{ detailInfo.receiveNum || '0' }}</span>
            </div>
            <div>
              <div>入库差异</div>
              <span
                  :style="{color: detailInfo.discrepancyNum ? 'rgb(248,71,95)' : ''}">{{
                  detailInfo.discrepancyNum || '0'
                }}</span>
            </div>
            <div>
              <div style="color: rgb(56,161,105);">发货方承担</div>
              <span>{{ detailInfo.sendDutyNum || '0' }}</span>
            </div>
            <div>
              <div style="color:rgb(188,115,210);">收货方承担</div>
              <span>{{ detailInfo.receiveDutyNum || '0' }}</span>
            </div>
            <div>
              <div style="color:rgb(7,124,242);">运输方承担</div>
              <span>{{ detailInfo.transportDutyNum || '0' }}</span>
            </div>
            <div>
              <div>状态</div>
              <span v-if="detailInfo.state === '0'" style="color: #F8475F;">未定责</span>
              <span v-else style="color: #33A169;">已定责</span>
            </div>
          </div>
          <div class="time-span">
            <span>打包完成：{{detailInfo.packCompleteTime}}</span>
            <span>出货时间：{{detailInfo.originalInvoiceOutTime}}</span>
            <span>收货完成：{{detailInfo.checkCompleteTime}}</span>
          </div>
        </div>
      </div>
      <div class="card-box" v-if="detailInfo.spuDiscrepancyDetailList && detailInfo.spuDiscrepancyDetailList.length">
        <div class="card-box-top">
          <span></span>
          <div>差异货品</div>
        </div>
        <div style="display:flex;align-items:center;background:rgb(245,247,250);text-align:center;border:1px solid #eee;border-left:0;">
          <div style="width:120px;line-height:36px;border-left:1px solid #eee;">货号</div>
          <div style="width:200px;line-height:36px;border-left:1px solid #eee;">货品名称</div>
          <div style="width:100px;line-height:36px;border-left:1px solid #eee;">颜色</div>
          <div style="flex: 1;line-height:36px;border-left:1px solid #eee;">尺码</div>
          <div style="flex: 1;line-height:36px;border-left:1px solid #eee;">差异</div>
          <div style="flex: 1;line-height:36px;border-left:1px solid #eee;">发货方承担</div>
          <div style="flex: 1;line-height:36px;border-left:1px solid #eee;">收货方承担</div>
          <div style="flex: 1;line-height:36px;border-left:1px solid #eee;">运输方承担</div>
        </div>
        <div v-for="(item, i) in detailInfo.spuDiscrepancyDetailList" :key="i" style="display:flex;align-items:center;text-align:center;border:1px solid #eee;border-top:0;">
          <div style="width:120px;border-right:1px solid #eee;" :style="{height: 36 * item.skuDiscrepancyDetailList.length + 'px', lineHeight: 36 * item.skuDiscrepancyDetailList.length + 'px'}">
            {{ item.goodsNo }}
          </div>
          <div style="width:200px;border-right:1px solid #eee;" :style="{height: 36 * item.skuDiscrepancyDetailList.length + 'px', lineHeight: 36 * item.skuDiscrepancyDetailList.length + 'px'}">
            {{ item.goodsName }}
          </div>
          <div style="width:100px;border-right:1px solid #eee;" :style="{height: 36 * item.skuDiscrepancyDetailList.length + 'px', lineHeight: 36 * item.skuDiscrepancyDetailList.length + 'px'}">
            {{ item.color }}
          </div>
          <div style="flex:1;">
            <div style="display:flex;align-items:center;height:36px;line-height:36px;border-bottom:1px solid #eee;"
                 v-for="(size, s) in item.skuDiscrepancyDetailList" :key="s"
                 :style="item.skuDiscrepancyDetailList.length - 1 === s ? 'border-bottom: 0;' : ''">
              <div style="flex: 1;line-height:36px;border-right:1px solid #eee;">{{ size.size }}</div>
              <div style="flex: 1;line-height:36px;border-right:1px solid #eee;">{{ size.discrepancyNum || '0' }}</div>
              <div style="flex: 1;line-height:36px;border-right:1px solid #eee;">
                <span v-if="!isInputDuty">{{ size.sendDutyNum || '0' }}</span>
              </div>
              <div style="flex: 1;line-height:36px;border-right:1px solid #eee;">
                <span v-if="!isInputDuty">{{ size.receiveDutyNum || '0' }}</span>
              </div>
              <div style="flex: 1;line-height:36px;">
                <span v-if="!isInputDuty">{{ size.transportDutyNum || '0' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="step-box" v-if="!isInputDuty">
          <div class="step-item">
            <div class="step-item-left">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
            <div class="step-item-right">
              <div class="right-title">差异收货</div>
              <div>
                <span>收货人：{{ detailInfo.checkUserName }}</span>
                <span style="margin-left:100px;">收货时间：{{ detailInfo.checkCompleteTime }}</span>
              </div>
            </div>
          </div>

          <div class="step-item">
            <div class="step-item-left">
              <div class="circle" :style="!detailInfo.remark ? 'border-color:#eee;' : ''"></div>
              <div class="line" v-if="detailInfo.dutyOwnership"
                   :style="!detailInfo.remark ? 'background:#eee;' : ''"></div>
            </div>
            <div class="step-item-right">
              <div class="right-title" :style="detailInfo.state === '0' ? 'color:#999;' : ''">差异定责</div>
              <template v-if="detailInfo.remark && detailInfo.state !== '0'">
                <div v-if="detailInfo.affirmDutyTime">
                  <span>定责人：{{ detailInfo.affirmDutyUserName }}</span>
                  <span style="margin-left:100px;">定责时间：{{ detailInfo.affirmDutyTime }}</span>
                </div>
                <div>定责说明：{{ detailInfo.remark }}</div>
                <div style="display:flex;">
                  <span style="width:84px;">定责凭证：</span>
                  <div style="display:flex;flex-wrap:wrap;">
                    <template v-for="(item, i) in detailInfo.imageUrl">
                      <el-image
                          :src="item"
                          :key="i"
                          :preview-src-list="detailInfo.imageUrl"
                          style="width:100px;height:100px;margin-right:10px;border-rasius:4px;margin-bottom:10px;border:1px solid #ddd;">
                      </el-image>
                    </template>
                    <template v-for="(item, index) in detailInfo.videoUrl">
                      <div :key="item"
                           style="width:100px;height:100px;margin-right:10px;border:1px solid #ddd;position: relative;">
                        <video controls="controls" :id="index + '_v'" :src="item"
                               style="width:100px;height:100px;"></video>
                        <div
                            style="position: absolute;left: 0;top: 0;width: 100px;height: 100px;cursor:pointer;display:block;background: rgb(0,0,0);"
                            :id="index + '_img'" @click="playVideo(item, index)">
                          <img style="width:60px;height: 60px;margin-left: 20px;margin-top: 20px;" src="@/assets/images/musicPlayer/play.png">
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div style="background:rgb(255,247,230);color:#000;">
                  <div style="padding:10px;" v-if="detailInfo.sendIsCloud === 'Y'">
                    <div>发货方为云仓，会有如下情况：</div>
                    <div>
                      <div>①、云仓有库存，但实物漏发了。</div>
                      <div>联系京东线下补发，完成收货后再定责。由于系统逻辑是先收货再产生的差异记录，因此没有二次入库的途径，
                        <span style="color:red;">在定责时需要把云仓这部分的差异归为收货方承担才能让店铺库存对的上</span>
                        （收货方承担在正常场景里属于漏收，所以系统会自动补上库存）</div>
                    </div>
                    <div>
                      <div>②、云仓没库存但京东误操作让单子多出货了。或者某个款临时通知不下发但云仓已经操作出货了，线下把实物取出不发。</div>
                      <div>
                        <span style="color:red;">定责时将差异归为发货方承担</span> 系统逻辑会自动将差异的数量返还给发货方增加库存。但由于无法直接对京东的数据进行改动，
                        <span style="color:red;">需要在京东后台做单，让京东系统和自己系统的库存两边对的上</span>。</div>
                    </div>
                  </div>
                  <div style="padding:10px;" v-if="detailInfo.dutyOwnership && detailInfo.dutyOwnership.includes('3')">运输方承担的差异数量，除了需要向物流索要赔付，还需要额外做库存调整单来减掉库存。</div>
                </div>
              </template>
            </div>
          </div>

        </div>
      </div>
    </section>
  </el-dialog>
</template>

<script>
import {queryDiscrepancyDetail} from "@/libs/http/modules/erp";
export default {
  name: 'differDetailDialog',
  props: {
    differRows: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      dialogVisible: true,
      fetchDetailLoading: false,
      detailInfo: null,
      remark: '',
      uploadHeaders: {},
      uploadImages: [],
      chooseVideoList: [],
      submitLoading: false,
      isInputDuty: false,
    }
  },
  created() {
    this.fetchDetail()
  },
  computed: {
    title() {
      if (this.detailInfo) {
        return `收货差异详情—${this.detailInfo.invoiceName}—${this.detailInfo.sn}`
      } else {
        return `收货差异详情`
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false
      this.$emit('close')
    },
    fetchDetail() {
      this.fetchDetailLoading = true
      queryDiscrepancyDetail({
        id: this.differRows.id
      }).then(data=>{
        if(data.imageUrl){
          data.imageUrl = JSON.parse(data.imageUrl)
        }
        if(data.videoUrl){
          data.videoUrl = JSON.parse(data.videoUrl)
        }
        if(data.spuDiscrepancyDetailList){
          data.spuDiscrepancyDetailList.forEach(item=>{
            item.skuDiscrepancyDetailList.forEach(size=>{
              if(size.sendDutyNum === '0'){
                size.sendDutyNum = ''
              }
              if(size.receiveDutyNum === '0'){
                size.receiveDutyNum = ''
              }
              if(size.transportDutyNum === '0'){
                size.transportDutyNum = ''
              }
            })
          })
        }
        this.detailInfo = data
        this.fetchDetailLoading = false
      }).catch(() => {
        this.fetchDetailLoading = false
      })
    },
    playVideo(url, id) {
      var video = document.getElementById(id + '_v')
      video.src = url
      video.play()
      document.getElementById(id + '_img').style.display = 'none'
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eee;
  padding: 10px 20px;

  div {
    font-size: 18px;
    font-weight: bold;
  }
}

.dialog-content {
  padding: 10px 20px;
  height: 83vh;
  overflow: auto;

  .card-box {
    margin-bottom: 20px;

    .card-box-top {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #000;
      margin-bottom: 10px;

      span {
        width: 4px;
        margin-right: 6px;
        height: 16px;
        border-radius: 4px;
        background: #0b83f3;
      }
    }

    .tips-text {
      color: #000;
      padding: 10px;
      border-radius: 6px;
      background: rgb(230, 247, 255);
      margin-bottom: 10px;
    }

    .invoice-box{
      background: rgb(245,247,250);
      padding: 10px;
      border-radius: 6px;
    }
    .card-box-num {
      display: flex;
      justify-content: space-between;
      background: rgb(245, 247, 250);
      padding: 10px;
      border-radius: 6px;
      > div {
        div {
          font-size: 16px;
          margin-bottom: 10px;
        }
        span {
          color: #000;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .time-span{
      span{
        margin-right: 40px;
      }
    }
  }

  .step-box {
    color: #000;
    margin-top: 10px;

    .step-item {
      display: flex;

      &:last-child {
        .step-item-left {
          .line {
            display: none;
          }
        }
      }

      .step-item-left {
        margin-right: 15px;

        .circle {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid rgb(7, 124, 242);
        }

        .line {
          width: 1px;
          height: calc(100% - 14px);
          background: rgb(7, 124, 242);
          margin-left: 7px;
        }
      }

      .step-item-right {
        margin-bottom: 20px;

        > div {
          margin-bottom: 5px;
        }

        .right-title {
          margin-bottom: 10px;
          color: rgb(7, 124, 242);
          font-size: 16px;
          margin-top: -4px;
        }
      }
    }
  }
}

.footer {
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  background: #eee;
}
</style>

<style lang="scss">
.differ-dialog {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-upload--picture-card {
    width: 160px;
    height: 160px;
    line-height: 160px;
  }

  .el-upload-list__item {
    width: 160px;
    height: 160px;
    line-height: 160px;
  }
}
</style>