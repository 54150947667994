<template>
<section>
    <dialogModule width="700px" top="26" title="扫码收款" v-if="changeVisiable" :dialogVisible="changeVisiable" @close="close">
        <span slot="close" class="el-icon-close close" @click="close"></span>
        <div slot="content" class="text-center" style="padding:15px;">
            <section v-if="payItem.length > 1">
                <el-steps :active="Number(curStep)" align-center>
                    <el-step v-for="(item, index) in payItem" :key="item.id" style="font-family: 'font-Light';">
                        <p slot="icon">
                            <span v-if="item.paymentState == 0" class="iconfont icon-tishi" style="font-size:30px;color:#ccc;"></span>
                            <span v-if="item.paymentState == 1" class="iconfont icon-chenggong" style="font-size:30px;color:#43D268;"></span>
                            <span v-if="item.paymentState == -1" class="iconfont icon-shibai" style="font-size:30px;color:#F8475F;"></span>
                        </p>
                        <div slot="title">
                            <span v-if="item.paymentState == 0" style="color:#333;">等待到账</span>
                            <span v-if="item.paymentState == 1" style="color:#43D268;">到账成功</span>
                            <span v-if="item.paymentState == -1" style="color:#F8475F;">已取消</span>
                        </div>
                        <p slot="description" style="font-size:14px;margin-bottom:20px;color:#999;">第{{index + 1}}笔收款：￥{{item.paymentPrice}}</p>
                    </el-step>
                </el-steps>
            </section>
            <section>
                <img class="state-img" src="@/assets/images/pay-scan.png" />
                <div class="text-tip">请扫描顾客的付款码</div>
            </section>
            <section class="row align-center space-between row-value">
                <input type="text" ref="payVal" v-model="outerNo" @keyup.enter="sureHandel" />
                <span class="iconfont icon-saoma"></span>
            </section>
            <el-button :loading="loading" @click="sureHandel" class="sure-btn">确 认</el-button>
        </div>
    </dialogModule>
    <dialogModule width="400px" top="35" title="扫码收款" v-if="waitingDialog" :dialogVisible="waitingDialog" :closeOnPressEscape="false">
        <span slot="close" class="el-icon-close close" @click="closeWaiting"></span>
        <div slot="content" class="text-center" style="padding:15px;">
            <section v-if="payItem[curStep] && payItem[curStep].paymentState == -1">
                <span class="el-icon-circle-close" style="font-size:40px;color:red;"></span>
                <div class="text-tip"><span v-if="payItem.length > 1">第{{Number(curStep) + 1}}笔</span> 支付失败，请关闭或继续支付</div>
            </section>
            <el-button class="sure-btn" @click="backhandel" v-else-if="timerSingle >= maxCount">取消支付</el-button>
            <section v-else>
                <span class="el-icon-loading" style="font-size:40px;"></span>
                <div class="text-tip">
                  <span v-if="payItem.length > 1">第{{Number(curStep) + 1}}笔</span>
                  提交成功，请等待金额到账
                  <p style="margin: 30px 50px;text-align: center;color: red;font-size: 16px;">请注意提醒顾客输入支付密码，否则可能支付失败！！！请留意支付结果。</p>
                </div>
            </section>
        </div>
    </dialogModule>
</section>
</template>

<script>
// const ipc = require('electron').ipcRenderer;
import dialogModule from "@/components/dialogModule.vue";
import {cancelPayment, vipPayConfir, vipPayConfirState} from '@/libs/http/modules/vip'
export default {
    props: {
        ordingId: {
            type: String,
            default: ()=> ''
        },
        payData: {
            type: Array,
            default: () => []
        },
        receivedAmount: {
            type: String,
            default: () => ''
        }
    },
    components: {
        dialogModule
    },
    data(){
        return{
            changeVisiable: false,
            loading: false,
            payItem: [],
            waitingDialog: false,
            outerNo: '',
            curStep: '0',//拆分时当前是在支付那一笔
            timer: null,//定时器
            timerSingle: 0, //状态查询次数
            isReturnBack: false, //调用查询状态接口是否有响应
            maxCount: this.$cache.local.get('maxCount') || 10,//状态最大查询次数
            intervalTime: this.$cache.local.get('intervalTime') * 1000 || 2000,//每次查询状态间隔时长
        }
    },
    created(){
        this.changeVisiable = true;
        this.payData.forEach(item=>{
            this.payItem.push({
                id: item.id,
                paymentPrice: item.paymentPrice,
                paymentState: '0'
            })
        })
        this.$nextTick(()=>{
            this.$refs.payVal.focus();
        });
    },
    methods: {
        close(e){
          this.$confirm("关闭后可在会员充值记录中查看和再次发起支付", "提示", {closeOnClickModal: false}).then(()=>{
            this.changeVisiable = false;
            this.$emit('close');
            if(!this.receivedAmount || Number(this.receivedAmount) != 0){
                let obj = { winId: 'salesRecod' };
                // ipc.send("send-refresh", obj);
            }
          }).catch(()=>{})
        },
        sureHandel(){
            if(!this.payItem[this.curStep]) return
            if(!this.outerNo) return
            this.$nextTick(() => {
              this.$refs.payVal.blur();
            });
            this.loading = true;
            this.payItem[this.curStep].paymentState = 0;
            this.timerSingle = 0;
            vipPayConfir({
                id: this.payItem[this.curStep].id,
                outerNo: this.outerNo
            }).then(data=>{
                this.outerNo = '';
                this.loading = false;
                this.waitingDialog = true;
                setTimeout(()=>{
                  this.queryState();
                }, Number(this.intervalTime))
            }).catch(err=>{
                this.outerNo = '';
                this.loading = false;
                this.$message({ showClose: true, message: err, type: "error", duration: 2000})
                this.$nextTick(() => {
                  this.$refs.payVal.focus();
                });
            })
        },
        queryState(){
            if(!this.payItem[this.curStep] || this.timerSingle >= this.maxCount){
            // if(!this.payItem[this.curStep]){
                clearInterval(this.timer);
                this.timer = null;
                if(!this.payItem[this.curStep]){
                  this.waitingDialog = false;
                }
                return
            }
            if(this.isReturnBack) return;
            this.timerSingle++;
            this.isReturnBack = true;
            vipPayConfirState({
                id: this.payItem[this.curStep].id
            }).then(data=>{
                this.isReturnBack = false;
                if(data.paymentState == -1){
                  clearInterval(this.timer);
                  this.timer = null;
                  this.payItem[this.curStep].paymentState = data.paymentState;
                }else if(data.paymentState == 1){
                  clearInterval(this.timer);
                  this.timer = null;
                  this.waitingDialog = false;
                  this.payItem[this.curStep].paymentState = data.paymentState;
                  this.curStep++;
                  let res = this.payItem.every(item=>{
                    return item.paymentState == 1;
                  });
                  if(res){
                    this.changeVisiable = false;
                    this.$emit('print');
                  }
                }else{
                  if(!this.timer){
                    this.timer = setInterval(()=>{
                      this.queryState();
                    }, Number(this.intervalTime));
                  }
                }
            }).catch(err=>{
                clearInterval(this.timer);
                this.timer = null;
                this.waitingDialog = false;
                this.isReturnBack = false;
                this.$message({ showClose: true, message: err, type: "error", duration: 2000})
            })
        },
        closeWaiting(){
          if(this.payItem[this.curStep].paymentState == -1){
            clearInterval(this.timer);
            this.timer = null;
            this.waitingDialog = false;
            this.$nextTick(()=>{
              this.$refs.payVal.focus();
            });
          }else{
              if(this.timerSingle >= this.maxCount){
                  clearInterval(this.timer);
                  this.timer = null;
                  this.waitingDialog = false;
                  this.$nextTick(()=>{
                      this.$refs.payVal.focus();
                  });
              }else{
                cancelPayment({
                  id: this.payItem[this.curStep].id
                }).then(data=>{
                  if(data === '-1'){
                    clearInterval(this.timer);
                    this.timer = null;
                    this.waitingDialog = false;
                    this.$nextTick(()=>{
                      this.$refs.payVal.focus();
                    });
                  }else{
                    this.$message({ showClose: true, message: `当前订单正在支付中，请继续等待支付`, type: "error", duration: 2000})
                  }
                }).catch(error=>{
                  this.$message({ showClose: true, message: error, type: "error", duration: 2000})
                })
              }
          }
        },
        backhandel(){
            clearInterval(this.timer);
            this.timer = null;
            this.waitingDialog = false;
            this.$nextTick(()=>{
                this.$refs.payVal.focus();
            });
        }
    },
    deactivated(){
        clearInterval(this.timer);
        this.timer = null;
    }
}
</script>

<style lang="scss" scoped>
.close{
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer
}
.sure-btn{
    background:#067CF2;
    border-radius:4px;
    width:320px;
    line-height:40px;
    text-align:center;
    color:#fff;
    padding:0;
    margin:20px auto 10px;
    font-size:18px;
}
.state-img{
    width:125px;
}
.text-tip{
    font-size:18px;
    margin:20px 0 30px;
}
.row-value{
    background:#F3F5FA;
    width:375px;
    height:40px;
    padding:0 10px;
    margin:auto;
    input{
        background:none;
        outline:none;
        line-height:38px;
        width:100%;
        border:none;
    }
    span{
        color:#067CF2;
        font-size:20px;
        font-weight:bold;
        margin-left:10px;
    }
}
</style>
